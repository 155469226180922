import React, {Component} from 'react';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import uuidv4 from 'uuid/v4';

import {gettext} from 'utils/i18n';
import {RemarkPropType} from 'utils/types';

import {STATE_KEY, requestAddResponse, getRemarkById} from "ducks/buildingSites";
import {markNfsFileForDeletion, registerNfsFile} from 'ducks/nfs';

import TextArea from 'components/TextArea';
import ImageWidget from 'components/ImageWidget';

import withView from "decorators/withView";

import NotFound from 'components/NotFound';


const mapStateToProps = (state, ownProps) => {
    const {remarkId} = ownProps.match.params;

    const categories = state[STATE_KEY].remarkCategories;
    const remark = getRemarkById(state[STATE_KEY].remarks, remarkId);

    if (!remark || !categories) {
        return {remark, category: null};
    }

    const category = remark ? Object.values(categories).find(c => c.id === remark.category) : null;

    return {
        remark,
        category,
    };
};

const mapDispatchToProps = dispatch => ({
    onSaveResponse: (responseData, remarkId, callback) => (
        dispatch(requestAddResponse(responseData, remarkId, callback))
    ),
    registerNfsFile: imageUrl => dispatch(registerNfsFile(imageUrl)),
    markNfsFileForDeletion: imageUrl => dispatch(markNfsFileForDeletion(imageUrl)),
});

class RemarkDisplay extends Component {
    static propTypes = {
        remark: RemarkPropType,
        onSaveResponse: PropTypes.func.isRequired,
        category: PropTypes.shape(),
        activeLanguage: PropTypes.string.isRequired,
        registerNfsFile: PropTypes.func.isRequired,
        markNfsFileForDeletion: PropTypes.func.isRequired,
    };

    static defaultProps = {
        remark: null,
        category: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            images: [], // Implement image adding
            comment: "",
            isSuccess: false,
            errors: {
                comment: null,
                images: null,
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        const {remark} = this.props;

        if (this.state.isSuccess) {
            if (nextProps.remark && nextProps.remark.last_response && remark && remark.last_response) {
                // If rollback occurred reset form flag
                if (nextProps.remark.last_response.rollback && !remark.last_response.rollback) {
                    this.setState({
                        isSuccess: false,
                    });
                }
            }
        }
    }

    onChangeField = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    onAddImage = (data) => {
        if (!data) {
            this.setError('imageData', gettext('Image must be selected'));
            return;
        }
        this.clearErrors();

        const image = {
            id: this.getImageId(),

            ...(data.type === 'nfs' ? {
                type: 'nfs',
                name: data.name,
                image: data.url,
                size: data.size,
            } : {
                type: 'datauri',
                name: data.name,
                image: data.data,
                size: data.size,
            }),
        };

        this.setState({
            images: [
                ...this.state.images,
                image,
            ],
        });
    };

    onRemoveImage = (id) => {
        const oldImageIndex = this.state.images.findIndex(image => image.id === id);

        if (oldImageIndex !== -1) {
            const imageData = this.state.images[oldImageIndex];

            if (imageData.type === 'nfs') {
                this.props.markNfsFileForDeletion(imageData.image);
            }

            const images = this.state.images.slice();
            images.splice(oldImageIndex, 1);

            this.setState({
                images,
                errors: {
                    ...this.state.errors,
                    images: null,
                },
            });
        }
    };

    onSaveResponse = () => {
        const {remark} = this.props;
        const {comment, images} = this.state;

        const errors = {...this.state.errors, comment: null, images: null};

        let valid = true;

        if (!comment) {
            errors.comment = gettext('Add a response');
            valid = false;
        }

        if (!valid) {
            this.setState({errors});
            return;
        }

        this.clearErrors();

        const responseData = {
            id: uuidv4(),
            comment,
            images,
            remark: remark.id,
        };

        this.props.onSaveResponse(responseData, remark.id);
        this.setState({isSuccess: true});
    };

    setError = (field, message) => this.setState({
        errors: {
            ...this.state.errors,
            [field]: message,
        },
    });

    getImageId = () => uuidv4();

    clearErrors = () => this.setState({
        errors: {
            comment: null,
            images: null,
        },
    });

    renderStaticImage = image => (
        <ImageWidget
            key={image ? image.id : 'new'}
            id={`image-${image ? image.id : 'new'}`}
            name={`image-${image ? image.id : 'new'}`}
            imageData={image.image}
            isStatic
        />
    );

    renderImage = image => (
        <ImageWidget
            key={image ? image.id : 'new'}
            id={`image-${image ? image.id : 'new'}`}
            name={`image-${image ? image.id : 'new'}`}
            imageData={image ? image.image : null}
            onChange={this.onAddImage}
            onDelete={() => this.onRemoveImage(image.id)}
            capture={"camera"}

            totalCount={this.state.images.length}

            registerNfsFile={this.props.registerNfsFile}
            onError={e => toast.error(e)}
        />
    );

    renderRemarkType = () => {
        // This is a neutered 'read-only' version for Responses only
        const subcategory = this.props.category ?
            this.props.category.subcategories.find(cat => cat.id === this.props.remark.subcategory) : null;

        return (
            <div>
                <label htmlFor="type">{gettext('Remark type')}</label>
                <div className="regular">
                    {this.props.category[`label_${this.props.activeLanguage}`]}&nbsp;/&nbsp;
                    {subcategory ? subcategory[`label_${this.props.activeLanguage}`] : null}
                </div>
            </div>
        );
    };

    render() {
        const {remark} = this.props;

        if (!remark) {
            return <NotFound />;
        }

        const {errors, images, comment, isSuccess} = this.state;
        const isNotResolved = !remark.last_response ||
            (remark.last_response.state !== DJ_CONST.RESPONSE_STATES.APPROVED);

        return (
            <div className="view__contents sticky">
                <h3><b>{gettext('Respond to remark at the latest')} {remark.deadline}</b></h3>
                {this.renderRemarkType()}
                <TextArea
                    name="previous_comment"
                    className="margin-lg-top"
                    label={gettext('Original comment')}
                    value={remark.comment}
                    cols={40}
                    rows={4}
                    readOnly
                />

                <div className="images--container--wrp">
                    <div className="images--container">
                        {(remark.images || []).map(this.renderStaticImage)}
                    </div>
                </div>

                <hr />

                {
                    remark.last_response && remark.last_response.reviewer_comment ?
                        [
                            <TextArea
                                key="reviewer-text"
                                name="reviewer_comment"
                                className="margin-lg-top"
                                label={gettext('Last reviewer\'s comment')}
                                value={remark.last_response.reviewer_comment}
                                cols={40}
                                rows={4}
                                readOnly
                            />,
                            <hr key="reviewer-border" />,
                        ] : null
                }

                {
                    isNotResolved ?
                        <TextArea
                            name="comment"
                            className="margin-lg-top"
                            label={gettext('Response')}
                            value={comment}
                            cols={40}
                            rows={4}
                            error={errors.comment}
                            onChange={e => this.onChangeField('comment', e.target.value)}
                        /> : null
                }

                {
                    isNotResolved ?
                        <div className="images--container--wrp">
                            <div className="images--container">
                                {this.renderImage(null)}
                                {images.map(this.renderImage)}
                            </div>
                            {errors.images ? <p className="error">{errors.images}</p> : null}
                        </div> : null
                }


                <div className="view__bottom sticky">
                    <div
                        className={`btn btn-success btn-block btn-round btn-bold ${isSuccess || !isNotResolved ?
                            'disabled' : ''}`}
                        onClick={this.onSaveResponse}
                        role="button"
                        tabIndex={0}
                    >
                        {isNotResolved ?
                            isSuccess ? gettext('Response successfully submitted') : gettext('Submit for review')
                            : gettext('This remark is already addressed')}
                    </div>
                </div>
            </div>
        );
    }
}

export default withView(gettext('Remark display'))(connect(mapStateToProps, mapDispatchToProps)(RemarkDisplay));
