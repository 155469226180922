import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


class Input extends Component {
    static defaultProps = {
        addonLeft: null,
        addonRight: null,
        className: null,
        error: null,
        touched: false,
        id: '',
        label: null,
        type: 'text',
        placeholder: '',
        readOnly: false,
    };

    static propTypes = {
        addonLeft: PropTypes.element,
        addonRight: PropTypes.element,
        className: PropTypes.string,
        error: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string,
        touched: PropTypes.bool,
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        readOnly: PropTypes.bool,
    };

    render() {
        const {
            addonLeft, addonRight, className, error, id, label, name, type, touched, placeholder, readOnly, ...props
        } = this.props;

        const hasAddons = addonLeft || addonRight;

        return (
            <div className={classNames('form-group', {'has-error': error})}>
                {label ? (
                    <label
                        htmlFor={id}
                        className='label'
                    >{label}</label>
                ) : null}
                <div className={classNames({'input-group': hasAddons})}>
                    {addonLeft}
                    <input
                        id={id}
                        name={name}
                        type={type}
                        className={classNames('form-control', className)}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        {...props}
                    />
                    {addonRight}
                </div>
                {error ? (
                    <span id={`error-${id}`} className='help-block'>{error}</span>
                ) : null}
            </div>
        );
    }
}

export default Input;
