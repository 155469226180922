import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {gettext} from 'utils/i18n';

import {STATE_KEY, requestFinalizeCheckup} from "ducks/buildingSites";
import {selectors as userSelectors} from 'ducks/user';
import List, {ListItem} from "components/List";
import Input from 'components/inputs/Input';
import TextArea from 'components/TextArea';
import RemovableObject from "components/RemovableObject";
import AddObserverForm from "components/AddObserverForm";
import {CheckUpPropType, UserShape, SitePropType} from "utils/types";
import withView from "../decorators/withView";


const mapStateToProps = (state, ownProps) => {
    const {siteId, checkupId} = ownProps.match.params;

    const site = state[STATE_KEY].data[siteId];
    const categories = state[STATE_KEY].remarkCategories;
    const checkUp = site && site.checkUps && checkupId ? site.checkUps[checkupId] : null;
    const remarks = checkupId ? state[STATE_KEY].remarks[checkupId] : null;

    return {
        site,
        checkupId,
        checkUp,
        remarks,
        categories,
        user: userSelectors.user(state),
    };
};

const mapDispatchToProps = dispatch => ({
    onFinalizeCheckup: checkupData => dispatch(requestFinalizeCheckup(checkupData)),
});

class FinalizeCheckup extends Component {
    constructor(props) {
        super(props);

        const me = {...this.props.user, title: gettext('Checkup conductor')};
        let observers = [];

        if (this.props.checkUp && this.props.checkUp.observers) {
            observers = this.props.checkUp.observers.map(m => ({...m, title: gettext('Observer')}));
        }

        let receivers = [];
        if (this.props.remarks) {
            receivers = this.mapReceivers(this.props.remarks);
        }

        this.state = {
            recipients: {
                general: [me, ...observers],
                partial: receivers,
            },
            errors: {
                employeeCount: null,
            },
            comment: '',
            employeeCount: '',
            showForm: false,
        };
    }

    onAddObserver = (member) => {
        this.setState({
            recipients: {
                ...this.state.recipients,
                general: [
                    ...this.state.recipients.general,
                    {...member, isRemovable: true, title: gettext('Manually added')},
                ],
            },
            showForm: false,
        });
    };

    onChangeField = (field, value) => {
        this.setState({[field]: value});
    };

    mapReceivers = (remarks) => {
        const results = [];
        Object.keys(remarks).forEach((categoryValue) => {
            const remarkArray = remarks[categoryValue][DJ_CONST.REMARK_STATUSES.STATUS_NEGATIVE];
            if (remarkArray) {
                const categoryId = parseInt(categoryValue, 10);
                const categoryLabel = Object.values(this.props.categories)
                    .find(c => c.id === categoryId)[`label_${this.props.activeLanguage}`];
                const categoryObj = {
                    category: categoryLabel,
                    people: [],
                };

                // Filter out partial adds (Yay email & name are optional now)
                remarkArray.filter(r => r.assignee && r.assignee.email && r.assignee.name).forEach((r) => {
                    if (categoryObj.people.findIndex(person =>
                        person.email === r.assignee.email) === -1) {
                        categoryObj.people.push(r.assignee);
                    }
                });

                if (categoryObj.people.length) {
                    results.push(categoryObj);
                }
            }
        });

        return results;
    };

    removeGeneralRecipient = (userId) => {
        this.setState({
            recipients: {
                ...this.state.recipients,
                general: this.state.recipients.general.filter(u => u.id !== userId),
            },
        });
    };

    toggleShowForm = () => this.setState({showForm: !this.state.showForm});

    finalizeCheckup = () => {
        if (this.state.employeeCount && !/^\+?[1-9][\d]*$/.test(this.state.employeeCount)) {
            this.setState({
                errors: {
                    employeeCount: gettext('Employee count should be a positive integer'),
                },
            });
            return;
        }

        const checkupData = {
            observers: this.state.recipients.general.filter(
                obs => obs.id !== this.props.user.id && obs.isRemovable === true),
            id: this.props.checkupId || null,
            site: this.props.site.id,
            employee_count: this.state.employeeCount || 0,
            comment: this.state.comment,
        };
        this.props.onFinalizeCheckup(checkupData);
        this.props.history.replace(`/sites/${this.props.site.id}`);
    };

    renderGeneralRecipients = () => (
        this.state.recipients.general.map((user) => {
            // If user is removable, then pass the `onRemove` function to `RemovableObject`
            const onRemove = user.isRemovable ? () => this.removeGeneralRecipient(user.id) : null;
            return (
                <RemovableObject title={user.name} subtitle={user.title} onRemove={onRemove} key={user.name} />
            );
        })
    );

    renderAddMember = () => {
        // If the form is visible, then show a small title and the form
        if (this.state.showForm) {
            return (
                <List header={gettext("Add new report recipient")}>
                    <AddObserverForm
                        onAddObserver={this.onAddObserver}
                        onClose={this.toggleShowForm}
                        saveBtnTxt={gettext("Add recipient")}
                        referrer={this.props.user.id}
                    />
                </List>
            );
        }
        // Otherwise show the `Add member` button, that opens the form.
        return (
            <span onClick={this.toggleShowForm}>
                <ListItem icon="plus" color="#2ecc71" className="lone-icon">
                    {gettext('Add recipient')}
                </ListItem>
            </span>
        );
    };

    renderRemarksRecipients = () => (
        this.state.recipients.partial.map(remarkCat => (
            <List header={remarkCat.category} key={remarkCat.category}>
                {remarkCat.people.map(user => (
                    <ListItem
                        key={user.email}
                    >
                        <p className="view__list__item__title">{user.name}</p>
                        <span className="view__list__item__subtitle">
                            {user.email}
                        </span>
                    </ListItem>
                ))}
            </List>
        ))
    );

    render() {
        const {comment, employeeCount, errors} = this.state;

        return (
            <div className="view__contents sticky">
                <h2 className="view__contents__title">
                    {gettext("TOM report")}</h2>
                <List header={gettext("TOM report will be sent to:")}>
                    {this.renderGeneralRecipients()}
                    {this.renderAddMember()}
                </List>
                <Input
                    label={gettext('Employee count on the site')}
                    name="employeeCount"
                    type="text"
                    value={employeeCount}
                    error={errors.employeeCount}
                    onChange={e => this.onChangeField('employeeCount', e.target.value)}
                />
                {this.renderRemarksRecipients()}
                <TextArea
                    name="comment"
                    className="margin-lg-top"
                    placeholder={gettext('Add explanation before sending')}
                    value={comment}
                    cols={40}
                    rows={4}
                    onChange={e => this.onChangeField('comment', e.target.value)}
                />
                <div className="view__bottom sticky">
                    <div className="btn btn-success btn-block btn-bold" onClick={() => this.finalizeCheckup()}>
                        {gettext('Send reports to listed people')}
                    </div>
                </div>
            </div>
        );
    }
}

FinalizeCheckup.propTypes = {
    user: UserShape.isRequired,
    checkupId: PropTypes.string.isRequired,
    checkUp: CheckUpPropType,
    site: SitePropType,
    remarks: PropTypes.shape(),
    onFinalizeCheckup: PropTypes.func.isRequired,
    categories: PropTypes.shape().isRequired,
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
    }).isRequired,
    activeLanguage: PropTypes.string.isRequired,
};

FinalizeCheckup.defaultProps = {
    checkUp: null,
    remarks: null,
    site: null,
};

const FinalizeCheckupView = withView(gettext('Submit checkup'), true)(connect(mapStateToProps, mapDispatchToProps)(FinalizeCheckup));
export default FinalizeCheckupView;
