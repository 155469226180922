import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ReactSwipe from 'react-swipe';

import {Icon} from 'react-fa';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {STATE_KEY, SORT_ASC} from 'ducks/buildingSites';

import withView from 'decorators/withView';
import {SitePropType, ReportPropType} from "utils/types";
import {gettext} from 'utils/i18n';
import SortSelect from "components/SortSelect";
import CheckupPieChart from "components/CheckupPieChart";
import SiteOverview from "components/SiteOverview";
import {urlResolve} from "configuration/routes";

const mapStateToProps = (state, ownProps) => {
    const {siteId, checkupId} = ownProps.match.params;
    const slide = ownProps.match.params.slide || null;
    const site = state[STATE_KEY].data[siteId];
    const categories = state[STATE_KEY].remarkCategories;

    return {
        site,
        reportData: state[STATE_KEY].report,
        slide,
        checkupId,
        categories,
    };
};

class TOMOverview extends Component {
    constructor(props) {
        super(props);
        // Even though the <SortSelect /> isn't actually meant for this functionality, for the sake of DRY, let's
        // reuse it for the periodSelect in ComparisonView, hence the field names and directions
        this.periodFilter = [
            {option: {field: 'week', dir: SORT_ASC}, label: gettext('This week')},
            {option: {field: 'month', dir: SORT_ASC}, label: gettext('This month')},
            {option: {field: 'year', dir: SORT_ASC}, label: gettext('This year')},
        ];
        const {slide} = this.props.match.params;

        this.POINTS_ON_GRAPH = 26;

        this.state = {
            startSlide: slide ? parseInt(slide, 10) : 0,
            comparisonPeriod: this.periodFilter[2].option,
            graphEndPoint: this.props.reportData &&
                this.props.reportData.trend ? this.props.reportData.trend.length : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // If the graphEndPoint is null from initial render, fix it eventually
        if ((this.props.reportData && this.props.reportData.trend) &&
            (this.props.reportData.trend.length !== prevState.graphEndPoint)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({graphEndPoint: this.props.reportData.trend.length});
        }
    }

    onDeadlineChange = (field, dir) => {
        this.setState({
            comparisonPeriod: {field, dir},
        });
    };

    prev = () => {
        this.swipe.prev();
    };

    next = () => {
        this.swipe.next();
    };

    changeSlideInHistory = (index) => {
        const {site, checkupId} = this.props;
        if (checkupId) {
            this.props.history.replace(`/sites/${site.id}/${checkupId}/overview/${index}`);
        } else {
            this.props.history.replace(`/sites/${site.id}/overview/${index}`);
        }
    };

    renderProblems = () => (
        <div className="margin-lg-top">
            <h5 className="text-center margin-lg-bottom">{gettext('Biggest problems')}</h5>
            <div className="overview__problems">
                {this.props.reportData.main_issues.last ?
                    this.props.reportData.main_issues.last.issues.map(([category, count]) => {
                        const prevCount = this.props.reportData.main_issues.previous ?
                            this.props.reportData.main_issues.previous.issues.find(
                                ([prevCat]) => prevCat === category)[1] : 0;
                        return (
                            <div className="overview__problem" key={`problem-${category}`}>
                                <div className="overview__problem__icon">
                                    {prevCount === count ?
                                        <Icon
                                            name="arrow-circle-right"
                                            rotate={360}
                                            size="2x"
                                            className="icon-neutral"
                                        /> :
                                        <Icon
                                            name="arrow-circle-right"
                                            rotate={prevCount > count ? '45' : '315'}
                                            size="2x"
                                            className={prevCount > count ? 'icon-success' : 'icon-failure'}
                                        />
                                    }
                                    <span className="overview__problem__count">
                                        {`${count} (${this.props.reportData.main_issues.last.date})`}
                                    </span>
                                </div>
                                <h5 className="overview__problem__topic">
                                    {
                                        this.props.reportData.main_issues.previous ?
                                            `${prevCount} (${this.props.reportData.main_issues.previous.date})` :
                                            null
                                    }
                                    <br />
                                    {Object.values(this.props.categories).find(
                                        c => c.id === category)[`label_${this.props.activeLanguage}`]
                                    }
                                </h5>
                            </div>
                        );
                    }) : null}
            </div>
        </div>
    );

    renderOverview = () => {
        if (!this.props.reportData || !this.props.reportData.trend || !this.props.reportData.trend.length) {
            return (
                <div>
                    <h5 className="text-center">{gettext("There are no checkups for this building site")}</h5>
                </div>
            );
        }
        return (
            <div>
                <SiteOverview
                    trend={this.props.reportData.trend}
                    etom_index_baseline={this.props.reportData.etom_index_baseline}
                    site={this.props.site}
                />
                <div className="overview__statistics">
                    <table>
                        <tbody>
                            <tr>
                                <td>{gettext('Total remarks')}:</td>
                                <td>{this.props.reportData.negative_remarks_count}</td>
                            </tr>
                            <tr>
                                <td>{gettext('Solved remarks')}:</td>
                                <td>{this.props.reportData.solved_remarks_count}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {this.props.categories ? this.renderProblems() : null}
            </div>
        );
    };

    renderCategories = (checkup) => {
        const {site} = this.props;

        if (!site || Object.keys(checkup.by_category).length === 0) {
            return null;
        }

        return (
            <div className="overview__categories">
                {
                    Object.keys(checkup.by_category).map((categoryIdStr) => {
                        const categoryId = parseInt(categoryIdStr, 10);
                        const data = checkup.by_category[categoryId];
                        const category = Object.values(this.props.categories).find(c => c.id === categoryId);
                        const negativePercentage = Math.round((data.negative / data.total) * 100);

                        return (
                            <div key={`cat-${category.id}`} className="checkup__category">
                                <h5>{category[`label_${this.props.activeLanguage}`]}</h5>
                                <div className="progress success">
                                    <div
                                        className="progress-bar progress-bar-danger"
                                        style={{width: `${negativePercentage}%`}}
                                    >
                                        <span className="sr-only">{negativePercentage}%</span>
                                        <Icon className="progress-bar__icon" name="eye" size="2x" />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    renderComparison = () => {
        const {comparison} = this.props.reportData;

        // Sometimes this is undefined
        if (!comparison) {
            return null;
        }

        const activeDataSet = comparison[this.state.comparisonPeriod.field];

        const comparisonGroups = [];

        Object.keys(activeDataSet).forEach((key) => {
            if (key === 'etom_average') {
                comparisonGroups.push({
                    title: gettext('Estonian average'),
                    score: activeDataSet[key],
                    colorTheme: 'danger',
                });
            } else if (key === 'site_average') {
                comparisonGroups.push({
                    title: gettext('Building site average'),
                    score: activeDataSet[key],
                    colorTheme: 'warning',
                });
            } else if (key === 'company_average') {
                comparisonGroups.push({
                    title: gettext('Company average'),
                    score: activeDataSet[key],
                    colorTheme: 'info',
                });
            }
        });


        return (
            <div className="comparison">
                <h5 className="text-center">{gettext("Period")}</h5>
                <SortSelect
                    onChange={this.onDeadlineChange}
                    options={this.periodFilter}
                    selected={this.state.comparisonPeriod}
                    iconName="fa-calendar-check-o"
                />
                <h5 className="text-center margin-lg-top">{gettext("Comparison to other users")}</h5>
                <div>
                    {comparisonGroups.map(group => (
                        <div key={group.title} className="checkup__category">
                            <h5>{group.title}:&nbsp;
                                <strong>
                                    {group.score}
                                    {group.score === 0 ? ` -  ${gettext('Not enough data to display')}` : null}
                                </strong>
                            </h5>
                            <div className="progress">
                                <div
                                    className={classNames('progress-bar', `progress-bar-${group.score > 0 ?
                                        group.colorTheme : 'missing'}`)}
                                    style={{width: `${group.score}%`}}
                                >
                                    <span className="sr-only">${group.score}%</span>
                                    <Icon className="progress-bar__icon" name="arrow-down" size="2x" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {this.renderProblems()}
                <div className="subcats-report-btns">
                    <h5 className="text-center">{gettext("Create a report")}</h5>
                    <Link
                        className='btn btn-blue btn-block'
                        to={urlResolve('building-site-subcategories-report',
                            {siteId: this.props.site.id, startDate: '3m'})}
                    >
                        <Icon name="calendar-check-o" />
                        {gettext('From the last 3 months')}
                    </Link>
                    <Link
                        className='btn btn-blue btn-block'
                        to={urlResolve('building-site-subcategories-report',
                            {siteId: this.props.site.id})
                        }
                    >
                        <Icon name="calendar-check-o" />
                        {gettext('From beginning of the site building')}
                    </Link>
                </div>
            </div>
        );
    };

    renderLastCheckup = () => {
        if (!this.props.reportData || !this.props.reportData.last_checkup) {
            return null;
        }
        const checkup = this.props.reportData.last_checkup;

        if (Object.keys(checkup).length === 0) {
            return (
                <div className="checkup">
                    <h5 className="text-center">{gettext('There is no previous checkup for this building site')}</h5>
                </div>
            );
        }

        return (
            <div className="checkup">
                <CheckupPieChart checkup={checkup} />
                {this.props.reportData.main_issues.last ? <a
                    className="link-to-pdf text-center"
                    href={DJ_CONST.DOWNLOAD_REPORT_PDF + this.props.reportData.main_issues.last.id}
                >
                    <Icon name="paperclip" />{gettext('Download .pdf of this report review')}
                </a> : null}
                {this.renderProblems()}
                {this.renderCategories(checkup)}
            </div>
        );
    };

    renderSlide = (title, contents) => (
        <div className="slide">
            <div className="slide__content">
                {contents}
            </div>
            <div className="slide__bottom">
                <Icon name="chevron-circle-left" size="2x" onClick={this.prev} />
                <span>{title}</span>
                <Icon name="chevron-circle-right" size="2x" onClick={this.next} />
            </div>
        </div>
    );

    render() {
        const {startSlide} = this.state;

        const swipeOptions = {
            startSlide,
            callback: this.changeSlideInHistory,
            continuous: true,
        };

        return (
            <div className="view__contents page--overview">
                <ReactSwipe ref={(node) => { this.swipe = node; }} swipeOptions={swipeOptions}>
                    {this.renderSlide(gettext('Comparison to other users'), this.renderComparison())}
                    {this.renderSlide(gettext('Trend'), this.renderOverview())}
                    {this.renderSlide(gettext('Last checkup'), this.renderLastCheckup())}
                </ReactSwipe>
            </div>
        );
    }
}

TOMOverview.propTypes = {
    site: SitePropType,
    slide: PropTypes.number,
    checkupId: PropTypes.string,
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            siteId: PropTypes.string.isRequired,
            slide: PropTypes.string,
        }).isRequired,
    }).isRequired,
    categories: PropTypes.shape().isRequired,
    reportData: ReportPropType,
    activeLanguage: PropTypes.string.isRequired,
};

TOMOverview.defaultProps = {
    site: null,
    slide: 1,
    checkupId: '',
    activeLanguage: DJ_CONST.LANGUAGE_CODE,
    reportData: {
        etom_index_baseline: 0,
        negative_remarks_count: 0,
        solved_remarks_count: 0,
        main_issues: [[10, 0]],
        trend: [{date: '2018-01-01', etom_index: 100, checkup_id: ''}],
        site: "",
        last_checkup: {
            etom_index: 100,
            positive_remarks: 0,
            negative_remarks: 0,
            total_remarks: 0,
            by_category: {},
        },
        comparison: {
            week: {
                etom_average: 0,
                site_average: 0,
                company_average: 0,
            },
            month: {
                etom_average: 0,
                site_average: 0,
                company_average: 0,
            },
            year: {
                etom_average: 0,
                site_average: 0,
                company_average: 0,
            },
        },
    },
    categories: {},
};

export default withView(gettext('Overview'), true)(connect(mapStateToProps, null)(TOMOverview));
