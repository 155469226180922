import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {connect} from 'react-redux';
import Markdown from 'react-markdown';
import Modal from 'tg-modal';
import uuid from 'uuid';

import withView from 'decorators/withView';
import {gettext} from 'utils/i18n';

import {STATE_KEY, requestAddRemark, addRemark} from "ducks/buildingSites";
import ScoreInput from "components/inputs/ScoreInput";
import {urlResolve} from "configuration/routes";
import {Link} from 'react-router-dom';


const mapStateToProps = (state, ownProps) => {
    let site = state[STATE_KEY].data[ownProps.match.params.siteId];
    if (!site) {
        site = null;
    }

    const checkUpId = ownProps.match.params.checkupId;
    const checkUp = site && site.checkUps ? site.checkUps[checkUpId] : null;

    const remarks = checkUp ? state[STATE_KEY].remarks[checkUp.id] : null;

    // TODO what if not loaded yet?
    const categories = state[STATE_KEY].remarkCategories || [];

    return {
        site,
        checkUpId,
        checkUp,
        remarks,
        categories,
    };
};

const mapDispatchToProps = dispatch => ({
    onSaveRemark: remarkData => dispatch(requestAddRemark(remarkData)),
    onAddRemark: remarkData => dispatch(addRemark(remarkData)),
});

class CheckupScoring extends Component {
    static propTypes = {
        checkUpId: PropTypes.string.isRequired,
        history: PropTypes.shape({
            replace: PropTypes.func.isRequired,
        }).isRequired,
        match: PropTypes.shape({
            url: PropTypes.string.isRequired,
            params: PropTypes.shape({
                siteId: PropTypes.string.isRequired,
                checkupId: PropTypes.string.isRequired,
            }),
        }).isRequired,
        onSaveRemark: PropTypes.func.isRequired,
        onAddRemark: PropTypes.func.isRequired,
        remarks: PropTypes.shape(),
        categories: PropTypes.shape().isRequired,
        activeLanguage: PropTypes.string.isRequired,
    };

    static defaultProps = {
        site: null,
        checkUp: null,
        remarks: null,
        categories: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            confirm: false,
        };

        Object.keys(props.categories).forEach((categorySlug) => {
            this.state[categorySlug] = {
                isOpen: false,
            };
        });
    }

    onFinishCheck = () => {
        if (this.state.confirm) {
            const {siteId, checkupId} = this.props.match.params;
            this.props.history.replace(urlResolve('checkup-finalize', {siteId, checkupId}));
        } else {
            this.setState({confirm: true});
        }
    };

    toggleModal = slug => this.setState({
        [slug]: {
            ...this.state[slug],
            isOpen: !this.state[slug].isOpen,
        },
    });

    increaseScore = (categorySlug) => {
        const {siteId} = this.props.match.params;
        this.props.onSaveRemark({
            checkup: this.props.checkUpId,
            category: this.props.categories[categorySlug].id,
            status: DJ_CONST.REMARK_STATUSES.STATUS_POSITIVE,
            assignee: null,
            images: null,
            id: uuid.v4(),
            siteId,
        });
    };

    addRemark = (categorySlug, type) => {
        const {siteId} = this.props.match.params;
        const freshId = uuid.v4();
        this.props.onAddRemark({ // Add the Remark to State so it could be read in the next view
            checkup: this.props.checkUpId,
            category: this.props.categories[categorySlug].id,
            status: type,
            assignee: [],
            images: [],
            id: freshId,
            siteId,
            isTemporary: true,
        });
        this.props.history.replace(`/remarks/${siteId}/${this.props.checkUpId}/${freshId}`);
    };

    renderModalContent = categorySlug => (
        <Markdown
            source={this.props.categories[categorySlug][`help_${this.props.activeLanguage}`]}
            escapeHtml={false}
        />
    );

    renderCategory = (category) => {
        const pos = DJ_CONST.REMARK_STATUSES.STATUS_POSITIVE;
        const neg = DJ_CONST.REMARK_STATUSES.STATUS_NEGATIVE;
        const remarks = this.props.remarks || null;
        const categoryRemarks = remarks ? remarks[this.props.categories[category.slug].id] : null;

        let positiveScore = 0;
        let negativeScore = 0;
        if (categoryRemarks) {
            positiveScore = categoryRemarks[pos] ? categoryRemarks[pos].filter(rmk => !rmk.isTemporary).length : 0;
            negativeScore = categoryRemarks[neg] ? categoryRemarks[neg].filter(rmk => !rmk.isTemporary).length : 0;
        }

        const {siteId, checkupId} = this.props.match.params;

        return (
            <div key={category.slug} className="category-item">
                <div className="category-item__title">
                    <span className="info-icon-placeholder">
                        <span className="info-icon" onClick={() => this.toggleModal(category.slug)} />
                    </span>
                    <div className="title" onClick={() => this.toggleModal(category.slug)}>
                        {category[`label_${this.props.activeLanguage}`]}
                    </div>
                </div>
                <div className="category-item__score">
                    <ScoreInput
                        showMinusButton={false}
                        icon="check"
                        onScoreChange={() => this.increaseScore(category.slug)}
                        theme="green"
                        value={positiveScore}
                    />
                    <ScoreInput
                        showMinusButton={false}
                        icon="close"
                        onScoreChange={() => this.addRemark(category.slug, DJ_CONST.REMARK_STATUSES.STATUS_NEGATIVE)}
                        theme="red"
                        value={negativeScore}
                    />
                    {negativeScore ? (
                        <div className="remark-icon-placeholder">
                            <Link to={urlResolve(
                                'checkup-remarks-by-category',
                                {categorySlug: category.slug, siteId, checkupId},
                            )
                            }
                            >
                                <span className="remark-icon edit" />
                            </Link>
                        </div>
                    ) :
                        (<div className="remark-icon-placeholder" >
                            <span className="remark-icon" />
                        </div>)
                    }
                </div>

                <Modal
                    autoWrap
                    title="Info"
                    isOpen={this.state[category.slug] ? this.state[category.slug].isOpen : false}
                    onCancel={() => this.toggleModal(category.slug)}
                >
                    {this.renderModalContent(category.slug)}
                </Modal>
            </div>
        );
    };

    render() {
        const {confirm} = this.state;
        const {siteId} = this.props.match.params;

        const hasSite = !!siteId;
        const buttonClasses = classNames('btn btn-block btn-bold', {
            'btn-success': hasSite && !confirm,
            'btn-warning': hasSite && confirm,
            'btn-danger disabled': !hasSite,
        });

        let btnLabel = confirm ? gettext('Confirm finish check up') : gettext('Finish check and send report');

        // If site is not loaded yet change the text of the button
        if (!hasSite) {
            btnLabel = gettext('Site is not loaded yet. Please wait');
        }

        return (
            <div className="view__contents page--checkup-scoring sticky">
                {Object.values(this.props.categories).map(this.renderCategory)}
                <div className="view__bottom sticky finalize-btn">
                    <div className={buttonClasses} onClick={this.onFinishCheck}>
                        {btnLabel}
                    </div>
                </div>
            </div>
        );
    }
}

const CheckupScoringView = withView(gettext('Add Remarks'), true)(connect(mapStateToProps, mapDispatchToProps)(CheckupScoring));
export default CheckupScoringView;
