import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Modal from 'tg-modal';

import {gettext, pgettext} from 'utils/i18n';
import {SitePropType, CheckUpPropType} from 'utils/types';

import {ListItem} from 'components/List';

import {STATE_KEY, requestDeleteCheckup} from "../ducks/buildingSites";


const mapStateToProps = (state, ownProps) => {
    const buildingSite = state[STATE_KEY].data[ownProps.siteId];
    if (!buildingSite) {
        return {buildingSite: null};
    }
    let checkUps = [];
    if (buildingSite.checkUps) {
        checkUps = Object.keys(buildingSite.checkUps).map(key => buildingSite.checkUps[key]);
    }

    return {
        buildingSite,
        checkUps,
        title: buildingSite.name,
    };
};

const mapDispatchToProps = dispatch => ({
    onCheckupDelete: checkupData => dispatch(requestDeleteCheckup(checkupData)),
});


class StartNewCheckup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inProgressModalOpen: false,
            confirmDelete: false,
        };
    }

    checkupInProgress = () => (
        Object.values(this.props.checkUps).find(checkup => (
            checkup.status === DJ_CONST.CHECKUP_STATUSES.STATUS_IN_PROGRESS
        ))
    );

    toggleInProgressModal = () => (
        this.setState(
            prevState => ({
                inProgressModalOpen: !prevState.inProgressModalOpen,
                confirmDelete: false,
            }),
        )
    );

    renderInProgressWarning = () => {
        const checkup = this.checkupInProgress();
        if (!checkup) {
            return null;
        }
        let confirmDelete = (
            <span
                onClick={() => (this.setState({confirmDelete: true}))}
                className="progressaction btn btn-block btn-bold btn-gray"
            >
                {gettext("Delete")}
            </span>
        );

        if (this.state.confirmDelete) {
            confirmDelete = (
                <span
                    onClick={() => this.props.onCheckupDelete(checkup)}
                    className="progressaction btn btn-block btn-bold btn-warning"
                >
                    {gettext("Confirm?")}
                </span>
            );
        }

        return (
            <div>
                <div>
                    {gettext("You can't start a new checkup while another one is in progress for this building site.")}
                    {gettext("What do you want to do with the checkup in progress?")}
                </div>
                <Link
                    to={`/checkups/${this.props.buildingSite.id}/${checkup.id}`}
                    className="progressaction btn btn-block btn-bold btn-success"
                >
                    {pgettext("checkup_list", "Continue")}
                </Link>
                {confirmDelete}
            </div>
        );
    };

    render() {
        // If a checkup is in progress, show modal asking to delete it or continue with it,
        // don't let user start a second one.
        let checkupButton = null;

        if (this.checkupInProgress()) {
            checkupButton = (
                <a onClick={this.toggleInProgressModal} className="prevent_underline">
                    <ListItem
                        icon="plus"
                        color="#2ecc71"
                    >
                        {gettext('Start new checkup')}
                    </ListItem>
                </a>
            );
        } else {
            checkupButton = (
                <ListItem
                    icon="plus"
                    color="#2ecc71"
                    to={`/checkups/${this.props.buildingSite.id}/`}
                    linkProps={{className: "prevent_underline"}}
                >
                    {gettext('Start new checkup')}
                </ListItem>);
        }

        return (
            <div className="start-new-checkup">
                {checkupButton}
                <Modal
                    autoWrap
                    title={gettext("Checkup already in progress")}
                    isOpen={this.state.inProgressModalOpen && this.checkupInProgress() !== undefined}
                    onCancel={() => this.toggleInProgressModal()}
                >
                    {this.renderInProgressWarning()}
                </Modal>
            </div>
        );
    }
}

StartNewCheckup.propTypes = {
    buildingSite: SitePropType,
    checkUps: PropTypes.arrayOf(CheckUpPropType),
    onCheckupDelete: PropTypes.func.isRequired,
};

StartNewCheckup.defaultProps = {
    buildingSite: null,
    checkUps: [],
};

const StartNewCheckupConnector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StartNewCheckup);


export default StartNewCheckupConnector;
