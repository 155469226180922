import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import withView from "decorators/withView";
import {gettext} from 'utils/i18n';

import List, {ListItem} from 'components/List';
import StartNewCheckup from 'components/StartNewCheckup';

import {STATE_KEY} from "ducks/buildingSites";
import {SitePropType, CheckUpPropType} from 'utils/types';


const mapStateToProps = (state, ownProps) => {
    const buildingSite = state[STATE_KEY].data[ownProps.match.params.siteId];
    if (!buildingSite) {
        return {buildingSite: null};
    }
    let checkUps = [];
    if (buildingSite.checkUps) {
        checkUps = Object.keys(buildingSite.checkUps).map(key => buildingSite.checkUps[key]);
    }

    return {
        buildingSite,
        checkUps,
        title: buildingSite.name,
    };
};

const BuildingSiteCheckupList = ({buildingSite, checkUps}) => (
    <div className="view__contents">
        {buildingSite ? null : (<Redirect to="/404" />)}
        {buildingSite ? (
            <Fragment>
                <div className="view__contents__header">
                    <h1 className="view__contents__title">{buildingSite.name}</h1>
                    <h2 className="view__contents__subtitle">{buildingSite.address}</h2>
                </div>
                <List>
                    <StartNewCheckup siteId={buildingSite.id} />
                    {checkUps.map(checkUp => (
                        <ListItem
                            key={checkUp.id}
                            to={`/sites/${buildingSite.id}/${checkUp.id}/overview/2`}
                            icon={checkUp.status === DJ_CONST.CHECKUP_STATUSES.STATUS_IN_PROGRESS
                                ? "exclamation" : null}
                            color="#3498db"
                        >
                            {checkUp.date}
                        </ListItem>
                    ))}
                </List>
            </Fragment>
        ) : null}
    </div>
);

BuildingSiteCheckupList.propTypes = {
    buildingSite: SitePropType,
    checkUps: PropTypes.arrayOf(CheckUpPropType),
};

BuildingSiteCheckupList.defaultProps = {
    buildingSite: null,
    checkUps: [],
};

const BuildingSiteCheckupListConnector = connect(
    mapStateToProps,
)(BuildingSiteCheckupList);


export default withView(gettext("Select checkup"), true)(BuildingSiteCheckupListConnector);
