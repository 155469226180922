export const STATE_KEY = 'nfs';

const REGISTER_NFS_FILE = `${STATE_KEY}/REGISTER`;
const MARK_FOR_DELETION = `${STATE_KEY}/MARK_DELETION`;
const UNLINK_NFS_FILE = `${STATE_KEY}/UNLINK`;

// Register an nfs file (so we know about it)
// Note: The NfsSaga also periodically deletes files the store does not know about (if they are older than 2 days).
//  This helps us keep the storage area under control in the case of crashes.
export const registerNfsFile = imageUrl => ({type: REGISTER_NFS_FILE, imageUrl});

// Mark an nfs file for deletion (this is done when we successfully have sent the file to server)
// NfsSaga will delete the file and will use `unlinkNfsFile` to remove traces of the image from the store
export const markNfsFileForDeletion = imageUrl => ({type: MARK_FOR_DELETION, imageUrl});

// This is used to remove tracked files from this store
//  called after a file is been deleted
export const unlinkNfsFile = imageUrl => ({type: UNLINK_NFS_FILE, imageUrl});

// key: nfs file url
// value: boolean (true === the file is still needed, false === the file can be deleted)
const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case REGISTER_NFS_FILE:
            return {
                ...state,
                [action.imageUrl]: true,
            };

        case MARK_FOR_DELETION:
            return {
                ...state,

                [action.imageUrl]: false,
            };

        case UNLINK_NFS_FILE: {
            const nState = {...state};
            delete nState[action.imageUrl];

            return nState;
        }

        default:
            return state;
    }
}
