import {call, put} from 'redux-saga/effects';

import api from 'utils/api';
import fizzlesWhenOffline from "utils/fizzlesWhenOffline";
import {handleApiFetch} from 'sagas/helpers/callApi';

import {addRemark} from "ducks/buildingSites";

function* getRemark(matchObj) {
    const {result: response, error} = yield call(handleApiFetch, api.apiRemarkRetrieve,
        {pk: matchObj.params.remarkId}, null, null,
    );

    if (response) {
        yield put(addRemark(response));
    }

    if (error) {
        if (error.statusCode === 404) { // The Remark only exists in the front-end, nothing to see here
            return null;
        }
        return error;
    }

    return null;
}

export default fizzlesWhenOffline(getRemark);
