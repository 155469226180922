import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Icon} from 'react-fa';
import {Link} from 'react-router-dom';


const ListItem = ({children, className, idName, icon, color, to, linkProps}) => {
    const item = (
        <span className={classNames("view__list__item", className)} {...{id: idName}}>
            {icon ? (<Icon name={icon} className="view__list__item__icon" style={{backgroundColor: color}} />) : null}
            <span className="view__list__item__text">{children}</span>
        </span>
    );

    if (to) {
        return <Link to={to} {...linkProps}>{item}</Link>;
    }

    return item;
};

ListItem.propTypes = {
    className: PropTypes.oneOfType([
        PropTypes.shape(),
        PropTypes.string,
    ]),
    color: PropTypes.string,
    icon: PropTypes.string,
    to: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    idName: PropTypes.string,
    linkProps: PropTypes.shape(),
};

ListItem.defaultProps = {
    className: null,
    icon: null,
    color: '',
    to: null,
    idName: null,
    linkProps: {},
};

export default ListItem;
