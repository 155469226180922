import React from 'react';

import View from 'containers/View';

/**
 * Wrapper to use around views. Adds Navbar with specified title and parameters.
 * @param Component: The component to wrap.
 * @param title: Title shown in the Navbar
 * @param decoratorProps: Props passed on to the view and navbar.
 *        - navbarLeft: Component to show in the left part of navbar
 *        - navbarRight: Component to show in the right part of navbar
 *
 * Usage:
 * ```
 * export default appView('Ülevaade')(Dashboard);
 * ```
 */
const appView = (Component, title, decoratorProps = {}) => {
    const WrappedComponent = props => (
        <View title={title} {...props} {...decoratorProps}>
            <Component {...props} />
        </View>
    );
    WrappedComponent.displayName = `appView(${Component.displayName || Component.name})`;
    return WrappedComponent;
};

export default (title, props = {}) => target => appView(target, title, props);
