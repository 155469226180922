import React from 'react';
import PropTypes from 'prop-types';
import {withFormik} from 'formik';
import Yup from 'yup';

import Input from 'components/inputs/Input';
import Checkbox from 'components/inputs/Checkbox';
import {urlResolve} from 'configuration/routes';
import {gettext, pgettext} from 'utils/i18n';
import {getFormPropTypes} from 'utils/types';


const Signup = ({values, touched, errors, status, isSubmitting, handleChange, handleBlur, handleSubmit}) => (
    <form onSubmit={handleSubmit}>
        {status !== undefined ?
            <div className='alert alert-danger' role='alert'>
                {status}
            </div> : null
        }
        <Input
            id='name'
            name='name'
            placeholder={gettext('Enter name')}
            label={gettext('Name')}
            type='text'
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <Input
            id='email'
            name='email'
            placeholder={gettext('Enter email')}
            label={gettext('Email')}
            type='text'
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <Input
            id="company_name"
            name="company_name"
            placeholder={gettext('Your company')}
            label={gettext('Company name')}
            value={values.company_name}
            touched={touched.company_name}
            error={errors.company_name}
            onChange={handleChange}
            onBlur={handleBlur}
        />
        <Input
            id='password'
            name='password'
            placeholder={gettext('Password')}
            label={gettext('Password')}
            type='password'
            value={values.password}
            touched={touched.password}
            error={errors.password}
            onChange={handleChange}
            onBlur={handleBlur}
        />

        <Input
            id='repeat_password'
            name='repeat_password'
            placeholder={gettext('Repeat password')}
            label='Repeat Password'
            type='password'
            value={values.repeat_password}
            error={errors.repeat_password}
            touched={touched.repeat_password}
            onChange={handleChange}
            onBlur={handleBlur}
        />

        <Checkbox
            name='agree_terms'
            checked={values.agree_terms}
            onChange={handleChange}
            label={
                <span>
                    {pgettext('signup', 'I agree with the')}&nbsp;
                    <a
                        href={urlResolve('terms-latest')}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {pgettext('signup', 'Terms of Service')}
                    </a>
                </span>
            }
        />

        <button
            type='submit'
            disabled={isSubmitting || !values.agree_terms}
            className='btn btn-lg btn-block btn-success'
        >
            {gettext('Sign up')}
        </button>

    </form>
);


Signup.propTypes = {
    ...getFormPropTypes(['email', 'password', 'repeatPassword', 'agree_terms']),
};


const SignupForm = withFormik({
    mapPropsToValues: () => ({
        email: '',
        password: '',
    }),
    validationSchema: Yup.object().shape({
        email: Yup.string().email(gettext('Invalid email address')),
        password: Yup.string(),
    }),

    handleSubmit: (values, {props, setErrors, setSubmitting, setStatus}) => (
        props.onSignup(values, setErrors, setSubmitting, setStatus)
    ),

    displayName: 'SignupForm', // helps with React DevTools
})(Signup);


SignupForm.propTypes = {
    onSignup: PropTypes.func.isRequired,
};


export default SignupForm;
