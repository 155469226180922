import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import {slide as Menu} from 'react-burger-menu';
import {Link} from 'react-router-dom';
import {NavLink} from 'reactstrap';
import Checkbox from 'components/inputs/Checkbox';

import {gettext, pgettext} from 'utils/i18n';
import {connect} from "react-redux";
import {urlResolve} from "configuration/routes";
import {UserShape} from "utils/types";
import {setActiveLanguage, requestUpdateRemarksCcEmailStatus, selectors as userSelectors} from 'ducks/user';
import {logout} from "sagas/user/logoutSaga";

const links = [
];

const languages = [
    {languageCode: 'gb', djangoLanguageCode: 'en'},
    {languageCode: 'ee', djangoLanguageCode: 'et'},
    {languageCode: 'ru', djangoLanguageCode: 'ru'},
];

const makeNavLink = link => (<NavLink key={link.name} to={link.link}>{link.name}</NavLink>);

const setLanguage = (languageCode, isOnline) => {
    if (isOnline) {
        window.location.reload();
    } else {
        const root = document.querySelector('#root');
        ReactDOM.unmountComponentAtNode(root);
        window.initEtom();
    }
};

const LanguageSelector = ({languageCode, djangoLanguageCode, onSetLanguage, isOnline}) => (
    <span
        className={`flag-icon flag-icon-${languageCode} flag-icon-squared`}
        onClick={() => { setLanguage(djangoLanguageCode, isOnline); onSetLanguage(djangoLanguageCode); }}
    />
);

LanguageSelector.propTypes = {
    languageCode: PropTypes.string.isRequired,
    djangoLanguageCode: PropTypes.string.isRequired,
    onSetLanguage: PropTypes.func.isRequired,
    onChangeCcEmailStatus: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    isOnline: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    user: userSelectors.user(state),
    isAuthenticated: userSelectors.isAuthenticated(state),
    activeLanguage: userSelectors.activeLanguage(state),
    isOnline: state.offline.online,
});

const mapDispatchToProps = dispatch => ({
    onLogout: () => dispatch(logout(urlResolve('landing'))),
    onSetLanguage: language => dispatch(setActiveLanguage(language)),
    onChangeCcEmailStatus: status => dispatch(requestUpdateRemarksCcEmailStatus(status)),
});

class Offcanvas extends Component {
    static propTypes = {
        user: UserShape,
        isAuthenticated: PropTypes.bool.isRequired,
        onLogout: PropTypes.func.isRequired,
        onSetLanguage: PropTypes.func.isRequired,
        onChangeCcEmailStatus: PropTypes.func.isRequired,
        isOnline: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        user: {},
    };

    state = {
        isMenuOpen: false,
    };

    render() {
        return (
            <Menu right width={'100%'} isOpen={this.state.isMenuOpen}>
                <div className="language_select">
                    {languages.map(language => (<LanguageSelector
                        key={language.languageCode}
                        {...language}
                        onSetLanguage={this.props.onSetLanguage}
                        isOnline={this.props.isOnline}
                    />))}
                </div>
                {links.map(makeNavLink)}
                {this.props.isAuthenticated ?
                    [
                        <NavLink key={'settings'} href='#'>{this.props.user.email}</NavLink>,
                        <NavLink key={'logout'} href='#' onClick={this.props.onLogout}>
                            {gettext('Logout')}
                        </NavLink>,
                    ] : (
                        <NavLink to={urlResolve('login')} tag={Link} onClick={() => this.setState({isMenuOpen: false})}>
                            {gettext('Login')}</NavLink>
                    )
                }
                <NavLink
                    to={urlResolve('privacy-policy')}
                    tag={Link}
                    onClick={() => this.setState({isMenuOpen: false})}
                >
                    {pgettext('menu-link', 'Privacy policy')}
                </NavLink>
                <NavLink
                    to={urlResolve('terms-latest')}
                    tag={Link}
                    onClick={() => this.setState({isMenuOpen: false})}
                >
                    {gettext('Terms')}
                </NavLink>
                {this.props.isAuthenticated ?
                    <Checkbox
                        name='remarks_cc_email'
                        checked={this.props.user.remarks_cc_email}
                        onChange={(evt) => {
                            this.props.onChangeCcEmailStatus(evt.target.checked);
                            this.setState({isMenuOpen: true});
                        }}
                        label={gettext('Send copy of remarks email to me')}
                    /> : null
                }
            </Menu>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offcanvas);
