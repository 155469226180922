import {call, select} from 'redux-saga/effects';

export default function fizzlesWhenOffline(f) {
    function* fizzledGenerator(...args) {
        const offlineStore = yield select(state => state.offline);
        if (offlineStore.online) {
            yield call(f, ...args);
        }
    }

    return fizzledGenerator;
}
