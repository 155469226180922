import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import {gettext} from "utils/i18n";
import Offcanvas from "containers/Offcanvas";


const mapStateToProps = state => ({
    isOnline: state.offline.online,
});

class Navbar extends Component {
    static propTypes = {
        navbarLeft: PropTypes.node,
        navbarRight: PropTypes.node,
        title: PropTypes.string,
        isOnline: PropTypes.bool,
        goBack: PropTypes.func,
        canGoBack: PropTypes.bool,
        showGoBackWarning: PropTypes.func,
    };

    static defaultProps = {
        navbarLeft: undefined,
        navbarRight: undefined,
        title: '',
        isOnline: true,
        canGoBack: true,
        goBack: (props) => { props.history.goBack(); },
        showGoBackWarning: () => {},
    };

    goBackPressed = () => {
        if (this.props.canGoBack) {
            this.props.goBack(this.props);
        } else {
            this.props.showGoBackWarning(true);
        }
    };

    renderBack = () => (
        <div className="back" onClick={this.goBackPressed}>
            <span className="lnr lnr-chevron-left margin-sm-right" />{gettext('Back')}
        </div>
    );

    renderLeft = () => {
        const {navbarLeft} = this.props;
        const leftContent = navbarLeft !== undefined ? navbarLeft : this.renderBack();
        return (
            <div className="navbar-item navbar-left">
                {leftContent}
            </div>
        );
    };

    renderRight = () => {
        const {navbarRight} = this.props;

        const rightContent = navbarRight !== undefined ? navbarRight : (<Offcanvas />);
        return (
            <div className="navbar-item navbar-right">
                {rightContent}
            </div>
        );
    };

    render() {
        return (
            <div className={`navbar navbar-default ${this.props.isOnline ? "" : "offline"}`}>
                {this.renderLeft()}
                <div className="navbar-item navbar-center">
                    {this.props.isOnline ? this.props.title : gettext("You are offline")}
                </div>
                {this.renderRight()}
            </div>
        );
    }
}


export default withRouter(connect(mapStateToProps)(Navbar));
