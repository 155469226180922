import {combineReducers} from 'redux';

export const STATE_KEY = 'referred';
export const ADD_OBSERVERS = `${STATE_KEY}/ADD_OBSERVERS`;
export const ADD_ASSIGNEES = `${STATE_KEY}/ADD_ASSIGNEES`;


export function observersReducer(state = [], action) {
    switch (action.type) {
        case ADD_OBSERVERS:
            return action.observers;

        default:
            return state;
    }
}

export function assigneesReducer(state = [], action) {
    switch (action.type) {
        case ADD_ASSIGNEES:
            return action.assignees;

        default:
            return state;
    }
}

export default combineReducers({
    observers: observersReducer,
    assignees: assigneesReducer,
});

export const addObservers = observers => ({type: ADD_OBSERVERS, observers});
export const addAssignees = assignees => ({type: ADD_ASSIGNEES, assignees});

export const selectors = {
    observers: state => state[STATE_KEY].observers,
    assignees: state => state[STATE_KEY].assignees,
};

