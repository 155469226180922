import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Filter = ({iconName, multiple, name, options, onChange, value}) => (
    <div className='etom-select'>
        <div className={classNames('select-arrow', 'fa', iconName)} />
        <select
            id={name}
            value={value}
            multiple={multiple}
            name={name}
            onChange={e => onChange(name, e.target.selectedOptions)}
            className="filter__select"
            size={1}
        >
            {options.map(option => (
                <option key={`${name}-${option.slug}`} value={option.slug}>{option.label}</option>
            ))}
        </select>
    </div>
);

Filter.propTypes = {
    iconName: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
};

Filter.defaultProps = {
    iconName: null,
    multiple: false,
};

export default Filter;
