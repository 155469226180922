import React, {Component} from 'react';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import uuidv4 from 'uuid/v4';
import moment from 'moment';

import {gettext} from 'utils/i18n';
import {RemarkPropType, AssigneePropType} from 'utils/types';
import validateEmail from "utils/validateEmail";

import {requestUpdateRemark} from "ducks/buildingSites";

import Input from 'components/inputs/Input';
import TextArea from 'components/TextArea';
import ImageWidget from 'components/ImageWidget';
import Select from 'react-select';
import {markNfsFileForDeletion, registerNfsFile} from 'ducks/nfs';
import differenceBy from 'lodash.differenceby';

const mapDispatchToProps = dispatch => ({
    registerNfsFile: imageUrl => dispatch(registerNfsFile(imageUrl)),
    markNfsFileForDeletion: imageUrl => dispatch(markNfsFileForDeletion(imageUrl)),
    onSaveRemark: remarkData => dispatch(requestUpdateRemark(remarkData)),
});

class RemarkEdit extends Component {
    static propTypes = {
        activeLanguage: PropTypes.string.isRequired,
        onSaving: PropTypes.func.isRequired,
        remark: RemarkPropType.isRequired,
        assignees: PropTypes.arrayOf(AssigneePropType).isRequired,
        onRef: PropTypes.func.isRequired,
        checkChanges: PropTypes.func.isRequired,
        registerNfsFile: PropTypes.func.isRequired,
        markNfsFileForDeletion: PropTypes.func.isRequired,
        onSaveRemark: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            images: this.props.remark.images,
            comment: this.props.remark.comment,
            activeLanguage: this.props.activeLanguage,
            assignees: this.props.assignees,
            email: this.props.remark.assignee ? this.props.remark.assignee.email : '',
            name: this.props.remark.assignee ? this.props.remark.assignee.name : '',
            deadline: this.props.remark.deadline ?
                moment(this.props.remark.deadline, 'DD.MM.YYYY').format('YYYY-MM-DD') : null,
            errors: {
                comment: null,
                images: null,
            },
        };
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUpdate(nextProps, nextState) {
        const prevImages = this.state.images;
        const nextImages = nextState.images;
        if (this.state.comment !== nextState.comment ||
            this.state.name !== nextState.name ||
            this.state.email !== nextState.email ||
            this.state.deadline !== nextState.deadline ||
            differenceBy(prevImages, nextImages, 'id').length ||
            differenceBy(nextImages, prevImages, 'id').length
        ) {
            this.props.checkChanges(nextState);
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    onChangeField = (field, value) => {
        this.setState({[field]: value});
    };


    onChangeAssignee = (field, value) => {
        this.setState({
            name: value ? value.name : field === 'name' ? '' : this.state.name,
            email: value ? value.email : field === 'email' ? '' : this.state.email,
        });
    };

    onAddImage = (data) => {
        if (!data) {
            this.setError('imageData', gettext('Image must be selected'));
            return;
        }
        this.clearErrors();

        const image = {
            id: this.getImageId(),

            ...(data.type === 'nfs' ? {
                type: 'nfs',
                name: data.name,
                image: data.url,
                size: data.size,
            } : {
                type: 'datauri',
                name: data.name,
                image: data.data,
                size: data.size,
            }),
        };

        this.setState({
            images: [
                ...this.state.images,
                image,
            ],
        });
    };

    onRemoveImage = (id) => {
        const oldImageIndex = this.state.images.findIndex(image => image.id === id);

        if (oldImageIndex !== -1) {
            const imageData = this.state.images[oldImageIndex];

            if (imageData.type === 'nfs') {
                this.props.markNfsFileForDeletion(imageData.image);
            }

            const images = this.state.images.slice();
            images.splice(oldImageIndex, 1);

            this.setState({
                images,
                errors: {
                    ...this.state.errors,
                    images: null,
                },
            });
        }
    };

    onSaveRemark = (e) => {
        e.stopPropagation();

        const {remark} = this.props;
        const {comment, name, email, deadline, images} = this.state;

        const errors = {...this.state.errors, comment: null, name: null, email: null, deadline: null, images: null};

        let valid = true;

        if (deadline && moment(deadline, "YYYY-MM-DD").isBefore(moment(), 'day')) {
            errors.deadline = gettext("A deadline shouldn't be in the past");
            valid = false;
        }

        if (!valid) {
            this.setState({errors});
            return;
        }

        this.clearErrors();

        const remarkData = {
            checkup: this.props.remark.checkup,
            assignee: name && email ? {
                name,
                email,
            } : null,
            comment,
            // because empty deadline equals empty string
            deadline: deadline || null,
            category: this.props.remark.category,
            subcategory: this.props.remark.subcategory,
            status: remark.status,
            id: remark.id,
            images,
            // created date for keeping order
            created: this.props.remark.created,
        };
        this.props.onSaveRemark(remarkData);
        this.props.onSaving();
    };

    getImageId = () => uuidv4();

    clearErrors = () => this.setState({
        errors: {
            comment: null,
            name: null,
            email: null,
            deadline: null,
            images: null,
        },
    });

    renderImage = image => (
        <ImageWidget
            key={image ? image.id : 'new'}
            id={`image-${image ? image.id : 'new'}`}
            name={`image-${image ? image.id : 'new'}`}
            imageData={image ? image.image : null}
            onChange={this.onAddImage}
            onDelete={() => this.onRemoveImage(image.id)}
            capture={"camera"}
            totalCount={this.state.images.length}
            registerNfsFile={this.props.registerNfsFile}
            onError={e => toast.error(e)}
        />
    );

    render() {
        const {comment, images, name, email, deadline, errors, assignees} = this.state;

        if (!assignees) {
            return (<span />);
        }

        return (
            <div>
                <TextArea
                    name="comment"
                    className="margin-lg-top"
                    label={gettext('Comment')}
                    value={comment}
                    cols={40}
                    rows={4}
                    error={errors.comment}
                    onChange={e => this.onChangeField('comment', e.target.value)}
                />

                <div className="images--container--wrp">
                    <div className="images--container">
                        {this.renderImage(null)}
                        {images.map(this.renderImage)}
                    </div>
                    {errors.images ? <p className="error">{errors.images}</p> : null}
                </div>
                <div>
                    <div className="margin-lg-top margin-sm-bottom">{gettext('Responsible person')}</div>
                    <div className="form-group margin-md-left margin-md-right">
                        <div className="form-group">
                            <span className="label">{gettext('Name')}</span>
                            <Select.Creatable
                                options={assignees.filter(o => o.name).map(o =>
                                    ({value: o.name, label: o.name, email: o.email, name: o.name}))}
                                onChange={value => this.onChangeAssignee('name', value)}
                                onNewOptionClick={(o) => {
                                    const lastIdx = assignees.length - 1;
                                    const lastObs = assignees[lastIdx];
                                    if (lastIdx === -1 || (lastObs && lastObs.id)) {
                                        assignees.push({email, name: o.label});
                                    } else {
                                        assignees[lastIdx].name = o.label;
                                    }
                                    this.setState({assignees});
                                }}
                                placeholder={gettext('Enter name')}
                                value={name}
                                name="name"
                            />
                        </div>
                        <div className="form-group">
                            <span className="label">{gettext('Email')}</span>
                            <Select.Creatable
                                options={assignees.filter(o => o.email).map(o =>
                                    ({value: o.email, label: o.email, email: o.email, name: o.name}))}
                                onChange={value => this.onChangeAssignee('email', value)}
                                onNewOptionClick={(o) => {
                                    const lastIdx = assignees.length - 1;
                                    const lastObs = assignees[lastIdx];
                                    if (lastIdx === -1 || (lastObs && lastObs.id)) {
                                        assignees.push({email: o.label, name});
                                    } else {
                                        assignees[lastIdx].email = o.label;
                                    }
                                    this.setState({assignees});
                                }}
                                isValidNewOption={o => validateEmail(o.label)}
                                placeholder={gettext('Enter email')}
                                value={email}
                                name="email"
                            />
                        </div>
                        <Input
                            label={gettext('Deadline')}
                            name="deadline"
                            type="date"
                            value={deadline || ""}
                            min={moment().format('YYYY-MM-DD')}
                            error={errors.deadline}
                            onChange={e => this.onChangeField('deadline', e.target.value)}
                        />
                    </div>
                </div>
                <div className="view__bottom">
                    <div className="btn btn-success btn-block btn-round btn-bold" onClick={e => this.onSaveRemark(e)}>
                        {gettext('Save remark')}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(RemarkEdit);
