import {delay} from 'redux-saga';
import {all, call, fork, put, select, take, takeEvery} from 'redux-saga/effects';

import {ADD_REMARK, ADD_RESPONSE} from 'ducks/buildingSites';
import {markNfsFileForDeletion, STATE_KEY, unlinkNfsFile} from 'ducks/nfs';
import {getMetadata, getNativeFS, ls, unlinkFile} from '../utils/nfs';


const getPersistStatus = state => (
    state && state._persist ? state._persist.rehydrated : false // eslint-disable-line no-underscore-dangle
);

/**
 * Listens on image upload commits and marks the files for deletion
 *
 * @param action ADD_REMARK | ADD_RESPONSE
 */
function* nfsCommitWatcher(action) {
    if (action.isCommit && action.nfsImages && action.nfsImages.length) {
        const actions = action.nfsImages.map(imageUrl => put(markNfsFileForDeletion(imageUrl)));

        yield all(actions);
    }
}

function* deleteNfsFile(imageUrl) {
    try {
        // Try to delete it with nfs (throws if fails
        yield call(unlinkFile, imageUrl);

        // Forget about it
        yield put(unlinkNfsFile(imageUrl));
    } catch (e) {
        console.error(`deleteNfsFile: Failed to delete file ${imageUrl}`);
    }
}

const deleteNfsFileTrigger = imageUrl => call(deleteNfsFile, imageUrl);

function* nfsDeleteWorker() {
    // Note: Delays are used for throttling to avoid affecting ui perfomance

    while (true) {
        const allNfsFiles = yield select(state => state[STATE_KEY]);
        const toDeleteFiles = Object.keys(allNfsFiles).filter(imageUrl => !allNfsFiles[imageUrl]);

        if (toDeleteFiles.length > 0) {
            console.log(`nfsDeleteWorker: Removing ${toDeleteFiles.length} files`);

            const queue = toDeleteFiles.map(deleteNfsFileTrigger);

            while (queue.length > 0) {
                yield queue.pop();
                yield delay(10);
            }
        }

        // wait 60 seconds before deleting again
        yield delay(60 * 1000);
    }
}

function* nfsCleanupWorker() {
    // Note: Delays are used for throttling to avoid affecting ui perfomance

    // Trigger the first cleanup 10 seconds after loading the page
    yield delay(10 * 1000);

    while (true) {
        try {
            const results = yield call(ls);
            const allNfsFiles = yield select(state => state[STATE_KEY]);
            const allKnownFiles = Object.keys(allNfsFiles);
            const deleteThreshold = new Date(new Date() - (2 * 60 * 60 * 1000)); // now - 2 hours

            // eslint-disable-next-line no-loop-func
            const unknownFiles = results.filter(fileEntry => allKnownFiles.indexOf(fileEntry.toURL()) === -1);

            // Also check the age of the file
            // eslint-disable-next-line no-loop-func
            const metaDataResults = yield all(unknownFiles.map(fileEntry => call(getMetadata, fileEntry)));

            for (let i = 0; i < unknownFiles.length; i += 1) {
                const fileEntry = unknownFiles[i];
                const metaData = metaDataResults[i];

                if (metaData.modificationTime <= deleteThreshold) {
                    yield deleteNfsFileTrigger(fileEntry.toURL());
                    yield delay(10);
                }
            }
        } catch (e) {
            console.error('nfsCleanupWorker: Failed to read directory contents', e);

            // Kill the worker if nfs is not supported
            if (`${e}` === 'NFS.ls NFS is not supported') {
                break;
            }
        }

        // wait 120 seconds before deleting again
        yield delay(120 * 1000);
    }
}


export default function* nfsSagaRoot() {
    // Wait for state rehydrate to be complete if not rehydrated
    const rehydrated = yield select(getPersistStatus);
    if (!rehydrated) {
        yield take('persist/REHYDRATE');
    }

    // Wait for nfs to be available
    // Delay until nfs support is detected...
    while (true) {
        const nfs = getNativeFS();

        if (nfs === false || nfs !== null) {
            break;
        }

        yield delay(1000);
    }

    yield all([
        takeEvery(ADD_REMARK, nfsCommitWatcher),
        takeEvery(ADD_RESPONSE, nfsCommitWatcher),

        fork(nfsDeleteWorker),
        fork(nfsCleanupWorker),
    ]);
}
