import React from 'react';
import Markdown from 'react-markdown';
import appView from "utils/appView";
import {gettext} from 'utils/i18n';


const Privacy = () => (
    <div className='page--privacy'>
        <h1 className='privacy-heading'>{gettext('Privacy policy')}</h1>
        <Markdown
            className="privacy-text"
            source={gettext('Privacy policy full text\n')}
            escapeHtml={false}
        />
    </div>
);

export default appView(gettext('Privacy policy'))(Privacy);
