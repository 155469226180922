import App from 'containers/App';
import PageNotFound from 'views/PageNotFound';

import activateLanguage from 'sagas/user/activateLanguage';
import fetchUserDetails from 'sagas/user/fetchUserDetails';
import logoutSaga from 'sagas/user/logoutSaga';
import loginWatcher from 'sagas/user/loginSaga';
import signupWatcher from 'sagas/user/signupSaga';
import offlineVersion from 'sagas/offlineVersion';
import fetchBuildingSites, {getBuildingSite} from 'sagas/sites/fetchBuildingSites';
import fetchBuildingSiteCheckups from 'sagas/sites/fetchBuildingSiteCheckups';
import fetchBuildingSiteReport from 'sagas/sites/fetchBuildingSiteReport';
import fetchBuildingSiteSubcategoriesReport from 'sagas/sites/fetchBuildingSiteSubcategoriesReport';
import getCheckup from "sagas/checkups/getCheckup";
import getRemark from "sagas/remarks/getRemark";
import fetchRemarks from "sagas/remarks/fetchRemarks";
import fetchRemarkCategories from "sagas/remarks/fetchRemarkCategories";
import fetchObservers from "sagas/referred/fetchObservers";
import fetchAssignees from "sagas/referred/fetchAssignees";

import AddBuilding from 'views/AddBuilding';
import BuildingSiteCheckupList from 'views/BuildingSiteCheckupList';
import BuildingSiteDetails from 'views/BuildingSiteDetails';
import BuildingSiteList from 'views/BuildingSiteList';
import CheckupDetails from 'views/CheckupDetails';
import CheckupScoring from 'views/CheckupScoring';
import Dashboard from 'views/Dashboard';
import FinalizeCheckup from 'views/FinalizeCheckup';
import Login from 'views/Login';
import Signup from 'views/Signup';
import Terms from 'views/Terms';
import Privacy from 'views/Privacy';
import RemarkDetails from 'views/RemarkDetails';
import CheckupRemarksByCategory from 'views/CheckupRemarksByCategory';
import RemarkDisplay from 'views/RemarkDisplay';
import RemarkList from 'views/RemarkList';
import Remarks from 'views/Remarks';
import TOMOverview from 'views/TOMOverview';
import SubcategoriesReport from 'views/SubcategoriesReport';


const routes = [
    {
        component: App,
        initial: [
            fetchRemarkCategories,
            fetchUserDetails,
        ],
        watcher: [
            logoutSaga,
            loginWatcher,
            activateLanguage,
            offlineVersion,
        ],
        routes: [
            {
                path: '/',
                exact: true,
                name: 'landing',
                component: Dashboard,
            },
            {
                path: '/login',
                exact: true,
                name: 'login',
                component: Login,
            },
            {
                path: '/signup',
                exact: true,
                name: 'signup',
                component: Signup,
                watcher: signupWatcher,
            },
            {
                path: '/terms',
                exact: true,
                name: 'terms-latest',
                component: Terms,
            },
            {
                path: '/privacy-policy',
                exact: true,
                name: 'privacy-policy',
                component: Privacy,
            },
            {
                path: '/sites',
                exact: true,
                name: 'building-sites',
                component: BuildingSiteList,
                initial: [fetchBuildingSites],
            },
            {
                path: '/sites/add',
                exact: true,
                name: 'add-building-site',
                component: AddBuilding,
            },
            {
                path: '/sites/:siteId',
                exact: true,
                name: 'building-site-detail',
                component: BuildingSiteDetails,
                initial: [getBuildingSite, fetchBuildingSiteCheckups],
            },
            {
                path: '/sites/:siteId/checkups',
                exact: true,
                name: 'building-site-checkup-list',
                component: BuildingSiteCheckupList,
                initial: [getBuildingSite, fetchBuildingSiteCheckups],
            },
            {
                path: '/sites/:siteId/:checkupId?/overview/:slide?',
                exact: true,
                name: 'building-site-overview',
                component: TOMOverview,
                initial: [getBuildingSite, fetchBuildingSiteReport],
            },
            {
                path: '/sites/:siteId/subcategories-report/:startDate?',
                exact: true,
                name: 'building-site-subcategories-report',
                component: SubcategoriesReport,
                initial: [getBuildingSite, fetchBuildingSiteReport, fetchBuildingSiteSubcategoriesReport],
            },
            {
                path: '/sites/:siteId/remarks/:filter',
                exact: true,
                name: 'building-site-remark-list',
                component: RemarkList,
                initial: fetchRemarks,
            },
            {
                path: '/sites/:siteId/remarks',
                exact: true,
                name: 'building-site-remarks',
                component: Remarks,
                initial: fetchRemarks,
            },
            {
                path: '/checkups/:siteId/:checkupId?',
                exact: true,
                name: 'checkup-detail',
                component: CheckupDetails,
                initial: [getCheckup, getBuildingSite, fetchObservers],
            },
            {
                path: '/checkups/:siteId/score/:checkupId',
                exact: true,
                name: 'checkup-scoring',
                component: CheckupScoring,
                initial: [getBuildingSite, getCheckup, matchObj => fetchRemarkCategories(matchObj, true)],
            },
            {
                path: '/checkups/:siteId/finalize/:checkupId',
                exact: true,
                name: 'checkup-finalize',
                component: FinalizeCheckup,
                initial: [getBuildingSite, getCheckup],
            },
            {
                path: '/remarks/:siteId/:checkupId/:remarkId',
                exact: true,
                name: 'remark-details',
                component: RemarkDetails,
                initial: [getBuildingSite, getCheckup, getRemark, fetchAssignees],
            },
            {
                path: '/previous-remarks/:siteId/:checkupId/:remarkId',
                exact: true,
                name: 'previous-remark-details',
                component: RemarkDetails,
                initial: [getBuildingSite, getCheckup, getRemark, fetchAssignees],
            },
            {
                path: '/checkup-remarks-by-category/:siteId/:checkupId/:categorySlug',
                exact: true,
                name: 'checkup-remarks-by-category',
                component: CheckupRemarksByCategory,
                initial: [getBuildingSite, getCheckup, fetchAssignees],
            },
            {
                path: '/remark-display/:remarkId',
                exact: true,
                name: 'remark-display',
                component: RemarkDisplay,
                initial: getRemark,
            },
            {
                path: '*',
                component: PageNotFound,
            },
        ],
    },
];

const urlMapCache = {};

export const rebuildUrlCache = (routeData = routes, namespace = null) => {
    routeData.forEach((route) => {
        const baseName = !namespace ? '' : `${namespace}:`;

        if (route.path && route.name) {
            const base = route.path.split('/');

            urlMapCache[`${baseName}${route.name}`] = {
                pattern: route.path,
                resolve: (kwargs) => {
                    const parameters = kwargs || {};
                    const urlFragments = base.map((fragment) => {
                        const result = /:([^/?]+)(\??)/g.exec(fragment);
                        if (result) {
                            const parameterName = result[1];
                            const optional = result[2];

                            if (parameters[parameterName] === undefined && !optional) {
                                throw Error('Parametrized url missing parameters');
                            }

                            return parameters[parameterName];
                        }

                        return fragment;
                    }).filter(fragment => !!fragment).join('/');

                    return `/${urlFragments}`;
                },
            };
        }

        if (route.routes) {
            rebuildUrlCache(route.routes, `${baseName}${route.name || ''}`);
        }
    });
};

rebuildUrlCache();

export const urlResolve = (name, kwargs) => {
    if (!Object.keys(urlMapCache)) {
        rebuildUrlCache();
    }

    if (!urlMapCache[name]) {
        throw Error(`Unknown url name : ${name}`);
    }

    return urlMapCache[name].resolve(kwargs);
};

export default routes;
