import {call, put} from 'redux-saga/effects';

import api from 'utils/api';
import fizzlesWhenOffline from "utils/fizzlesWhenOffline";
import {handleApiFetch} from 'sagas/helpers/callApi';
import {addAssignees} from "ducks/referred";

function* fetchAssignees() {
    const {result: response, error} = yield call(handleApiFetch, api.apiAssigneesList);

    if (response) {
        yield put(addAssignees(response));
    }

    if (error) {
        return error;
    }

    return null;
}

export default fizzlesWhenOffline(fetchAssignees);
