import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {Row, Col} from 'reactstrap';

import SignupForm from 'forms/Signup';
import withView from 'decorators/withView';
import {signup} from 'sagas/user/signupSaga';
import {gettext} from 'utils/i18n';

import {RouterLocationShape} from 'utils/types';


const Signup = ({location, isAuthenticated, onSignup}) => {
    const {from} = location.state || {from: {pathname: '/'}};

    if (isAuthenticated) {
        return (
            <Redirect to={from} />
        );
    }


    return (
        <div className='page-container view__contents page--login'>
            <Row>
                <Col md={4} className='ml-auto mr-auto'>
                    <SignupForm onSignup={onSignup} />
                </Col>
            </Row>
        </div>
    );
};

Signup.propTypes = {
    onSignup: PropTypes.func.isRequired,
    location: RouterLocationShape.isRequired,
    isAuthenticated: PropTypes.bool,
};

Signup.defaultProps = {
    isAuthenticated: false,
};


const mapDispatchToProps = dispatch => ({
    onSignup: (...args) => dispatch(signup(...args)),
});


const SignupConnector = connect(
    null,
    mapDispatchToProps,
)(Signup);

const SignupAsView = withView(gettext('Signup'))(SignupConnector);

export default SignupAsView;
