import Raven from 'raven-js';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Icon} from 'react-fa';
import {Link} from 'react-router-dom';

import {interpolate, gettext} from 'utils/i18n';
import {bytesToSize, parseNativeFile, nativeFSSupported} from 'utils/nfs';


const MAX_FILE_SIZE = 7 * 1024 * 1024; // 7MB
const MAX_FILES = 5;

const handleFile = (file, resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
        resolve({
            type: 'datauri',
            name: file.name,
            data: reader.result,
            size: file.size,
        });
    };

    reader.readAsDataURL(file);
};


class ImageWidget extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        imageData: PropTypes.string,
        capture: PropTypes.string,
        registerNfsFile: PropTypes.func,
        isStatic: PropTypes.bool,

        onError: PropTypes.func,

        totalCount: PropTypes.number,
    };

    static defaultProps = {
        imageData: null,
        onChange: null,
        onDelete: null,
        capture: null,
        registerNfsFile: null,
        isStatic: false,
        onError: null,

        totalCount: 0,
    };

    state = {
        keys: [],
    };

    onChange = (evt) => {
        const supNfs = !!nativeFSSupported();

        if (this.props.onChange) {
            const promises = [...evt.target.files].map(file => new Promise((resolve, reject) => {
                const key = new Date().getTime();

                const wrappedResolve = (data) => {
                    this.finishUpload(key);
                    resolve(data);
                };

                this.setState({keys: [...this.state.keys, key]}, () => {
                    // Early error for file size warnings
                    if (file.size > MAX_FILE_SIZE) {
                        const err = new Error('File too big');
                        err.name = 'CustomFileTooBig';
                        return reject(err);
                    }

                    if (supNfs) {
                        return parseNativeFile(file, wrappedResolve, reject);
                    } else {
                        return handleFile(file, wrappedResolve, reject);
                    }
                });
            }));

            Promise.all(promises)
                .then((convertedFiles) => {
                    convertedFiles.forEach((convertedFile) => {
                        if (convertedFile.type === 'nfs' && this.props.registerNfsFile) {
                            this.props.registerNfsFile(convertedFile.url);
                        }

                        this.props.onChange(convertedFile);
                    });
                }, (e) => {
                    if (DEV_MODE) {
                        console.error('ImageWidget: Failed to load the selected file(s)', e);

                        if (!this.props.onError) {
                            console.warn('ImageWidget: no onError available, however an error occured and will be ' +
                                         'unhandled. Please add onError to ensure all errors are properly handled.');
                        }
                    } else {
                        Raven.captureException(e);
                    }

                    if (this.props.onError) {
                        let msg = gettext('Failed to load the selected image(s)');

                        if (e.name === 'QuotaExceededError') {
                            msg = gettext('Failed to load the selected image(s): Quota exceeded and failed to ' +
                                          'request more');
                        } else if (e.name === 'CustomFileTooBig') {
                            msg = interpolate(
                                gettext('Failed to load the selected image(s): File exceeds the maximum size limit ' +
                                        '(%(max)s).'),
                                {
                                    max: bytesToSize(MAX_FILE_SIZE),
                                },
                                true,
                            );
                        }

                        this.props.onError(msg);
                    }
                });
        }
    };

    onDelete = (evt) => {
        evt.preventDefault();
        this.props.onDelete();
    };

    finishUpload = key => this.setState({keys: this.state.keys.filter(k => k === key)}, () => {
        if (this.state.keys.length === 0) {
            this.domInput.value = '';
        }
    });

    render() {
        const {id, name, imageData, isStatic, totalCount} = this.props;
        const style = {};
        let preview;

        if (imageData) {
            style.backgroundImage = `url(${imageData})`;
            preview = (
                <Link to={imageData} target="_blank" className="photo-preview" style={style}>
                    {this.props.onDelete && !isStatic ? (
                        <span className="delete-button" onClick={e => this.onDelete(e)}>
                            <Icon name="times" />
                        </span>
                    ) : null}
                </Link>
            );
        } else {
            preview = (
                <span className="info-camera-placeholder">
                    <div className="camera-icon" />
                </span>
            );
        }

        const belowLimit = totalCount < MAX_FILES;

        return (
            <div className="photo-input">
                {preview}
                {!imageData && !isStatic && belowLimit ? (
                    <input
                        type="file"
                        id={id}
                        name={name}
                        accept="image/*"
                        ref={(ref) => { this.domInput = ref; }}
                        onChange={this.onChange}
                        capture={this.props.capture}
                    />
                ) : null}
            </div>
        );
    }
}


export default ImageWidget;
