import React from 'react';

import {Link} from 'react-router-dom';

import {gettext} from 'utils/i18n';
import withView from "../decorators/withView";


const Dashboard = () => (
    <div className='view__contents view__logo page--dashboard'>
        <div className='logo__container' />
        <div className='container'>
            <Link className='btn btn-success btn-block btn-bold' to='/sites/add'>
                <i className='lnr lnr-file-add' /> {gettext('New site')}
            </Link>
            <Link className='btn btn-info btn-block btn-bold' to='/sites'>
                <i className='lnr lnr-list' /> {gettext('Building sites')}
            </Link>
        </div>
    </div>
);

export default withView(gettext('Overview'), true, {navOptions: {navbarLeft: null}})(Dashboard);
