import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlacesAutocomplete from 'react-places-autocomplete';


class Input extends Component {
    static defaultProps = {
        className: null,
        error: null,
        id: '',
        label: null,
        type: 'text',
        placeholder: '',
        readOnly: false,
    };

    static propTypes = {
        className: PropTypes.string,
        error: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    state = {
        country: 'EE',
    };

    componentDidMount() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({location: {lat: position.coords.latitude, lng: position.coords.longitude}},
                    (results, status) => {
                        if (status === window.google.maps.GeocoderStatus.OK) {
                            for (const r of results[0].address_components) { // eslint-disable-line no-restricted-syntax
                                if (r.types[0] === "country") {
                                    this.setState({country: r.short_name});
                                }
                            }
                        }
                    });
            });
        }
    }

    render() {
        const {className, error, id, label, name, type, placeholder, readOnly, value, onChange} = this.props;

        return (
            <div className={classNames('form-group', {'has-error': error})}>
                {label ? (
                    <label
                        htmlFor={id}
                        className='label'
                    >{label}</label>
                ) : null}
                <PlacesAutocomplete
                    onChange={onChange}
                    value={value}
                    searchOptions={{componentRestrictions: {country: this.state.country}}}
                >
                    {({getInputProps, suggestions, getSuggestionItemProps}) => (
                        <div>
                            <input
                                {...getInputProps({
                                    placeholder,
                                    id,
                                    name,
                                    type,
                                    readOnly,
                                    className: classNames('form-control', className),
                                })}
                            />
                            {suggestions.length ? <div className="PlacesAutocomplete__autocomplete-container">
                                {suggestions.map(suggestion => (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className: classNames('autocompleteItem', {active: suggestion.active}),
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>),
                                )}
                            </div> : null}
                        </div>
                    )}
                </PlacesAutocomplete>
                {error ? (
                    <span id={`error-${id}`} className='help-block'>{error}</span>
                ) : null}
            </div>
        );
    }
}

export default Input;
