import React from 'react';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';

import List, {ListItem} from "components/List";
import StartNewCheckup from "components/StartNewCheckup";

import appView from 'utils/appView';
import {gettext} from 'utils/i18n';
import {SitePropType} from 'utils/types';

import {STATE_KEY} from "ducks/buildingSites";

import {urlResolve} from "configuration/routes";


const BuildingSiteDetails = ({site}) => (
    <div className="view__contents">
        {site ?
            (<div>
                <div className="view__contents__header">
                    <h1 className="view__contents__title">{site.name}</h1>
                    <h2 className="view__contents__subtitle">{site.address}</h2>
                </div>
                <List header={gettext("Select action")} icons>
                    {/* urlResolve() can't handle optional kwargs */}
                    <StartNewCheckup siteId={site.id} />
                    <ListItem
                        icon="list"
                        to={site.id ? urlResolve('building-site-checkup-list', {siteId: site.id}) : ''}
                    >
                        {gettext('Previous checkups')}
                    </ListItem>
                    <ListItem
                        icon="list"
                        to={site.id ? urlResolve('building-site-remarks', {siteId: site.id}) : ''}
                    >
                        {gettext('Previous remarks')}
                    </ListItem>
                    <ListItem icon="tachometer" color="#3498db" to={`/sites/${site.id}/overview/2`}>
                        {gettext('TOM overview')}
                    </ListItem>
                    <ListItem icon="exclamation" color="#f1c40f" className="view__list__item--coming">
                        <span>{gettext('Incidents')}</span>
                        <span className="badge badge-info">{gettext('Coming soon')}</span>
                    </ListItem>
                </List>
            </div>
            ) : (<Redirect to="/404" />)
        }
    </div>
);

BuildingSiteDetails.propTypes = {
    site: SitePropType,
};

BuildingSiteDetails.defaultProps = {
    site: null,
};

const BuildingSiteDetailView = appView()(BuildingSiteDetails);

const mapStateToProps = (state, ownProps) => {
    const site = state[STATE_KEY].data[ownProps.match.params.siteId];
    if (!site) {
        return {site: null};
    }

    return {
        site,
        title: site.name,
    };
};

const BuildingSiteDetailsConnector = connect(
    mapStateToProps,
)(BuildingSiteDetailView);


export default withRouter(BuildingSiteDetailsConnector);
