import {call, put, select} from 'redux-saga/effects';

import api from 'utils/api';
import fizzlesWhenOffline from "utils/fizzlesWhenOffline";
import {handleApiFetch} from 'sagas/helpers/callApi';

import {addRemarkCategories} from "ducks/buildingSites";

function* fetchRemarkCategories(matchObj, force) {
    if (force !== true) {
        // Do nothing if already fetched.
        const getCategories = state => state.buildingSites.remarkCategories;
        const categories = yield select(getCategories);
        if (categories && Object.keys(categories).length) {
            return null;
        }
    }

    const {result: response, error} = yield call(handleApiFetch, api.remarkcategoryList);

    if (response) {
        yield put(addRemarkCategories(response));
    }

    if (error) {
        return error;
    }

    return null;
}

export default fizzlesWhenOffline(fetchRemarkCategories);
