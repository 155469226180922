import {connect} from 'react-redux';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import classNames from 'classnames';

import {gettext} from "utils/i18n";
import {ObserverPropType} from "utils/types";
import validateEmail from "utils/validateEmail";
import Select from 'react-select';
import {selectors as referredSelectors} from 'ducks/referred';


const mapStateToProps = (state, ownProps) => (
    {observers: referredSelectors.observers(state).filter(o => o.referrer === ownProps.referrer)}
);


class AddObserverForm extends Component {
    state = {
        values: {
            name: '',
            email: '',
        },
        errors: {
            name: null,
            email: null,
        },
        observers: this.props.observers,
    };

    onChangeField = (field, value) => {
        this.setState({
            values: {
                name: value ? value.name : field === 'name' ? '' : this.state.values.name,
                email: value ? value.email : field === 'email' ? '' : this.state.values.email,
            },
        });
    };

    onAddObserver = () => {
        const {name, email} = this.state.values;

        const errors = {...this.state.errors, name: null, email: null};
        if (!name) {
            errors.name = gettext('Field is required');
        }

        if (!email) {
            errors.email = gettext('Field is required');
        }

        if (errors.name || errors.email) {
            this.setState({errors});
            return;
        }

        this.clearErrors();

        const observer = {id: uuid.v4(), name, email};
        this.setState({
            values: {
                name: '',
                email: '',
            },
        });

        this.props.onAddObserver(observer);
    };

    clearErrors = () => this.setState({
        errors: {
            name: null,
            email: null,
        },
    });

    render() {
        const {values, errors} = this.state;
        const observers = this.state.observers;
        return (
            <div className="add-member-form">
                <div className={classNames('form-group', {"has-error": errors.name})}>
                    <span className="label">{gettext('Name')}</span>
                    <Select.Creatable
                        options={observers.filter(o => o.name).map(o =>
                            ({value: o.name, label: o.name, email: o.email, name: o.name}))}
                        onChange={value => this.onChangeField('name', value)}
                        onNewOptionClick={(o) => {
                            const lastIdx = observers.length - 1;
                            const lastObs = observers[lastIdx];
                            if (lastIdx === -1 || (lastObs && lastObs.id)) {
                                observers.push({email: values.email, name: o.label});
                            } else {
                                observers[lastIdx].name = o.label;
                            }
                            this.setState({observers});
                        }}
                        placeholder={gettext('Enter name')}
                        value={values.name}
                        name="name"
                    />
                    <span className="help-block">{errors.name}</span>
                </div>
                <div className={classNames('form-group', {"has-error": errors.email})}>
                    <span className="label">{gettext('Email')}</span>
                    <Select.Creatable
                        options={observers.filter(o => o.email).map(o =>
                            ({value: o.email, label: o.email, email: o.email, name: o.name}))}
                        onChange={value => this.onChangeField('email', value)}
                        onNewOptionClick={(o) => {
                            const lastIdx = observers.length - 1;
                            const lastObs = observers[lastIdx];
                            if (lastIdx === -1 || (lastObs && lastObs.id)) {
                                observers.push({email: o.label, name: values.name});
                            } else {
                                observers[lastIdx].email = o.label;
                            }
                            this.setState({observers});
                        }}
                        isValidNewOption={o => validateEmail(o.label)}
                        placeholder={gettext('Enter email')}
                        value={values.email}
                        name="email"
                    />
                    <span className="help-block">{errors.email}</span>
                </div>
                <div className="btn btn-primary btn-block btn-bold" onClick={this.onAddObserver}>
                    {this.props.saveBtnTxt}
                </div>
                {this.props.onClose ? (
                    <div className="btn btn-default btn-block btn-bold" onClick={this.props.onClose}>
                        {gettext('Cancel')}
                    </div>
                ) : null
                }
            </div>
        );
    }
}

AddObserverForm.propTypes = {
    onAddObserver: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    saveBtnTxt: PropTypes.string.isRequired,
    referrer: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    observers: PropTypes.arrayOf(ObserverPropType),
};

AddObserverForm.defaultProps = {
    onClose: null,
    observers: [],
};

export default connect(mapStateToProps)(AddObserverForm);
