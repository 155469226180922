export const LOADING = 'loading';


export default function (state = true, action) {
    switch (action.type) {
        case 'persist/REHYDRATE':
            return false;
        case LOADING:
            return action.is_loading;
        default:
            return state;
    }
}

export const startLoading = () => ({type: LOADING, is_loading: true});
export const finishLoading = () => ({type: LOADING, is_loading: false});

export const selectors = {
    isLoading: state => state[LOADING],
};
