export const STATE_KEY = 'offlineVersion';
export const SET_OFFLINE_VERSION = `${STATE_KEY}/SET_VERSION`;

export default function reducer(state = 0.1, action) {
    switch (action.type) {
        case SET_OFFLINE_VERSION:
            return action.version;

        default:
            return state;
    }
}

export const setOfflineVersion = version => ({type: SET_OFFLINE_VERSION, version});
