import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {SortingFieldPropType} from 'utils/types';

const getOptionValue = ({field, dir}) => (`${field}-${dir}`);


const Option = ({label, option}) => (
    <option value={getOptionValue(option)}>
        {label}
    </option>
);

Option.propTypes = {
    option: SortingFieldPropType.isRequired,
    label: PropTypes.string.isRequired,
};

class SortSelect extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            option: SortingFieldPropType.isRequired,
        })).isRequired,
        selected: SortingFieldPropType.isRequired,
        onChange: PropTypes.func.isRequired,
        iconName: PropTypes.string,
    };

    static defaultProps = {
        iconName: "fa-chevron-down",
    };

    componentDidMount() {
        const {selected} = this.props;
        this.props.onChange(selected.field, selected.dir);
    }

    onChange = evt => this.props.onChange(...evt.target.value.split('-'));

    render() {
        const {options, selected, iconName} = this.props;
        return (
            <div className='etom-select'>
                <div className={classNames('select-arrow', 'fa', iconName)} />
                <select value={getOptionValue(selected)} onChange={this.onChange}>
                    {options.map(data => <Option key={getOptionValue(data.option)} {...data} />)}
                </select>
            </div>
        );
    }
}


export default SortSelect;
