import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({id, name, label, onChange, required, checked, error}) => (
    <div className={classNames('form-group', 'form-group-checkbox', {'has-error': error})}>
        <input
            id={id}
            name={name}
            required={required}
            onChange={onChange}
            checked={checked}
            className={`form-control form-control-checkbox ${error ? 'error' : ''}`}
            type='checkbox'
        />
        <label htmlFor={id || name} className='control-label'>
            {label}
        </label>
        {error ? (
            <span id={`error-${id}`} className='help-block'>{error}</span>
        ) : null}
    </div>
);

Checkbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    checked: PropTypes.bool,
    error: PropTypes.string,
};

Checkbox.defaultProps = {
    id: null,
    error: null,
    checked: false,
    required: false,
};

export default Checkbox;
