import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {gettext} from 'utils/i18n';
import {urlResolve} from "configuration/routes";
import {SitePropType, SortingFieldPropType} from 'utils/types';

import {STATE_KEY, sortBy, SORT_ASC, SORT_DESC} from 'ducks/buildingSites';

import SortSelect from 'components/SortSelect';
import List, {ListItem} from 'components/List';
import withView from "decorators/withView";

const BuildingSiteList = ({sortedBy, onChange, sites}) => (
    <div className="view__contents page--objects-list">
        <p className="text-left margin-md-top">{gettext('Selection choices')}</p>
        <div>
            <SortSelect
                onChange={onChange}
                options={[
                    {label: gettext('By name ascending'), option: {field: 'name', dir: SORT_ASC}},
                    {label: gettext('By name descending'), option: {field: 'name', dir: SORT_DESC}},
                ]}
                selected={sortedBy}
            />
        </div>
        <List header={gettext('Select location')}>
            {sites.map(site => (
                site && site.id ?
                    <ListItem key={site.id} to={urlResolve('building-site-detail', {siteId: site.id})}>
                        <p className="view__list__item__title">{site.name}</p>
                        <span className="view__list__item__subtitle">{site.address}</span>
                    </ListItem>
                    :
                    null
            ))}
        </List>
    </div>
);

BuildingSiteList.propTypes = {
    sites: PropTypes.arrayOf(SitePropType),
    sortedBy: SortingFieldPropType.isRequired,
    onChange: PropTypes.func.isRequired,
};

BuildingSiteList.defaultProps = {
    sites: [],
};

const mapStateToProps = state => ({
    sites: state[STATE_KEY].sorted.map(key => state[STATE_KEY].data[key]),
    sortedBy: state[STATE_KEY].sortedBy,
});

const mapDispatchToProps = dispatch => ({
    onChange: (field, dir) => dispatch(sortBy(field, dir)),
});

const BuildingSiteListConnector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BuildingSiteList);


export default withView(gettext('Select site'), true)(BuildingSiteListConnector);
