import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RemarkEdit from 'components/RemarkEdit';
import {selectors as referredSelectors} from 'ducks/referred';
import {gettext} from "utils/i18n";
import get from 'lodash.get';

import Modal from 'tg-modal';
import {STATE_KEY} from "ducks/buildingSites";
import {RemarkPropType, CategoryPropType, AssigneePropType} from 'utils/types';

import ImageWidget from 'components/ImageWidget';
import withView from "decorators/withView";
import {connect} from 'react-redux';
import List, {ListItem} from "components/List";
import differenceBy from 'lodash.differenceby';

import moment from 'moment';


const mapStateToProps = (state, ownProps) => {
    const site = state[STATE_KEY].data[ownProps.match.params.siteId];
    if (!site) {
        return {};
    }

    const checkUpId = ownProps.match.params.checkupId;
    const checkUp = site && site.checkUps ? site.checkUps[checkUpId] : null;
    if (!checkUp) {
        return {};
    }

    const categories = state[STATE_KEY].remarkCategories || [];
    const category = categories[ownProps.match.params.categorySlug];

    const remarks = get(state[STATE_KEY],
        ['remarks', checkUp.id, category.id, DJ_CONST.REMARK_STATUSES.STATUS_NEGATIVE], [],
    ).filter(rmk => !rmk.isTemporary);

    const assignees = referredSelectors.assignees(state).filter(a => a.site === ownProps.match.params.siteId);

    return {
        remarks,
        category,
        assignees,
    };
};

class CheckupRemarksByCategoryView extends Component {
    static propTypes = {
        remarks: PropTypes.arrayOf(RemarkPropType),
        category: CategoryPropType,
        activeLanguage: PropTypes.string.isRequired,
        remark: RemarkPropType,
        match: PropTypes.shape({
            params: PropTypes.shape({
                siteId: PropTypes.string.isRequired,
                checkupId: PropTypes.string.isRequired,
                categorySlug: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        canGoBack: PropTypes.func.isRequired,
        assignees: PropTypes.arrayOf(AssigneePropType).isRequired,
        history: PropTypes.shape({
            replace: PropTypes.func.isRequired,
        }).isRequired,
        goBackWarning: PropTypes.bool,
        dismissGoBackWarning: PropTypes.func.isRequired,
    };

    static defaultProps = {
        remarks: [],
        category: null,
        remark: null,
        assignees: [],
        goBackWarning: false,
    };

    constructor(props) {
        super(props);

        this.remarkRefs = {};
    }

    state = {
        remarkChanged: false,
        isModalOpen: false,
        currentRemark: null,
    };

    componentDidUpdate() {
        if (this.props.remark) {
            const element = document.getElementById(`edit-remark-opened`);
            element.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }

    editRemark = (rmk) => {
        if (this.state.remarkChanged) {
            this.setState({isModalOpen: true});
        } else {
            this.setState({currentRemark: rmk});
        }
    };

    checkChanges = (newRmk) => {
        let email = '';
        let name = '';
        const assignee = this.state.currentRemark.assignee;
        if (assignee) {
            email = this.state.currentRemark.email;
            name = this.state.currentRemark.email;
        }
        const prevImages = this.state.currentRemark.images;
        const nextImages = newRmk.images;
        if (this.state.currentRemark.comment !== newRmk.comment ||
            email !== newRmk.email ||
            name !== newRmk.name ||
            this.state.currentRemark.deadline !== newRmk.deadline ||
            differenceBy(prevImages, nextImages, 'id').length ||
            differenceBy(nextImages, prevImages, 'id').length
        ) {
            this.setState({remarkChanged: true});
            this.props.canGoBack(false);
        }
    };

    saveChanges(e) {
        e.stopPropagation();
        this.setState({currentRemark: null, remarkChanged: false, isModalOpen: false}, () => {
            this.props.canGoBack(true);
            this.props.dismissGoBackWarning();
        });
        this.editRemarkForm.onSaveRemark(e);
    }

    rejectChanges(e) {
        e.stopPropagation();
        this.setState({currentRemark: null, remarkChanged: false, isModalOpen: false}, () => {
            this.props.canGoBack(true);
            this.props.dismissGoBackWarning();
        });
    }

    renderRemark = (rmk) => {
        const subcategory = this.props.category ?
            this.props.category.subcategories.find(cat => cat.id === rmk.subcategory) : null;
        const opened = this.state.currentRemark && this.state.currentRemark.id === rmk.id;
        return (
            <ListItem
                key={rmk.id}
                {...(opened ? {idName: 'edit-remark-opened'} : {})}
            >
                <span className='edit-remark-link' onClick={opened ? null : () => this.editRemark(rmk)}>
                    {subcategory ? subcategory[`label_${this.props.activeLanguage}`] : null}
                </span>
                {rmk.images.length ? <div className="images--container--wrp">
                    <div className="images--container">
                        {rmk.images.map(image => (
                            <ImageWidget
                                key={image.id}
                                id={`image-${image.id}`}
                                name={`image-${image.id}`}
                                imageData={image.image}
                                isStatic
                            />
                        ))}
                    </div>
                </div> : null}
                {opened ? <RemarkEdit
                    onRef={(ref) => { this.editRemarkForm = ref; }}
                    remark={rmk}
                    activeLanguage={this.props.activeLanguage}
                    assignees={this.props.assignees}
                    onSaving={() => this.setState({currentRemark: null, remarkChanged: false}, () => {
                        this.props.dismissGoBackWarning();
                        this.props.canGoBack(true);
                    })}
                    checkChanges={this.checkChanges}
                /> : null }
            </ListItem>
        );
    };

    render() {
        if (!this.props.category) {
            return (<span />);
        }

        return (
            <div className="view__contents page--negative-remarks">
                <div className="view__contents__header">
                    <h1 className="view__contents__title">
                        {this.props.category[`label_${this.props.activeLanguage}`]}
                    </h1>
                </div>
                <List>
                    {
                        this.props.remarks.sort((a, b) => {
                            const aCreated = moment(a.created);
                            const bCreated = moment(b.created);
                            if (bCreated.isAfter(aCreated)) {
                                return 1;
                            }
                            if (bCreated.isBefore(aCreated)) {
                                return -1;
                            }
                            return 0;
                        }).map(r => (this.renderRemark(r)))
                    }
                </List>
                <Modal
                    autoWrap
                    title={gettext('Remark changes are not saved.')}
                    isOpen={this.state.isModalOpen || this.props.goBackWarning}
                    onCancel={() => this.setState({isModalOpen: false}, () => {
                        this.props.dismissGoBackWarning();
                    })}
                >
                    <div>
                        {gettext('Do you want to save changes?')}
                    </div>
                    <div className="btn btn-success btn-block btn-round btn-bold" onClick={e => this.saveChanges(e)}>
                        {gettext('Yes')}
                    </div>
                    <div className="btn btn-danger btn-block btn-round btn-bold" onClick={e => this.rejectChanges(e)}>
                        {gettext('No')}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withView(gettext('Negative checkup remarks by category'), true)(connect(mapStateToProps)(CheckupRemarksByCategoryView));

