import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';

import withView from 'decorators/withView';
import {gettext, ngettext, interpolate} from 'utils/i18n';
import {RemarkPropType} from 'utils/types';
import filterRemarks from "utils/remarks";

import {STATE_KEY, SORT_ASC, SORT_DESC} from "ducks/buildingSites";
import {ListItem} from "components/List";
import SortSelect from "components/SortSelect";
import Filter from "components/Filter";
import {urlResolve} from "configuration/routes";


const mapStateToProps = (state, ownProps) => {
    const categories = state[STATE_KEY].remarkCategories;
    const flattenedRemarks = [];

    // Flatten remarks
    Object.entries(state[STATE_KEY].remarks).forEach(([, types]) => {
        Object.entries(types).forEach(([, statuses]) => {
            Object.entries(statuses).forEach(([, remarks]) => {
                flattenedRemarks.push(...remarks);
            });
        });
    });

    const remarks = filterRemarks(flattenedRemarks, ownProps.match.params.filter);

    const assignees = remarks.reduce((acc, r) => Object.assign(acc, {[r.assignee.id]: r.assignee}), {});

    return {
        categories,
        assignees,
        remarks,
        siteId: ownProps.match.params.siteId,
    };
};

class RemarkList extends Component {
    static propTypes = {
        remarks: PropTypes.arrayOf(RemarkPropType),
        siteId: PropTypes.string,
        categories: PropTypes.shape().isRequired,
        assignees: PropTypes.shape().isRequired,
        activeLanguage: PropTypes.string.isRequired,
    };

    static defaultProps = {
        remarks: [],
        siteId: "",
    };

    constructor(props) {
        super(props);

        moment.locale('et');

        this.sortingKeys = [
            {option: {field: 'deadline', dir: SORT_DESC}, label: gettext('By deadline descending')},
            {option: {field: 'deadline', dir: SORT_ASC}, label: gettext('By deadline ascending')},
        ];

        this.state = {
            sortBy: {field: 'deadline', dir: SORT_DESC},
            type: Object.keys(props.categories),
            assignees: Object.keys(props.assignees),
        };
    }

    getRemarkDeadlineClass = (remark) => {
        const remarkMoment = moment(remark.deadline, 'DD.MM.YYYY');
        // We don't need to paint remarks that are resolved
        if (!remark.is_resolved && remarkMoment.isBefore(moment())) {
            return 'overdue';
        } else if (!remark.is_resolved && remarkMoment.isBefore(moment().add(1, 'days'))) {
            return 'due-today';
        }

        return '';
    };

    filterByType = remark => this.state.type.includes(
        Object.values(this.props.categories).find(c => c.id === remark.category).slug,
    );

    filterByAssignee = remark => this.state.assignees.includes(remark.assignee.id);

    sortRemarks = (a, b) => {
        const {field, dir} = this.state.sortBy;
        let order = 1;
        if (dir === 'desc') {
            order = -1;
        }
        if (field === 'deadline') { // Special handling for sorting by date
            if (moment(a[field], 'DD.MM.YYYY').isBefore(moment(b[field], 'DD.MM.YYYY'))) {
                return -1 * order;
            } else if (moment(a[field], 'DD.MM.YYYY').isAfter(moment(b[field], 'DD.MM.YYYY'))) {
                return 1 * order;
            } else {
                return 0;
            }
        }
        return 0;
    };

    filter = (field, value) => {
        const valueArray = Array.from(value);

        if (field === 'type' && valueArray[0].value === "all-categories") { // Special case for 'All Categories'
            this.setState({[field]: Object.keys(this.props.categories)});
        } else if (field === 'assignees' && valueArray[0].value === "all-assignees") {
            this.setState({[field]: Object.keys(this.props.assignees)});
        } else {
            this.setState({[field]: valueArray.map(option => option.value)});
        }
    };

    changeSortKey = (field, dir) => {
        this.setState({
            sortBy: {field, dir},
        });
    };

    renderRemark = (remark) => {
        const {siteId} = this.props;
        const assignee = remark.assignee ? remark.assignee.name : gettext('No assigned person');
        const deadline = remark.deadline || gettext('No deadline');
        const categoryId = remark.category;
        const categoryLabel = Object.values(this.props.categories)
            .find(c => c.id === categoryId)[`label_${this.props.activeLanguage}`];
        return (
            <ListItem
                key={remark.id}
                to={urlResolve('previous-remark-details', {siteId, checkupId: remark.checkup, remarkId: remark.id})}
                className={this.getRemarkDeadlineClass(remark)}
            >
                <p className="view__list__item__title">{categoryLabel}</p>
                <span className="view__list__item__subtitle">
                    {`${gettext('Responsible person:')}`} {assignee} &middot; {deadline}
                </span>
            </ListItem>
        );
    };

    renderRemarks = () => {
        const filteredRemarks = this.props.remarks
            .filter(r => r.is_from_finalized_checkup) // Hide unfinalized remarks
            .filter(r => r.status !== DJ_CONST.REMARK_STATUSES.STATUS_POSITIVE) // Hide Positive remarks
            .filter(r => this.filterByType(r))
            .filter(r => this.filterByAssignee(r))
            .sort(this.sortRemarks);

        if (filteredRemarks.length) {
            return (
                filteredRemarks.map(remark => this.renderRemark(remark))
            );
        } else {
            return (
                <div>{gettext('No remarks to show with current filters')}</div>
            );
        }
    };

    render() {
        const {remarks} = this.props;
        const {sortBy, type, assignees} = this.state;

        const category = type.length === Object.keys(this.props.categories).length ?
            gettext("all") :
            this.props.categories[type][`label_${this.props.activeLanguage}`];

        const transDict = {
            category,
        };

        const filterTexts = ngettext('Displaying remarks from category %(category)s.',
            'Displaying remarks from %(category)s categories.', category === gettext("all") ? 2 : 1);

        const categoryOptions = Object.values(this.props.categories)
            .map(c => ({slug: c.slug, label: c[`label_${this.props.activeLanguage}`]}));
        categoryOptions.unshift({label: gettext("All categories"), slug: "all-categories"});


        const assigneeOptions = Object.values(this.props.assignees)
            .map(a => ({slug: a.id, label: `${a.name} ${a.email}`}));
        assigneeOptions.unshift({label: gettext("All assignees"), slug: "all-assignees"});

        return (
            <div className="view__contents">
                <div className="view__filters">
                    <Filter
                        name="type"
                        options={categoryOptions}
                        onChange={this.filter}
                        value={type}
                        iconName={'fa-tags'}
                    />
                    <Filter
                        name="assignees"
                        options={assigneeOptions}
                        onChange={this.filter}
                        value={assignees}
                        iconName={'fa-check-circle'}
                    />
                    <SortSelect
                        onChange={this.changeSortKey}
                        options={this.sortingKeys}
                        selected={sortBy}
                        iconName={'fa-calendar-times-o'}
                    />
                </div>
                <div className="margin-md-bottom">
                    {interpolate(filterTexts, transDict, true)}
                </div>
                {Object.keys(remarks).length ? this.renderRemarks() : <div>{gettext('No remarks yet')}</div>}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withView(gettext('Previous remarks'), true)(RemarkList));
