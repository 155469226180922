import Raven from 'raven-js';
import {v4 as uuidV4} from 'uuid';

import {interpolate, gettext} from 'utils/i18n';


// 512MB
const ETOM_QUOTA = 512 * 1024 * 1024; // 512MB
const EXTRA_QUOTA = 100 * 1024 * 1024; // 100MB

let curQuota = 0;

export const nativeFSSupported = () => !!window.nativeFS;

export const bytesToSize = (bytes, decimals = 0) => {
    if (bytes === 0) {
        return gettext('0 Bytes');
    }
    const sizes = [
        gettext('Bytes'),
        gettext('KB'),
        gettext('MB'),
        gettext('GB'),
        gettext('TB'),
        gettext('PB'),
        gettext('EB'),
        gettext('ZB'),
        gettext('YB'),
    ];

    const k = 1024;
    const dm = decimals || 2;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const getNativeFS = () => {
    if (typeof window === 'undefined') {
        throw new Error('NFS can only be used in the client.');
    }

    return window.nativeFS;
};

export const ls = () => new Promise((resolve, reject) => {
    const nfs = getNativeFS();

    if (!nfs) {
        reject(new Error('NFS.ls NFS is not supported'));
        return;
    }

    const dirReader = nfs.root.createReader();
    dirReader.readEntries(resolve, reject);
});

const requestQuota = quota => new Promise((resolve, reject) => {
    navigator.webkitPersistentStorage.requestQuota(quota, (grantedBytes) => {
        if (grantedBytes !== quota) {
            console.error(`Requested ${quota} bytes but got ${grantedBytes}`);
        }

        curQuota = grantedBytes;

        resolve(grantedBytes);
    }, reject);
});

export const errorHandler = (pushErrorMessage, e) => {
    let msg;

    switch (e.name) {
        case 'QuotaExceededError':
            msg = interpolate(
                gettext('Failed to request quota for filesystem. Please allow the application to save files and ' +
                        'check that your disk has at least %(space)s free space. '),
                {
                    space: bytesToSize(ETOM_QUOTA),
                },
                true,
            );
            break;
        default:
            console.error('NFS Error: ', e);
            if (!DEV_MODE) {
                Raven.captureException(e);
            }
            msg = gettext('Unknown Error occured when requesting filesystem quota.');
            break;
    }

    pushErrorMessage(msg);
};

// Uses FileSystem API which at this point is for chrome only (and is not on standards track)
//
// https://developer.mozilla.org/en-US/docs/Web/API/FileSystem
// https://www.html5rocks.com/en/tutorials/file/filesystem/
function initNfs(pushErrorMessage) {
    if (!window) {
        throw new Error('NFS should not be included in the server');
    }

    // requestFileSystem is only available in chrome
    window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
    window.resolveLocalFileSystemURL = window.resolveLocalFileSystemURL || window.webkitResolveLocalFileSystemURL;

    // Exposing globally for debugging purposes
    window.nativeFS = null;
    window.nativeFSLS = null;

    if (window.requestFileSystem && navigator.webkitPersistentStorage) {
        requestQuota(ETOM_QUOTA).then((grantedBytes) => {
            if (grantedBytes < ETOM_QUOTA) {
                pushErrorMessage(interpolate(
                    gettext('Requested %(requested)s of space but got %(granted)s. Please allow the application to ' +
                            'save files and check that your disk has at least %(requested)s amount of free space.'),
                    {
                        requested: bytesToSize(ETOM_QUOTA),
                        granted: bytesToSize(grantedBytes),
                    },
                    true,
                ));
            }

            window.requestFileSystem(window.PERSISTENT, grantedBytes, (fs) => {
                window.nativeFS = fs;

                // For debugging
                window.nativeFSLS = () => ls().then(items => console.log('LS', items), e => console.error('LS', e));
            }, (e) => {
                window.nativeFS = false;
                window.nativeFSLS = false;

                errorHandler(pushErrorMessage, e);
            });
        }, (e) => {
            window.nativeFS = false;
            window.nativeFSLS = false;

            errorHandler(pushErrorMessage, e);
        });
    } else {
        window.nativeFS = false;
    }
}

export const getDataForSubmission = (fileData, fieldName) => new Promise((resolve, reject) => {
    if (fileData.type !== 'nfs') {
        reject(new Error(`NFS.getDataForSubmission received invalid data: ${fileData}`));
        return;
    }

    const {image, name} = fileData;
    window.resolveLocalFileSystemURL(image, (fileEntry) => {
        fileEntry.file((handle) => {
            resolve({
                field: fieldName,
                file: handle,
                name,
            });
        }, e => reject(e));
    }, e => reject(e));
});


export const parseNativeFile = (file, resolve, reject, isRetry = false) => {
    const nfs = getNativeFS();
    const fileName = uuidV4();

    // This can occur if we hit quota or disk is full, in that case we should request more quota and retry
    //  (if that fails we reject)
    // All other errors are rejected directly
    const parseErrorHandler = (e) => {
        if (isRetry) {
            reject(e);
            return;
        }

        if (e.name === 'QuotaExceededError') {
            // Request extra quota and try again
            curQuota += EXTRA_QUOTA;
            requestQuota(curQuota).then((grantedBytes) => {
                window.requestFileSystem(window.PERSISTENT, grantedBytes, (fs) => {
                    // update the global fs object
                    window.nativeFS = fs;

                    // retry
                    parseNativeFile(file, resolve, reject, true);
                });
            }, reject);
            return;
        }

        reject(e);
    };

    nfs.root.getFile(fileName, {create: true}, (fileEntry) => {
        fileEntry.createWriter((fileWriter) => {
            fileWriter.onwriteend = (e) => { // eslint-disable-line no-param-reassign
                resolve({
                    type: 'nfs', // native fs
                    url: fileEntry.toURL(),
                    name: file.name,
                    size: (e && e.loaded) || file.size,
                });
            };

            fileWriter.onerror = parseErrorHandler; // eslint-disable-line no-param-reassign
            fileWriter.write(file);
        }, parseErrorHandler);
    }, parseErrorHandler);
};

export const getMetadata = fileEntry => new Promise((resolve, reject) => fileEntry.getMetadata(resolve, reject));

export const unlinkFile = fileUrl => new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(fileUrl, (fileEntry) => {
        fileEntry.remove(resolve, reject);
    }, reject);
});

export default initNfs;
