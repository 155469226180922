import moment from 'moment';

export default function filterRemarks(remarks, filter) {
    const filteredRemarks = remarks.filter(
        r => (r.status !== DJ_CONST.REMARK_STATUSES.STATUS_POSITIVE && r.is_from_finalized_checkup && r.assignee &&
            ((r.images && r.images.length) || r.comment)),
    );

    if (filter === 'after-deadline') {
        return filteredRemarks.filter(r => !r.is_resolved &&
            moment(r.deadline, 'DD.MM.YYYY').add(1, 'days').isSameOrBefore(moment().startOf('day')) &&
            !moment(r.deadline, 'DD.MM.YYYY').add(21, 'days').isBefore(moment().startOf('day')),
        );
    }

    if (filter === 'before-deadline') {
        return filteredRemarks.filter(r => !r.is_resolved &&
            moment(r.deadline, 'DD.MM.YYYY').isSameOrAfter(moment().startOf('day')),
        );
    }

    if (filter === 'last') {
        if (filteredRemarks.length) {
            return filteredRemarks.filter(r => r.checkup === filteredRemarks[0].checkup);
        }
    }

    if (filter === 'waiting-confirmation') {
        return filteredRemarks.filter(r => r.is_waiting_confirmation);
    }

    if (filter === 'resolved') {
        return filteredRemarks.filter(r => r.is_resolved);
    }

    if (filter === 'non-resolved-archived') {
        return filteredRemarks.filter(r => !r.is_resolved &&
            moment(r.deadline, 'DD.MM.YYYY').add(21, 'days').isBefore(moment().startOf('day')),
        );
    }

    return filteredRemarks;
}

