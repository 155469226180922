import React, {Component} from 'react';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import get from 'lodash.get';
import moment from 'moment';

import {gettext} from 'utils/i18n';
import {RemarkPropType, AssigneePropType} from 'utils/types';
import validateEmail from "utils/validateEmail";

import {STATE_KEY, requestAddRemark, getRemarkByIdAndCheckup, requestPatchResponse} from "ducks/buildingSites";
import {markNfsFileForDeletion, registerNfsFile} from 'ducks/nfs';
import {selectors as referredSelectors} from 'ducks/referred';

import Input from 'components/inputs/Input';
import TextArea from 'components/TextArea';
import ImageWidget from 'components/ImageWidget';
import withView from "decorators/withView";
import Select from 'react-select';


const mapStateToProps = (state, ownProps) => {
    const {siteId, checkupId, remarkId} = ownProps.match.params;

    const categories = state[STATE_KEY].remarkCategories;
    const site = state[STATE_KEY].data[siteId] || null;
    const checkUp = site && site.checkUps ? site.checkUps[checkupId] : null;
    const remark = getRemarkByIdAndCheckup(state[STATE_KEY].remarks, checkupId, remarkId);
    const categorySlug = remark ? Object.values(categories).find(c => c.id === remark.category).slug : null;
    const category = categorySlug ? categories[categorySlug] : null;

    const subcategories = category ? category.subcategories : [];
    const title = category ? category[`label_${ownProps.activeLanguage}`] : gettext('New remark');
    const assignees = referredSelectors.assignees(state).filter(a => a.site === siteId);
    return {
        remark,
        title,
        subcategories,
        checkUp,
        isCreation: !!(remark && remark.isTemporary), // Are we coming back or creating a new remark?
        assignees,
    };
};

const mapDispatchToProps = dispatch => ({
    onSaveRemark: remarkData => (dispatch(requestAddRemark(remarkData))),
    onPatchResponse: remarkData => dispatch(requestPatchResponse(remarkData)),
    registerNfsFile: imageUrl => dispatch(registerNfsFile(imageUrl)),
    markNfsFileForDeletion: imageUrl => dispatch(markNfsFileForDeletion(imageUrl)),
});

class RemarkDetails extends Component {
    static propTypes = {
        remark: RemarkPropType,
        onSaveRemark: PropTypes.func.isRequired,
        onPatchResponse: PropTypes.func.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                siteId: PropTypes.string.isRequired,
                checkupId: PropTypes.string.isRequired,
                remarkId: PropTypes.string,
            }).isRequired,
        }).isRequired,
        subcategories: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.number,
                label: PropTypes.string,
            }),
        ),
        title: PropTypes.string.isRequired,
        isCreation: PropTypes.bool.isRequired,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired,
        }).isRequired,
        activeLanguage: PropTypes.string.isRequired,
        assignees: PropTypes.arrayOf(AssigneePropType),
        registerNfsFile: PropTypes.func.isRequired,
        markNfsFileForDeletion: PropTypes.func.isRequired,
    };

    static defaultProps = {
        notFound: false,
        remark: null,
        category: null,
        subcategories: [],
        assignees: [],
    };

    constructor(props) {
        super(props);

        const {remark} = props;

        this.state = {
            images: get(remark, ['images'], []),
            comment: get(remark, ['comment'], ''),
            name: get(remark, ['assignee', 'name'], ''),
            email: get(remark, ['assignee', 'email'], ''),
            deadline: get(remark, ['deadline'], ''),
            subcategory: `${get(remark, ['subcategory'], '')}`, // .toString()
            reviewerComment: get(remark, ['last_response', 'reviewer_comment'], ''),
            errors: {
                comment: null,
                name: null,
                email: null,
                deadline: null,
                subcategory: null,
                images: null,
                reviewerComment: null,
            },
            subcategories_menu_size: this.props.subcategories.length + 1,
            assignees: props.assignees,

            isSaving: false,
        };
    }

    componentWillMount = () => {
        if (!this.props.isCreation && !this.props.remark) {
            // Well something is obviously not right (Like a hard refresh before the remark was POSTed or something)
            this.props.history.goBack();
        }
    };

    onChangeField = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    onChangeAssignee = (field, value) => {
        this.setState({
            name: value ? value.name : field === 'name' ? '' : this.state.name,
            email: value ? value.email : field === 'email' ? '' : this.state.email,
        });
    };

    onAddImage = (data) => {
        if (!data) {
            this.setError('imageData', gettext('Image must be selected'));
            return;
        }
        this.clearErrors();

        const image = {
            id: this.getImageId(),

            ...(data.type === 'nfs' ? {
                type: 'nfs',
                name: data.name,
                image: data.url,
                size: data.size,
            } : {
                type: 'datauri',
                name: data.name,
                image: data.data,
                size: data.size,
            }),
        };

        this.setState({
            images: [
                ...this.state.images,
                image,
            ],
        });
    };

    onRemoveImage = (id) => {
        const oldImageIndex = this.state.images.findIndex(image => image.id === id);

        if (oldImageIndex !== -1) {
            const imageData = this.state.images[oldImageIndex];

            if (imageData.type === 'nfs') {
                this.props.markNfsFileForDeletion(imageData.image);
            }

            const images = this.state.images.slice();
            images.splice(oldImageIndex, 1);

            this.setState({
                images,
                errors: {
                    ...this.state.errors,
                    images: null,
                },
            });
        }
    };

    onSaveRemark = () => {
        const {match: {params}, remark} = this.props;
        const {comment, subcategory, name, email, deadline, images} = this.state;

        const errors = {...this.state.errors, comment: null, name: null, email: null, deadline: null, images: null};

        let valid = true;

        if (!subcategory) {
            errors.subcategory = gettext('Choose a subcategory');
            valid = false;
        }

        if (deadline && moment(deadline, "YYYY-MM-DD").isBefore(moment(), 'day')) {
            errors.deadline = gettext("A deadline shouldn't be in the past");
            valid = false;
        }

        if (!valid) {
            this.setState({errors});
            return;
        }

        this.clearErrors();
        const remarkData = {
            checkup: params.checkupId,
            assignee: name && email ? {
                name,
                email,
            } : null,
            comment,
            // because empty deadline equals empty string
            deadline: deadline || null,
            category: remark.category,
            subcategory: parseInt(subcategory, 10),
            status: remark.status,
            id: remark.id,
            images,
            siteId: params.siteId,
            // adding created date for keeping order for remarks edit view in offline mode
            created: moment().format(),
        };

        this.props.onSaveRemark(remarkData);
        this.setState({isSaving: true});
        this.props.history.replace(`/checkups/${params.siteId}/score/${params.checkupId}`);
    };

    onDeclineResponse = () => {
        const response = this.props.remark.last_response;
        const {reviewerComment} = this.state;

        const errors = {...this.state.errors, reviewerComment: null};

        if (!reviewerComment) {
            errors.reviewerComment = gettext('Describe what\'s wrong');
            this.setState({errors});
            return;
        }

        this.clearErrors();

        const responseData = {
            id: response.id,
            reviewer_comment: reviewerComment,
            state: DJ_CONST.RESPONSE_STATES.DECLINED,
            remark: this.props.remark.id,
        };

        // The data changes deep enough in the tree that React doesn't notice it, force the re-render
        // TODO: Remove forceReRender and check deep state in ComponentShouldUpdate
        this.props.onPatchResponse(responseData);
    };

    onApproveResponse = () => {
        const response = this.props.remark.last_response;

        const responseData = {
            id: response.id,
            state: DJ_CONST.RESPONSE_STATES.APPROVED,
            remark: this.props.remark.id,
        };

        // TODO: Remove forceReRender and check deep state in ComponentShouldUpdate
        this.props.onPatchResponse(responseData);
    };

    setError = (field, message) => this.setState({
        errors: {
            ...this.state.errors,
            [field]: message,
        },
    });

    getImageId = () => uuidv4();

    forceReRender = () => {
        this.forceUpdate();
    };

    clearErrors = () => this.setState({
        errors: {
            comment: null,
            name: null,
            email: null,
            deadline: null,
            subcategory: null,
            images: null,
        },
    });

    // Note: We only allow editing of the images when in creation mode (e.g. no edits)
    renderImage = image => (
        <ImageWidget
            key={image ? image.id : 'new'}
            id={`image-${image ? image.id : 'new'}`}
            name={`image-${image ? image.id : 'new'}`}
            imageData={image ? image.image : null}
            onChange={this.onAddImage}
            onDelete={() => this.onRemoveImage(image.id)}
            capture={"camera"}
            isStatic={!this.props.isCreation}
            totalCount={this.state.images.length}

            registerNfsFile={this.props.registerNfsFile}

            onError={e => toast.error(e)}
        />
    );

    renderRemarkTypeSelect() {
        const subCategory = this.props.subcategories.find(cat => cat.id === this.props.remark.subcategory);

        return (
            /* eslint-disable jsx-a11y/label-has-for */
            <div
                className="subcategories-wrp"
                onChange={(e) => {
                    this.onChangeField('subcategory', e.target.value);
                }}
            >
                {!this.props.isCreation ?
                    <label>
                        {gettext("Category")}
                        <div className="regular">
                            {subCategory ? subCategory[`label_${this.props.activeLanguage}`] : ''}
                        </div>
                    </label> :
                    this.props.subcategories.map(category => (
                        <label key={`label-${category.id}-key`} htmlFor={`radio-${category.id}`}>
                            <input
                                key={`radio-${category.id}-key`}
                                id={`radio-${category.id}`}
                                type="radio"
                                value={category.id}
                                name={`radio-${category.id}`}
                                checked={this.state.subcategory === `${category.id}`}
                            />
                            {category[`label_${this.props.activeLanguage}`]}
                        </label>
                    ))}
                {this.state.errors.subcategory ? (
                    <span id={`error-subcategory`} className='help-block'>{this.state.errors.subcategory}</span>
                ) : null}
            </div>
            /* eslint-enable jsx-a11y/label-has-for */
        );
    }

    renderButtons = () => { // eslint-ignore-line consistent-return
        const {remark} = this.props;

        if (this.props.isCreation) {
            return (
                <div className="btn btn-success btn-block btn-round btn-bold" onClick={this.onSaveRemark}>
                    {gettext('Save remark')}
                </div>
            );
        }

        if (remark && remark.last_response) {
            switch (remark.last_response.state) {
                case DJ_CONST.RESPONSE_STATES.PENDING: {
                    return [
                        <div
                            key="link-approve"
                            className="btn btn-success btn-block btn-round btn-bold"
                            onClick={this.onApproveResponse}
                        >
                            {gettext('Approve response')}
                        </div>,
                        <div
                            key="link-decline"
                            className="btn btn-danger btn-block btn-round btn-bold"
                            onClick={this.onDeclineResponse}
                        >
                            {gettext('Decline response')}
                        </div>,
                    ];
                }

                case DJ_CONST.RESPONSE_STATES.APPROVED: {
                    return (
                        <div className="btn btn-success btn-block btn-round btn-bold disabled">
                            {gettext('You already accepted this response')}
                        </div>
                    );
                }

                case DJ_CONST.RESPONSE_STATES.DECLINED: {
                    return (
                        <div className="btn btn-danger btn-block btn-round btn-bold disabled">
                            {gettext('You already declined this response')}
                        </div>
                    );
                }

                default: {
                    return null;
                }
            }
        } else {
            return (
                <div className="btn btn-success btn-block btn-round btn-bold disabled">
                    {gettext('No responses submitted')}
                </div>
            );
        }
    };

    render() {
        const {match, title, isCreation, remark} = this.props;
        const {comment, images, name, email, deadline, reviewerComment, errors, assignees, isSaving} = this.state;

        let overlay;

        if (isSaving) {
            overlay = (
                <div className="loading overlay">
                    <div className="loading-mini" />
                </div>
            );
        }

        return (
            <div className="view__contents page--remark-details sticky">
                <h3><Link to={`${match.url}/approve`}>{title}</Link></h3>
                {this.renderRemarkTypeSelect()}
                <TextArea
                    name="comment"
                    className="margin-lg-top"
                    label={gettext('Comment')}
                    value={comment}
                    cols={40}
                    rows={4}
                    error={errors.comment}
                    onChange={e => this.onChangeField('comment', e.target.value)}
                    readOnly={!isCreation}
                />

                <div className="images--container--wrp">
                    <div className="images--container">
                        {this.renderImage(null)}
                        {images.map(this.renderImage)}
                    </div>
                    {errors.images ? <p className="error">{errors.images}</p> : null}
                </div>

                {(!isCreation && email) || isCreation ?
                    <div>
                        <div className="margin-lg-top margin-sm-bottom">{gettext('Responsible person')}</div>
                        <div className="form-group margin-md-left margin-md-right">
                            {isCreation ?
                                <div className="form-group">
                                    <span className="label">{gettext('Name')}</span>
                                    <Select.Creatable
                                        options={assignees.filter(o => o.name).map(o =>
                                            ({value: o.name, label: o.name, email: o.email, name: o.name}))}
                                        onChange={value => this.onChangeAssignee('name', value)}
                                        onNewOptionClick={(o) => {
                                            const lastIdx = assignees.length - 1;
                                            const lastObs = assignees[lastIdx];
                                            if (lastIdx === -1 || (lastObs && lastObs.id)) {
                                                assignees.push({email, name: o.label});
                                            } else {
                                                assignees[lastIdx].name = o.label;
                                            }
                                            this.setState({assignees});
                                        }}
                                        placeholder={gettext('Enter name')}
                                        value={name}
                                        name="name"
                                    />
                                </div> :
                                <Input
                                    label={gettext('Name')}
                                    name="name"
                                    value={name || ""}
                                    readOnly
                                />
                            }
                            {isCreation ?
                                <div className="form-group">
                                    <span className="label">{gettext('Email')}</span>
                                    <Select.Creatable
                                        options={assignees.filter(o => o.email).map(o =>
                                            ({value: o.email, label: o.email, email: o.email, name: o.name}))}
                                        onChange={value => this.onChangeAssignee('email', value)}
                                        onNewOptionClick={(o) => {
                                            const lastIdx = assignees.length - 1;
                                            const lastObs = assignees[lastIdx];
                                            if (lastIdx === -1 || (lastObs && lastObs.id)) {
                                                assignees.push({email: o.label, name});
                                            } else {
                                                assignees[lastIdx].email = o.label;
                                            }
                                            this.setState({assignees});
                                        }}
                                        isValidNewOption={o => validateEmail(o.label)}
                                        placeholder={gettext('Enter email')}
                                        value={email}
                                        name="email"
                                    />
                                </div> :
                                <Input
                                    label={gettext('Email')}
                                    name="email"
                                    type="email"
                                    value={email || ""}
                                    readOnly
                                />
                            }
                            {(!isCreation && deadline) || isCreation ? <Input
                                label={gettext('Deadline')}
                                name="deadline"
                                type={!isCreation ? "text" : "date"}
                                value={deadline || ""}
                                min={moment().format('YYYY-MM-DD')}
                                error={errors.deadline}
                                onChange={e => this.onChangeField('deadline', e.target.value)}
                                readOnly={!isCreation}
                            /> : null }
                        </div>
                    </div> : null
                }
                {remark && remark.last_response ? <hr /> : null}
                {
                    remark && remark.last_response ?
                        <div className="recent-response">
                            <h3>{gettext('Most recent response')}</h3>
                            <TextArea
                                name="response-comment"
                                className="margin-lg-top"
                                label={gettext('Assignee\'s comment')}
                                value={remark.last_response.comment}
                                cols={40}
                                rows={4}
                                readOnly
                            />

                            {
                                remark.last_response.images && remark.last_response.images.length ?
                                    <div className="images--container--wrp">
                                        <div className="images--container">
                                            {remark.last_response.images.map(this.renderImage)}
                                        </div>
                                    </div> : null
                            }

                            <TextArea
                                name="reviewr-comment"
                                className="margin-lg-top"
                                label={gettext('Your comment')}
                                value={reviewerComment || ''}
                                cols={40}
                                rows={4}
                                error={errors.reviewerComment}
                                onChange={e => this.onChangeField('reviewerComment', e.target.value)}
                            />
                        </div> : null
                }
                <div className="view__bottom sticky">
                    {this.renderButtons()}
                </div>
                {overlay}
            </div>
        );
    }
}

export default withView(gettext('Remark details'), true,
    {navOptions: {
        goBack: (props) => {
            if (props.match.path.startsWith('/previous-remarks')) {
                props.history.goBack();
            } else {
                props.history.replace(`/checkups/${props.match.params.siteId}/score/${props.match.params.checkupId}`);
            }
        }},
    },
)(connect(mapStateToProps, mapDispatchToProps)(RemarkDetails));
