import {call, put} from 'redux-saga/effects';
import moment from 'moment';
import api from 'utils/api';
import fizzlesWhenOffline from "utils/fizzlesWhenOffline";
import {handleApiFetch} from 'sagas/helpers/callApi';

import {getSubcategoriesReport} from "ducks/buildingSites";


function* fetchBuildingSiteSubcategoriesReport(matchObj) {
    const {result: response, error} = yield call(handleApiFetch, api.apiBuildingSitesSubcategoriesReport,
        {
            pk: matchObj.params.siteId,
            start_date: matchObj.params.startDate === '3m' ? moment().subtract(3, 'months').format('YYYY-MM-DD') : null,
        }, null, null,
    );

    if (response) {
        yield put(getSubcategoriesReport(response, matchObj.params.siteId, matchObj.params.startDate));
    }

    if (error) {
        return error;
    }

    return null;
}

export default fizzlesWhenOffline(fetchBuildingSiteSubcategoriesReport);
