import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import uuid from 'uuid';
import get from 'lodash.get';

import withView from "decorators/withView";
import {gettext} from 'utils/i18n';

import {STATE_KEY, requestAddCheckUp, requestUpdateCheckUp} from "ducks/buildingSites";
import {SitePropType, CheckUpPropType} from 'utils/types';

import Input from 'components/inputs/Input';
import List from "components/List";
import RemovableObject from 'components/RemovableObject';
import AddObserverForm from "components/AddObserverForm";

const mapStateToProps = (state, ownProps) => {
    const site = state[STATE_KEY].data[ownProps.match.params.siteId];
    if (!site) {
        return {site: null};
    }

    const checkUpId = ownProps.match.params.checkupId;
    const checkUp = get(site, ['checkUps', checkUpId], null);

    return {
        site,
        checkUp,
        title: checkUpId ? site.name : gettext('New checkup'),
    };
};

const mapDispatchToProps = dispatch => ({
    onCreateCheckup: checkupData => dispatch(requestAddCheckUp(checkupData)),
    onUpdateCheckup: checkupData => dispatch(requestUpdateCheckUp(checkupData)),
});

class CheckupDetailView extends Component {
    static propTypes = {
        site: SitePropType,
        checkUp: CheckUpPropType,
        onCreateCheckup: PropTypes.func.isRequired,
        onUpdateCheckup: PropTypes.func.isRequired,
    };

    static defaultProps = {
        site: null,
        checkUp: null,
    };

    constructor(props) {
        super(props);

        const date = props.checkUp ? props.checkUp.date : new Date().toLocaleDateString('en-GB');

        this.state = {
            observers: (props.checkUp ? props.checkUp.observers : []) || [],
            date,
            isSuccess: false,
            checkupId: null,
            errors: {
                generic: null,
            },
        };
    }

    onRemoveObserver = id => this.setState({observers: this.state.observers.filter(member => member.id !== id)});

    onAddCheckup = () => {
        const {site, checkUp} = this.props;
        const {observers} = this.state;

        const checkupData = {
            site: site.id,
            id: checkUp ? checkUp.id : uuid.v4(),
            date: checkUp ? checkUp.date : new Date().toJSON().slice(0, 10),
            observers,
            remarks: [],
        };

        this.clearErrors();

        if (!checkUp) {
            checkupData.status = DJ_CONST.CHECKUP_STATUSES.STATUS_IN_PROGRESS;
            this.props.onCreateCheckup(checkupData);
        } else {
            this.props.onUpdateCheckup(checkupData);
        }

        this.finishAdding(checkupData.id);
    };

    onAddObserver = (observer) => {
        const mergeObserverIntoState = (o) => {
            const n = this.state.observers.slice();
            let duplicate = false;
            n.forEach((e) => {
                if (e.name === o.name && e.email === o.email) {
                    duplicate = true;
                }
            });
            if (!duplicate) {
                n.push(o);
            }
            return n;
        };

        this.setState({
            observers: mergeObserverIntoState(observer),
        });
    };

    clearErrors = () => this.setState({
        errors: {
            generic: null,
        },
    });

    finishAdding = checkupId => this.setState({isSuccess: true, checkupId});

    renderObservers = () => (
        <List header={gettext("Observers")}>
            {this.state.observers.map(observer => (
                <RemovableObject
                    title={observer.name}
                    subtitle={observer.title}
                    onRemove={() => this.onRemoveObserver(observer.id)}
                    key={observer.name}
                />
            ))}
        </List>
    );

    render() {
        const {site, checkUp} = this.props;

        if (this.state.isSuccess) {
            if (!site) {
                return (<span />);
            }

            return (
                <Redirect to={`/checkups/${site.id}/score/${this.state.checkupId}`} />
            );
        }

        return (
            <div className="view__contents sticky page--checkup--details">

                <Input
                    label={gettext('Name')}
                    name="name"
                    readOnly
                    value={site ? site.name : ''}
                />

                <div className="form-group">
                    <label htmlFor="#" className="label">{gettext("Date")}</label>
                    <div className="form-control">{this.state.date}</div>
                </div>

                <Input
                    label={gettext('Address')}
                    name="address"
                    readOnly
                    value={site ? site.address : ''}
                />

                {this.renderObservers()}
                <AddObserverForm
                    onAddObserver={this.onAddObserver}
                    referrer={this.props.site.reviewer.id}
                    saveBtnTxt={gettext('Add observer')}
                />

                <div className="view__bottom sticky">
                    <div className="btn btn-success btn-block btn-bold" onClick={this.onAddCheckup}>
                        {checkUp ? gettext('Edit checkup') : gettext('Start new checkup')}
                        <span className="lnr lnr-chevron-right pull-right" />
                    </div>
                </div>
            </div>
        );
    }
}

export default withView(gettext('Checkup'), true)(connect(mapStateToProps, mapDispatchToProps)(CheckupDetailView));
