import {all, take, fork, put, select} from 'redux-saga/effects';

import routes from 'configuration/routes';
import ViewManager, {pageLoad, locationSelector} from 'sagas/ViewManager';
import nfsSagaRoot from 'sagas/NfsSaga';


export default function* rootSaga(hot = false) {
    // On slower browsers the network state detection is too slow. Since the default value for online in redux-offline
    //  is set to false we have to wait until network status is actually detected before running our sagas. Otherwise
    //  fizzlesWhenOffline just ignores our initial data fetching sagas.
    yield take('Offline/STATUS_CHANGED');

    yield all([
        fork(ViewManager, routes),
    ]);

    if (!SERVER_MODE) {
        yield fork(nfsSagaRoot);
    }

    if (DEV_MODE && hot) {
        const location = yield select(locationSelector);
        yield put(pageLoad(location, () => null));
    }
}
