import PropTypes from 'prop-types';


export const UuidPropType = function (props, propName, componentName) {
    if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(props[propName])) {
        return new Error(`UUID Validation Failed: Invalid prop '${propName}' = '${props[propName]}' supplied to` +
            `'${componentName}'. Validation failed.`);
    }
    return null;
};


export const SitePropType = PropTypes.shape({
    id: UuidPropType,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
});


export const SortingFieldPropType = PropTypes.shape({
    field: PropTypes.string.isRequired,
    dir: PropTypes.string.isRequired,
});


export const ObserverPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    referrer: PropTypes.number.isRequired,
});

export const AssigneePropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    referrer: PropTypes.number.isRequired,
});


export const LocalImagePropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
});

export const RemoteImagePropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
});

export const ImagePropType = PropTypes.oneOfType([LocalImagePropType, RemoteImagePropType]);


export const RemarkPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    comment: PropTypes.string,
    checkUp: PropTypes.string,
    status: PropTypes.number.isRequired,
    category: PropTypes.number.isRequired,
    images: PropTypes.arrayOf(ImagePropType.isRequired),
    last_response: PropTypes.shape({
        images: PropTypes.arrayOf(ImagePropType),
        comment: PropTypes.string,
    }),
});

export const ReportTrendPropType = PropTypes.shape({
    date: PropTypes.string.isRequired,
    etom_index: PropTypes.number.isRequired,
    checkup_id: PropTypes.string.isRequired,
});

export const ReportLastCheckupPropType = PropTypes.shape({
    date: PropTypes.string.isRequired,
    etom_index: PropTypes.number.isRequired,
    positive_remarks: PropTypes.number.isRequired,
    negative_remarks: PropTypes.number.isRequired,
    total_remarks: PropTypes.number.isRequired,
    by_category: PropTypes.shape({
        category: PropTypes.shape({
            negative: PropTypes.number,
            total: PropTypes.number,
        }),
    }).isRequired,
});


export const ReportPropType = PropTypes.shape({
    etom_index_baseline: PropTypes.number.isRequired,
    negative_remarks_count: PropTypes.number.isRequired,
    solved_remarks_count: PropTypes.number.isRequired,
    comparison: PropTypes.shape({
        week: PropTypes.shape({
            etom_average: PropTypes.number.isRequired,
            site_average: PropTypes.number.isRequired,
            company_average: PropTypes.number.isRequired,
        }).isRequired,
        month: PropTypes.shape({
            etom_average: PropTypes.number.isRequired,
            site_average: PropTypes.number.isRequired,
            company_average: PropTypes.number.isRequired,
        }).isRequired,
        year: PropTypes.shape({
            etom_average: PropTypes.number.isRequired,
            site_average: PropTypes.number.isRequired,
            company_average: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
    // TODO: fix main_issues
    main_issues: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    trend: PropTypes.arrayOf(ReportTrendPropType).isRequired,
    site: PropTypes.string.isRequired,
    last_checkup: ReportLastCheckupPropType,
});

export const SubcategoriesReportPropType = PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        label_en: PropTypes.string.isRequired,
        label_et: PropTypes.string.isRequired,
        label_ru: PropTypes.string.isRequired,
        positive_count: PropTypes.number.isRequired,
        negative_count: PropTypes.number.isRequired,
        subcategories: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            label_en: PropTypes.string.isRequired,
            label_et: PropTypes.string.isRequired,
            label_ru: PropTypes.string.isRequired,
            negative_count: PropTypes.number.isRequired,
        })),
    })).isRequired,
    per_assignee_responses: PropTypes.arrayOf(PropTypes.shape({
        email: PropTypes.string.isRequired,
        approved_count: PropTypes.number.isRequired,
        declined_pending_count: PropTypes.number.isRequired,
        no_answered_count: PropTypes.number.isRequired,
    })).isRequired,
    workers_and_remarks: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string.isRequired,
        employee_count: PropTypes.number.isRequired,
        remarks_count: PropTypes.number.isRequired,
    })).isRequired,
});


export const CheckUpPropType = PropTypes.shape({
    date: PropTypes.string.isRequired,
    observers: PropTypes.arrayOf(ObserverPropType),
});


export const CategoryPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    label_en: PropTypes.string.isRequired,
    label_et: PropTypes.string.isRequired,
    label_ru: PropTypes.string.isRequired,
    help_en: PropTypes.string.isRequired,
    help_et: PropTypes.string.isRequired,
    help_ru: PropTypes.string.isRequired,
});


const RouteLocationObject = {
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
};

export const RouterLocationShape = PropTypes.shape({
    ...RouteLocationObject,
    state: PropTypes.shape({
        from: PropTypes.shape(RouteLocationObject),
    }),
});


export const RouterMatchShape = PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired, // eslint-disable-line
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

export const RouterHistoryShape = PropTypes.shape({
    action: PropTypes.string,
});


export const ErrorShape = PropTypes.shape({
    statusCode: PropTypes.number,
    message: PropTypes.string,
    stack: PropTypes.any,
});


export const UserShape = PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    is_superuser: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_active: PropTypes.bool,
    last_login: PropTypes.string,
    date_joined: PropTypes.string,
});


export const UsersShape = PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    user: UserShape,
});


/**
 * Generate type for each field defined
 * @param fields - Array of field names
 * @param type - `PropType` for single field
 */
const getFieldMapping = (fields, type) => fields.reduce((result, current) => {
    result[current] = type; // eslint-disable-line no-param-reassign
    return result;
}, {});

export const getFormPropTypes = fields => ({
    values: PropTypes.shape(
        getFieldMapping(fields, PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
    ).isRequired,
    status: PropTypes.string,
    errors: PropTypes.shape(getFieldMapping(fields, PropTypes.string)).isRequired,
    touched: PropTypes.shape(getFieldMapping(fields, PropTypes.bool)).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setValues: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
});
