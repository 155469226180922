import Router, {Resource} from 'tg-resources';
import {getCookie} from 'utils/cookie';

class UpdatedResource extends Resource {
    createRequest(method, url, query, data, attachments, requestConfig) {
        const req = super.createRequest(method, url, query, data, attachments, requestConfig);

        if (requestConfig.mutateRequest) {
            return requestConfig.mutateRequest(req);
        }
        return req;
    }
}


const resources = Object.entries(DJ_CONST.API || {})
    .filter(item => item[0] !== 'apiRoot')
    .reduce((result, [key, url]) => {
        result[key] = new UpdatedResource(url); // eslint-disable-line no-param-reassign
        return result;
    }, {});


const api = new Router(
    resources,
    {
        apiRoot: DJ_CONST.API_BASE,
        headers: () => ({
            Accept: 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        }),
        withCredentials: true,
    });

export default api;
