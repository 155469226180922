import React from 'react';
import {ResponsiveContainer, PieChart, Pie, Cell, Label} from 'recharts';
import {interpolate, gettext} from 'utils/i18n';
import {ReportLastCheckupPropType} from "../utils/types";


const CheckupPieChart = ({checkup}) => {
    const data = [{name: "pros", value: checkup.positive_remarks}, {name: "cons", value: checkup.negative_remarks}];

    const renderLabel = (labelProps) => {
        const {cx, cy} = labelProps.viewBox;

        return (
            <g>
                <text className="checkup__value" x={cx} y={cy} dy={-5} textAnchor="middle">
                    {`${checkup.etom_index}%`}
                </text>
                <text className="small" x={cx} y={cy} dy={15} textAnchor="middle">
                    {interpolate(gettext('%d remarks'), checkup.total_remarks)}
                </text>
            </g>
        );
    };

    return (
        <React.Fragment>
            <h5 className="text-center">{gettext('Last checkup')}</h5>
            <ResponsiveContainer height={200}>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        innerRadius={60}
                        outerRadius={90}
                        fill="#54BD95"
                    >
                        <Label position="center" content={renderLabel} />
                        {data.map(entry => (
                            <Cell key={entry.name} fill={entry.name === "pros" ? "#54BD95" : "#F32D3A"} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <p className="text-center">{checkup.date}</p>
        </React.Fragment>
    );
};

CheckupPieChart.propTypes = {
    checkup: ReportLastCheckupPropType.isRequired,
};

export default CheckupPieChart;
