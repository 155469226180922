import React from 'react';
import Markdown from 'react-markdown';
import appView from "utils/appView";
import {gettext} from 'utils/i18n';


const Terms = () => (
    <div className='page--terms'>
        <h1 className='terms-heading'>{gettext('eTOM software terms and conditions agreement')}</h1>
        <Markdown
            className="terms-text"
            source={gettext('terms full text\n')}
            escapeHtml={false}
        />
    </div>
);

export default appView(gettext('Terms'))(Terms);
