import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const TextArea = ({id, name, className, label, placeholder, value, cols, rows, error, onChange, readOnly}) => (
    <div className={classNames('form-group', className, {"has-error": error})}>
        <label htmlFor={id || name}>{label}</label>
        <textarea
            id={id}
            name={name}
            className={classNames('form-control', {error})}
            placeholder={placeholder}
            cols={cols}
            rows={rows}
            value={value}
            onChange={!readOnly ? onChange : null}
            readOnly={readOnly}
        />
        {error ? (
            <span id={`error-${id}`} className="help-block">{error}</span>
        ) : null}
    </div>
);

TextArea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string,
    label: PropTypes.string.isRequired,
    cols: PropTypes.number.isRequired,
    rows: PropTypes.number.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
};

TextArea.defaultProps = {
    id: null,
    error: null,
    value: '',
    className: '',
    placeholder: '',
    readOnly: false,
    onChange: () => {},
};

export default TextArea;
