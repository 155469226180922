import React from 'react';
import ReactDOM from 'react-dom';
import {toast} from 'react-toastify';
import Raven from 'raven-js';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';

import {Provider} from 'react-redux';
import {ConnectedRouter} from 'react-router-redux';
import {renderRoutes} from 'react-router-config';
import {AppContainer} from 'react-hot-loader';

import configureStore from 'configuration/configureStore';
import routes from 'configuration/routes';
import {selectors as userSelectors} from 'ducks/user';
import i18n, {gettext} from 'utils/i18n';
import initNfs from 'utils/nfs';


// Install Raven in production envs
if (!DEV_MODE) {
    Raven.config(DJ_CONST.RAVEN_PUBLIC_DSN).install(); // eslint-disable-line
    // handle rejected promises
    window.addEventListener('unhandledrejection', (evt) => {
        Raven.captureException(evt.reason);
    });
}

// Install our service worker
OfflinePluginRuntime.install({
    onInstalled: () => {
        console.log('onInstalled');
    },
    onUpdating: () => {
        console.log('SW Event:', 'onUpdating');
    },
    onUpdateReady: () => {
        console.log('SW Event:', 'onUpdateReady');
        // Tells to new SW to take control immediately
        OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
        console.log('SW Event:', 'onUpdated');
        // Reload the webpage to load into the new version
        const r = confirm(gettext("eTOM has updated version. To get updated version the application " +
            "must be restarted. Do you want to restart?"));
        if (r === true) {
            window.location.reload();
        }
    },
    onUpdateFailed: () => {
        console.log('SW Event:', 'onUpdateFailed');
    },
});

// We want to handle scroll restoration on our own (this only really works in Chrome)
// So sorry Chrome & Firefox users
if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
}

// Try to initialize native filesystem api
initNfs(msg => toast.error(msg));

const initialState = window.__initial_state__ || {};
const {store, history} = configureStore(initialState);

const currentLanguage = userSelectors.activeLanguage(store.getState());
i18n.forceLanguage(currentLanguage);


// Note: If enabling SSR change ReactDOM.render->ReactDOM.hydrate
const render = (appRoutes) => {
    ReactDOM.render(
        <AppContainer>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    {renderRoutes(appRoutes)}
                </ConnectedRouter>
            </Provider>
        </AppContainer>,
        document.getElementById('root'));
};

if (module.hot) {
    module.hot.accept('./configuration/routes', () => {
        const appRoutes = require('./configuration/routes').default; // eslint-disable-line
        render(appRoutes);
    });
}

function init() {
    render(routes);
}

init();

if (typeof window !== 'undefined') {
    window.initEtom = init;
}
