import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tappable from 'react-tappable/lib/Tappable';
import {Icon} from 'react-fa';


class ScoreInput extends Component {
    changeScore = () => {
        this.props.onScoreChange();

        if ('vibrate' in window.navigator) {
            window.navigator.vibrate(this.props.vibrationDuration);
        }
    };

    render() {
        const {label, theme, showMinusButton, icon} = this.props;
        const {value} = this.props;

        return (
            <div className={`score-input score-input--${theme}`}>
                {label ? (<span className="score-input__label">{label}</span>) : null}
                <div className="score-input__triplet">
                    {showMinusButton ? (
                        <Tappable
                            className={
                                classNames('score-input__triplet__left',
                                    {'score-input__triplet--disabled': value === 0},
                                )
                            }
                            onPress={() => this.changeScore()}
                            onTap={() => this.changeScore()}
                        >
                            <Icon name="minus" inverse />
                        </Tappable>
                    ) : null}
                    <div className="score-input__triplet__middle">{value}</div>
                    <Tappable
                        className="score-input__triplet__right"
                        onPress={() => this.changeScore()}
                        onTap={() => this.changeScore()}
                    >
                        <Icon name={icon} inverse />
                    </Tappable>
                </div>
            </div>
        );
    }
}

ScoreInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    onScoreChange: PropTypes.func.isRequired,
    vibrationDuration: PropTypes.number,
    theme: PropTypes.oneOf(['green', 'red']),
    showMinusButton: PropTypes.bool,
    icon: PropTypes.string.isRequired,
};

ScoreInput.defaultProps = {
    value: 0,
    vibrationDuration: 50,
    theme: 'green',
    label: null,
    showMinusButton: true,
};

export default ScoreInput;
