import {call, put} from 'redux-saga/effects';

import api from 'utils/api';
import fizzlesWhenOffline from "utils/fizzlesWhenOffline";
import {handleApiFetch} from 'sagas/helpers/callApi';

import {getSites, addSite} from "ducks/buildingSites";

function* fetchBuildingSites() {
    const {result: response, error} = yield call(handleApiFetch, api.apiBuildingSitesList,
        null, null, null,
    );

    if (response) {
        yield put(getSites(response));
    }

    if (error) {
        yield put(getSites([]));
        return error;
    }

    return null;
}

export default fizzlesWhenOffline(fetchBuildingSites);

function* getBS(matchObj) {
    const {result: response, error} = yield call(handleApiFetch, api.apiBuildingSitesGet,
        {pk: matchObj.params.siteId}, null, null,
    );

    if (response) {
        yield put(addSite(response));
    }

    if (error) {
        return error;
    }

    return null;
}

export const getBuildingSite = fizzlesWhenOffline(getBS);
