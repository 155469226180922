import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {gettext} from 'utils/i18n';
import moment from 'moment';
import {Icon} from 'react-fa';

import {ResponsiveContainer, ReferenceLine, LineChart, Line, Dot, XAxis, YAxis, CartesianGrid} from 'recharts';
import {ReportTrendPropType, SitePropType} from "utils/types";

class SiteOverview extends Component {
    constructor(props) {
        super(props);

        this.POINTS_ON_GRAPH = 26;

        this.state = {
            graphEndPoint: this.props.trend ? this.props.trend.length : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        // If the graphEndPoint is null from initial render, fix it eventually
        if ((this.props.trend) &&
            (this.props.trend.length !== prevState.graphEndPoint)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({graphEndPoint: this.props.trend.length});
        }
    }

    moveChartFocus = (direction) => {
        if (direction > 0) {
            if (this.state.graphEndPoint + direction <= this.props.trend.length) {
                this.setState({graphEndPoint: this.state.graphEndPoint + direction});
            } else if (this.state.graphEndPoint < this.props.trend.length) {
                this.setState({graphEndPoint: this.props.trend.length});
            }
        } else if (direction < 0) {
            if (this.state.graphEndPoint + direction >= this.POINTS_ON_GRAPH) {
                this.setState({graphEndPoint: this.state.graphEndPoint + direction});
            } else if (this.state.graphEndPoint < this.POINTS_ON_GRAPH) {
                this.setState({graphEndPoint: this.POINTS_ON_GRAPH});
            }
        }
    };

    render() {
        const {trend} = this.props;

        const chartData = Object.values(trend).map(c => ({
            date: c.date,
            value: c.etom_index,
        }));

        const graphStartPoint = this.state.graphEndPoint - this.POINTS_ON_GRAPH >= 0 ?
            this.state.graphEndPoint - this.POINTS_ON_GRAPH : 0;

        const displayData = chartData.slice(graphStartPoint, this.state.graphEndPoint);
        const chartValues = displayData.map(c => c.value);

        const maxValue = Math.max(...chartValues);
        const minValue = Math.min(...chartValues);

        const displayDataLbls = displayData.map(
            c => ({date: c.value === maxValue || c.value === minValue ? c.date : '', value: c.value}),
        );

        const renderDot = ({key, cx, cy, payload}) => {
            const fill = payload.value === minValue ? '#F32D3A' : payload.value === maxValue ? '#54BD95' : '#000000';
            return (<Dot r={5} key={key} cx={cx} cy={cy} fill={fill} />);
        };

        const renderLabel = ({x, y, value}) => { // eslint-disable-line arrow-body-style
            return (
                <text x={x} y={y} dy={-10} fill="#999999" fontSize={14} textAnchor="middle">
                    {`${value === maxValue ? `max ${value.toFixed(0)}` :
                        value === minValue ? `min ${value.toFixed(0)}` : ''}`}
                </text>
            );
        };

        let trendStartDate = this.props.trend_start_date;
        if (!this.props.trend_start_date) {
            if (this.props.site) {
                trendStartDate = moment(this.props.site.start, 'DD.MM.YYYY').format('DD.MM.YYYY');
            }
        }

        return (
            <div className="overview__graph">
                <h5 className="text-center">
                    {this.props.trend_start_date ?
                        gettext('Trend since') :
                        gettext('Trend since the beginning of the site on')
                    } {trendStartDate}
                </h5>
                <ResponsiveContainer height={200}>
                    <LineChart
                        data={displayDataLbls}
                        margin={{right: 50, top: 25}}
                    >
                        <XAxis padding={{left: 30, right: 30}} dataKey="date" />
                        <YAxis
                            padding={{left: 30, right: 30}}
                            domain={[dataMin => Math.max(dataMin - 10, 0), 100]}
                        />
                        <CartesianGrid strokeDasharray="2 5" />
                        <ReferenceLine
                            alwaysShow
                            y={this.props.etom_index_baseline}
                            stroke="red"
                            strokeDasharray="3 3"
                        />
                        <Line
                            type="monotone"
                            dot={renderDot}
                            label={renderLabel}
                            dataKey="value"
                            stroke="#54BD95"
                            strokeWidth={2}
                            isAnimationActive={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
                <button className="back" onClick={() => this.moveChartFocus(-this.POINTS_ON_GRAPH)}>
                    <Icon name="chevron-circle-left" size="2x" />
                </button>
                <button className="forward" onClick={() => this.moveChartFocus(this.POINTS_ON_GRAPH)}>
                    <Icon name="chevron-circle-right" size="2x" />
                </button>
            </div>
        );
    }
}

SiteOverview.propTypes = {
    site: SitePropType.isRequired,
    etom_index_baseline: PropTypes.number.isRequired,
    trend: PropTypes.arrayOf(ReportTrendPropType).isRequired,
    trend_start_date: PropTypes.string,
};

SiteOverview.defaultProps = {
    trend_start_date: '',
};

export default SiteOverview;
