import {call, put, select} from 'redux-saga/effects';

import api from 'utils/api';
import {handleApiFetch} from 'sagas/helpers/callApi';

import {addCheckup} from "ducks/buildingSites";
import fizzlesWhenOffline from "utils/fizzlesWhenOffline";

function* getCheckup(matchObj) {
    // Don't try to fetch it if creating a new one
    if (!matchObj.params.checkupId) {
        return null;
    }

    const {result: response, error} = yield call(handleApiFetch, api.apiCheckupGet,
        {pk: matchObj.params.checkupId}, null, null,
    );

    if (response) {
        const remarkCategories = yield select(state => state.buildingSites.remarkCategories);

        // Ensure checkup has structure for all remark categories
        Object.values(remarkCategories).forEach((category) => {
            if (!response.remarks[category.id]) {
                response.remarks[category.id] = {};

                Object.keys(DJ_CONST.REMARK_STATUSES).forEach((k) => {
                    response.remarks[category.id][k] = [];
                });
            }
        });

        yield put(addCheckup(response));
    }

    if (error) {
        return error;
    }

    return null;
}

export default fizzlesWhenOffline(getCheckup);
