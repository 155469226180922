import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {Route} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';

import ErrorBoundary from 'containers/ErrorBoundary';
import PendingDataRouter from 'containers/PendingDataRouter';
import Loader from "components/Loader";


// Load styles early if browser and DEV_MODE
if (typeof window !== 'undefined' && DEV_MODE) {
    require('../../../static/styles-src/main'); // eslint-disable-line global-require
}


const App = ({route}) => (
    <ErrorBoundary>
        <Loader>
            <Helmet titleTemplate='%s - eTOM' defaultTitle='eTOM' />
            <PendingDataRouter>
                {renderRoutes(route.routes)}
            </PendingDataRouter>
        </Loader>
    </ErrorBoundary>
);

App.propTypes = {
    route: PropTypes.shape({
        routes: PropTypes.arrayOf(PropTypes.shape(Route.propTypes)),
    }).isRequired,
};

export default App;
