import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import filterRemarks from "utils/remarks";

import appView from 'utils/appView';
import {gettext} from 'utils/i18n';
import {RemarkPropType, SitePropType} from 'utils/types';

import {STATE_KEY} from "ducks/buildingSites";


const Remarks = ({site, remarks}) => (
    <div className="view__contents page--remarks">
        <div className="container">
            <Link className='btn btn-info btn-block btn-bold' to={`/sites/${site.id}/remarks/after-deadline`}>
                <span className="counter color-red">
                    {filterRemarks(remarks, 'after-deadline').length}
                </span>
                {gettext('After deadline')}
            </Link>
            <Link className='btn btn-info btn-block btn-bold' to={`/sites/${site.id}/remarks/before-deadline`}>
                <span className="counter color-orange">
                    {filterRemarks(remarks, 'before-deadline').length}
                </span>
                {gettext('Before deadline')}
            </Link>
            <Link className='btn btn-info btn-block btn-bold' to={`/sites/${site.id}/remarks/last`}>
                <span className="counter color-yellow">
                    {filterRemarks(remarks, 'last').length}
                </span>
                {gettext('Last remarks')}
            </Link>
            <Link
                className='btn btn-info btn-block btn-bold waiting-confirmation'
                to={`/sites/${site.id}/remarks/waiting-confirmation`}
            >
                <span className="counter color-blue">
                    {filterRemarks(remarks, 'waiting-confirmation').length}
                </span>
                {gettext('Waiting confirmation')}
            </Link>
            <Link className='btn btn-info btn-block btn-bold' to={`/sites/${site.id}/remarks/resolved`}>
                <span className="counter color-green">
                    {filterRemarks(remarks, 'resolved').length}
                </span>
                {gettext('Resolved remarks')}
            </Link>
            <Link className='btn btn-info btn-block btn-bold' to={`/sites/${site.id}/remarks/non-resolved-archived`}>
                <span className="counter color-gray">
                    {filterRemarks(remarks, 'non-resolved-archived').length}
                </span>
                {gettext('Archieved and not resolved remarks')}
            </Link>
        </div>
    </div>
);

Remarks.propTypes = {
    site: SitePropType,
    remarks: PropTypes.arrayOf(RemarkPropType),
};

Remarks.defaultProps = {
    site: null,
    remarks: [],
};

const RemarksView = appView()(Remarks);

const mapStateToProps = (state, ownProps) => {
    const site = state[STATE_KEY].data[ownProps.match.params.siteId];

    if (!site) {
        return {site: null, remarks: [], checkupId: ''};
    }

    const flattenedRemarks = [];

    Object.entries(state[STATE_KEY].remarks).forEach(([, types]) => {
        Object.entries(types).forEach(([, statuses]) => {
            Object.entries(statuses).forEach(([, remarks]) => {
                flattenedRemarks.push(...remarks);
            });
        });
    });

    return {
        site,
        title: site.name,
        remarks: flattenedRemarks,
    };
};

const RemarksViewConnector = connect(
    mapStateToProps,
)(RemarksView);


export default withRouter(RemarksViewConnector);
