import {call, put} from 'redux-saga/effects';

import api from 'utils/api';
import fizzlesWhenOffline from "utils/fizzlesWhenOffline";
import {handleApiFetch} from 'sagas/helpers/callApi';

import {getCheckups} from "ducks/buildingSites";


function* fetchBuildingSiteCheckups(matchObj) {
    const {result: response, error} = yield call(handleApiFetch, api.apiBuildingSitesCheckups,
        {pk: matchObj.params.siteId}, null, null,
    );

    if (response) {
        yield put(getCheckups(response, matchObj.params.siteId));
    }

    if (error) {
        yield put(getCheckups([], matchObj.params.siteId));
        return error;
    }

    return null;
}

export default fizzlesWhenOffline(fetchBuildingSiteCheckups);
