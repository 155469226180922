import {createStore, applyMiddleware, compose} from 'redux';
import createHistory from 'history/createBrowserHistory';
import {routerMiddleware as routerMiddlewareFactory} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import api from 'utils/api';
import {createLogger} from 'redux-logger';
import {offline} from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import {createOfflineMiddleware} from '@redux-offline/redux-offline/lib/middleware';
import offlineActionTracker from '@redux-offline/redux-offline/lib/offlineActionTracker';
import thunkMiddleware from 'redux-thunk';

import rootReducer, {serverRootReducer} from 'configuration/reducers';
import {getDataForSubmission} from 'utils/nfs';
import transformObject from 'utils/transformObject';
import SagaManager from 'sagas/SagaManager';

// Enable Redux devTools in development (only when browser is used, ignored when rendered with node)
const composeEnhancers =
    DEV_MODE && typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const storeEnhancers = [];

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const handleNativeFs = (d, fieldName) => new Promise((resolve) => {
    const fieldData = d[fieldName];

    if (!fieldData) {
        return resolve({
            data: d,
            attachments: [],
        });
    }

    const promises = [];
    const attachments = [];
    let i = 0;

    fieldData.forEach((item) => {
        if (item && item.type === 'nfs') {
            promises.push([i, getDataForSubmission(item, `${fieldName}[${i}]`)]);
            attachments.push(undefined); // placeholder
        } else {
            attachments.push(item);
        }

        i += 1;
    });

    const result = {
        data: {
            ...d,
        },
        attachments,
    };

    delete result.data[fieldName];

    if (promises.length > 0) {
        return Promise.all(promises.map(pr => pr[1])).then((items) => {
            // Replace all placeholders
            items.forEach((item, idx) => {
                result.attachments[promises[idx][0]] = item;
            });

            resolve(result);
        });
    }

    return resolve(result);
});

// Since we cannot use promises inside redux-offline commit/effect (they will not persist after reloads) we need to
//  instead serialize the call actions. The promise will be created based on the serialized data right before doing
//  the request inside eTOMeffectReconciler.
const lazyParamsResolverMap = {
    handleNativeFs,
};

export const lazyParam = (funcName, ...args) => ({
    funcName,
    args,
});

// Note: This only works with POST/PATCH/PUT/DELETE since it accepts the data argument
const eTOMeffectReconciler = ({
    resourceName,
    kwargs = {},
    query,
    method,
    data = null,
    lazyParams,
    attachments,
    ...requestConfig
}) => {
    const resource = api[resourceName];

    // Note: LazyParams currently only supports data and attachments
    if (lazyParams) {
        const {funcName, args} = lazyParams;

        return new Promise((resolve, reject) => {
            if (!lazyParamsResolverMap[funcName]) {
                reject(new Error(`Unknown lazyParams handler: ${funcName}`));
            }

            lazyParamsResolverMap[funcName](...args).then((resolvedParams) => {
                const updatedData = {
                    ...(data || {}),
                    ...resolvedParams.data,
                };
                const nAttachments = resolvedParams.attachments || attachments;

                requestConfig.mutateRequest = (req) => { // eslint-disable-line no-param-reassign
                    nAttachments.forEach((attachment) => {
                        if (attachment.id) {
                            req.field('existing_images', attachment.id);
                        }
                    });
                    return req;
                };

                return resource[method](kwargs, updatedData, query, nAttachments, requestConfig).then(resolve, reject);
            }, reject);
        });
    }

    return resource[method](kwargs, data, query, attachments, requestConfig);
};

const decaySchedule = [
    1000, // After 1 seconds
    1000 * 5, // After 5 seconds
    1000 * 15, // After 15 seconds
    1000 * 30, // After 30 seconds
    1000 * 60, // After 1 minute
    1000 * 60 * 5, // After 5 minutes
];

const UPPER_RETRY_LIMIT = 2; // Times

export default function configureStore(initialState = {}) {
    // Add our effects reconciler to the redux-offline config
    const eTOMConfig = {
        ...offlineConfig,
        persistOptions: {
            ...offlineConfig.persistOptions,
            storage: SERVER_MODE
                ? {
                    getItem: () => undefined,
                    getAllKeys: () => undefined,
                    setItem: () => undefined,
                    clear: () => undefined,
                }
                : require('localforage').default, // eslint-disable-line global-require
        },
        effect: effect => eTOMeffectReconciler(effect),
        discard: (error, action, retries) => {
            if (error.isNetworkError) {
                return false;
            } else if (error.isInvalidResponseCode) {
                return error.statusCode !== 413 || retries > 1;
            }

            return retries > UPPER_RETRY_LIMIT;
        },
        retry: (action, retries) => (action.meta.urgent ? 100 : decaySchedule[Math.min(decaySchedule.length - 1, retries)]),

        offlineActionTracker: offlineActionTracker.withPromises,
    };

    // create list of middleware to spread later, makes easier way to add based on environment
    const middlewares = [createOfflineMiddleware(eTOMConfig), sagaMiddleware, thunkMiddleware];

    // create router middleware only on client
    let history = null;
    if (!SERVER_MODE) {
        history = createHistory();
        const routerMiddleware = routerMiddlewareFactory(history);
        middlewares.push(routerMiddleware);
    }

    // if not production, add redux logger
    if (DEV_MODE) {
        const extraOptions = {};

        if (SERVER_MODE) {
            extraOptions.colors = false;
            extraOptions.actionTransformer = action => transformObject(action);
            extraOptions.stateTransformer = state => transformObject(state);
            extraOptions.titleFormatter = (action, time, took) => (
                `action "${action.type}" @ ${time} (in ${took.toFixed(2)} ms)`
            );
        }

        middlewares.push(createLogger({
            collapsed: true,
            duration: true,
            logger: console,
            ...extraOptions,
        }));
    }

    // Adds support for dispatching an array of actions
    const arrayMiddleware = ({dispatch}) => next => (action) => {
        if (Array.isArray(action)) {
            return action.map(dispatch);
        }

        return next(action);
    };

    middlewares.push(arrayMiddleware);

    // might contain pre-configured enhancers
    storeEnhancers.unshift(applyMiddleware(...middlewares));
    // Server side has extra reducer attached to handle passing cookies and other stuff to frontend server
    const reducer = SERVER_MODE ? serverRootReducer : rootReducer;

    const createOfflineStore = offline(eTOMConfig)(createStore);

    const store = createOfflineStore(reducer, initialState, composeEnhancers(...storeEnhancers));

    if (typeof window !== 'undefined') {
        // run sagas only on client
        SagaManager.startSagas(sagaMiddleware);
    }

    /* eslint-disable global-require */
    if (module.hot) {
        module.hot.accept(
            './reducers', () => store.replaceReducer(require('./reducers').default),
        );

        module.hot.accept('../sagas/SagaManager', () => {
            SagaManager.cancelSagas(store);
            require('../sagas/SagaManager').default.startSagas(sagaMiddleware, true);
        });
    }
    /* eslint-enable */

    return {
        store,
        history,
        sagaMiddleware,
    };
}
