import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import uuid from 'uuid';

import {gettext} from 'utils/i18n';
import moment from 'moment';

import Input from 'components/inputs/Input';
import GoogleLocationInput from "components/inputs/GoogleLocationInput";
import withView from "decorators/withView";
import {selectors as userSelectors} from 'ducks/user';
import {requestAddSite, ETOM_INDEX_BASELINE_DEFAULT} from "ducks/buildingSites";
import {UserShape} from "utils/types";

const mapStateToProps = state => ({
    user: userSelectors.user(state),
});

const mapDispatchToProps = dispatch => ({
    onSubmit: siteData => dispatch(requestAddSite(siteData)),
});

class AddBuilding extends Component {
    static propTypes = {
        user: UserShape.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        isSuccess: false,
        name: '',
        address: '',
        etomIndexBaseline: ETOM_INDEX_BASELINE_DEFAULT,
        errors: {
            generic: null,
            name: null,
            address: null,
            start: null,
            end: null,
            etom_index_baseline: null,
        },
    };

    onChangeField = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    onAddObject = () => {
        const {name, address, start, end, etomIndexBaseline} = this.state;

        const errors = {name: null, address: null, start: null, end: null};
        if (!name) {
            errors.name = gettext('Name is required');
        }

        if (!address) {
            errors.address = gettext('Address is required');
        }

        if (!name) {
            errors.name = gettext('Name is required');
        }

        if (!start) {
            errors.start = gettext('Please set a starting date');
        } else if (moment(start, "YYYY-MM-DD").isAfter(moment(), 'day')) {
            errors.start = gettext("A start date shouldn't be in the future");
        }

        if (!end) {
            errors.end = gettext('Please set an end date');
        } else if (moment(end, "YYYY-MM-DD").isBefore(moment(), 'day')) {
            errors.end = gettext("An end date shouldn't be the past");
        }

        if (etomIndexBaseline < 0 || etomIndexBaseline > 100) {
            errors.etom_index_baseline = gettext('Must be between 0 and 100');
        }

        if (errors.name || errors.address || errors.start || errors.end || errors.etom_index_baseline) {
            this.setState({errors});
            return;
        }

        this.clearErrors();
        this.props.onSubmit({
            name,
            address,
            id: uuid.v4(),
            start,
            end,
            reviewer: this.props.user.id,
            etom_index_baseline: etomIndexBaseline,
        });
        this.finishAdding();
    };

    clearErrors = () => this.setState({
        errors: {
            generic: null,
            name: null,
            address: null,
        },
    });

    finishAdding = () => this.setState({isSuccess: true});

    render() {
        const {name, address, errors, etomIndexBaseline, isSuccess} = this.state;

        if (isSuccess) {
            return (
                <Redirect to="/sites" />
            );
        }

        return (

            <div className="view__contents">
                <h1>{gettext("Add object")}</h1>
                {errors.generic ? (<div className="alert alert-danger" role="alert">{errors.generic}</div>) : null}

                <Input
                    label={gettext('Name')}
                    name="name"
                    value={name}
                    onChange={e => this.onChangeField('name', e.target.value)}
                    placeholder={gettext('Object name')}
                    error={errors.name}
                />

                <GoogleLocationInput
                    label={gettext('Address')}
                    name="address"
                    value={address}
                    onChange={val => this.onChangeField('address', val)}
                    placeholder={gettext('Object address')}
                    error={errors.address}
                />

                <Input
                    label={gettext('Building site start date')}
                    name="start"
                    type="date"
                    max={moment().format('YYYY-MM-DD')}
                    error={errors.start}
                    onChange={e => this.onChangeField('start', e.target.value)}
                    placeholder={gettext('Start date')}
                />

                <Input
                    label={gettext('Building site end date')}
                    name="end"
                    type="date"
                    min={moment().format('YYYY-MM-DD')}
                    error={errors.end}
                    onChange={e => this.onChangeField('end', e.target.value)}
                    placeholder={gettext('End date')}
                />

                <Input
                    label={gettext('eTOM index baseline')}
                    name="etomIndexBaseline"
                    type="number"
                    value={etomIndexBaseline}
                    error={errors.etom_index_baseline}
                    onChange={e => this.onChangeField('etomIndexBaseline', e.target.value)}
                    placeholder={ETOM_INDEX_BASELINE_DEFAULT}
                />

                <div className="view__bottom">
                    <div className="btn btn-success btn-block btn-bold" onClick={this.onAddObject}>
                        {gettext('Add object')}
                    </div>
                </div>
            </div>
        );
    }
}

export default withView(gettext('Add new building'), true)(connect(mapStateToProps, mapDispatchToProps)(AddBuilding));
