import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';

import {ComposedChart, Area, ResponsiveContainer, Line, XAxis, YAxis, Bar, Tooltip,
    Legend, BarChart, CartesianGrid} from 'recharts';
import {Icon} from 'react-fa';
import CheckupPieChart from "components/CheckupPieChart";
import SiteOverview from "components/SiteOverview";

import {STATE_KEY} from 'ducks/buildingSites';

import withView from 'decorators/withView';
import {ReportPropType, SubcategoriesReportPropType, SitePropType} from "utils/types";
import {gettext} from 'utils/i18n';

const mapStateToProps = (state, ownProps) => {
    const {siteId, startDate} = ownProps.match.params;
    const site = state[STATE_KEY].data[siteId];
    const subcategoriesReport = state[STATE_KEY].subcategoriesReport[siteId];
    return {
        site,
        subcategoriesReportData: subcategoriesReport ? subcategoriesReport[startDate === '3m' ? '3m' : 'start'] : null,
        reportData: state[STATE_KEY].report,
    };
};

class SubcategoriesReport extends Component {
    renderLastCheckup = () => {
        if (!this.props.reportData.last_checkup) {
            return null;
        }
        const checkup = this.props.reportData.last_checkup;

        if (Object.keys(checkup).length === 0) {
            return (
                <div className="checkup">
                    <h5 className="text-center">{gettext('There is no previous checkup for this building site')}</h5>
                </div>
            );
        }

        return <CheckupPieChart checkup={checkup} />;
    };

    renderOverview = () => {
        if (!this.props.reportData.trend.length) {
            return null;
        }
        let trend = this.props.reportData.trend;
        let trendStartDate = "";
        if (this.props.match.params.startDate === '3m') {
            trend = this.props.reportData.trend.filter(
                t => moment(t.date, 'DD.MM.YYYY').isSameOrAfter(moment().subtract(3, 'months')),
            );
            if (trend[0]) {
                trendStartDate = trend[0].date;
            }
        }
        return (<SiteOverview
            trend={trend}
            etom_index_baseline={this.props.reportData.etom_index_baseline}
            site={this.props.site}
            trend_start_date={trendStartDate}
        />);
    };

    renderWorkersAndRemarks() {
        if (!this.props.subcategoriesReportData || !this.props.subcategoriesReportData.workers_and_remarks.length) {
            return null;
        }

        const renderLabel = ({x, y, value}) => {
            if (!value) {
                return null;
            }
            return (
                <text x={x} y={y} dy={-10} fill="#999999" fontSize={14} textAnchor="middle">
                    {value}
                </text>
            );
        };

        // if more than 50 checkups don't have workers specified, hide line for workers
        const checkupsWithZeroEmployees = this.props.subcategoriesReportData.workers_and_remarks
            .filter(d => d.employee_count === 0).length;
        let showEmployeeLine = true;
        if (checkupsWithZeroEmployees > this.props.subcategoriesReportData.workers_and_remarks.length / 2) {
            showEmployeeLine = false;
        }

        return (<div>
            <h5 className="text-center title">{gettext("Numbers of employees versus remarks on the site")}</h5>
            <ResponsiveContainer height={300}>
                <ComposedChart height={300} data={this.props.subcategoriesReportData.workers_and_remarks}>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                    <Tooltip />
                    <XAxis dataKey="date" />
                    <YAxis allowDecimals={false} />
                    <Area
                        type="monotone"
                        name={gettext("Remarks")}
                        dataKey="remarks_count"
                        stroke="#fd7e14"
                        fill="#fd7e14"
                        label={renderLabel}
                    />
                    {showEmployeeLine ? <Line
                        type="monotone"
                        name={gettext("Employees")}
                        dataKey="employee_count"
                        stroke="#42A5F5"
                        label={renderLabel}
                    /> : null}
                </ComposedChart>
            </ResponsiveContainer>
        </div>);
    }

    renderRemarkCategories() {
        if (!this.props.subcategoriesReportData || !this.props.subcategoriesReportData.categories.length) {
            return null;
        }
        return (<div className="categories-wrp">
            <h5 className="text-center title">{gettext("Remarks detailed info")}</h5>
            {this.props.subcategoriesReportData.categories.map((category) => {
                let negativePercentage = 0;
                if (category.negative_count > 0) {
                    negativePercentage = Math.round(
                        (category.negative_count / (category.negative_count + category.positive_count)) * 100,
                    );
                }

                return (
                    <div key={`cat-${category.id}`}>
                        <h5>
                            <b>{category[`label_${this.props.activeLanguage}`]}</b>
                            <span className="negative-score">{category.negative_count}</span>
                            <span className="score">/{category.negative_count + category.positive_count}</span>
                        </h5>
                        <div className="progress success">
                            {negativePercentage ?
                                <div
                                    className="progress-bar progress-bar-danger"
                                    style={{width: `${negativePercentage}%`}}
                                >
                                    <span className="sr-only">{negativePercentage}%</span>
                                    <Icon className="progress-bar__icon" name="eye" size="2x" />
                                </div> : null}
                        </div>
                        <div>
                            {category.subcategories.map((subcategory) => {
                                let negativeSubcategoryPercentage = 0;
                                if (subcategory.negative_count > 0) {
                                    negativeSubcategoryPercentage = Math.round(
                                        (subcategory.negative_count / (category.negative_count)) * 100,
                                    );
                                }
                                return (
                                    <div key={`subcat-${subcategory.id}`}>
                                        <h5>
                                            {subcategory[`label_${this.props.activeLanguage}`]}
                                            <span className="negative-score">{subcategory.negative_count}</span>
                                            <span className="score">/{category.negative_count}</span>
                                        </h5>
                                        <div className="progress neutral">
                                            {negativeSubcategoryPercentage ? <div
                                                className="progress-bar progress-bar-danger"
                                                style={{width: `${negativeSubcategoryPercentage}%`}}
                                            >
                                                <span className="sr-only">{negativeSubcategoryPercentage}%</span>
                                                <Icon className="progress-bar__icon" name="eye" size="2x" />
                                            </div> : null}
                                        </div>
                                    </div>);
                            },
                            )}
                        </div>
                    </div>
                );
            })}</div>);
    }

    renderResponses() {
        if (!this.props.subcategoriesReportData || !this.props.subcategoriesReportData.per_assignee_responses.length) {
            return null;
        }
        return (<div>
            <h5 className="text-center title">{gettext("Responses of assignees to the problems")}</h5>
            <ResponsiveContainer height={300}>
                <BarChart
                    height={300}
                    data={this.props.subcategoriesReportData.per_assignee_responses}
                    layout="vertical"
                >
                    <CartesianGrid />
                    <XAxis type="number" allowDecimals={false} />
                    <YAxis type="category" dataKey="email" width={170} />
                    <Tooltip />
                    <Legend layout="vertical" />
                    <Bar name={gettext("Answered/approved")} dataKey="approved_count" stackId="a" fill="#54bd95" />
                    <Bar
                        name={gettext("Answered and pending or declined")}
                        dataKey="declined_pending_count"
                        stackId="a"
                        fill="#ffc107"
                    />
                    <Bar
                        name={gettext("No answer")}
                        dataKey="no_answered_count"
                        stackId="a"
                        fill="#f52f3d"
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>);
    }

    render() {
        return (<div className="view__contents page--subcategories">
            <div>{this.renderLastCheckup()}</div>
            <div className="site-wrp">{this.renderOverview()}</div>
            {this.renderWorkersAndRemarks()}
            {this.renderRemarkCategories()}
            {this.renderResponses()}
        </div>);
    }
}

SubcategoriesReport.propTypes = {
    site: SitePropType,
    match: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
        params: PropTypes.shape({
            siteId: PropTypes.string.isRequired,
            startDate: PropTypes.string,
        }).isRequired,
    }).isRequired,
    reportData: ReportPropType.isRequired,
    subcategoriesReportData: SubcategoriesReportPropType.isRequired,
    activeLanguage: PropTypes.string.isRequired,
};

SubcategoriesReport.defaultProps = {
    site: null,
    reportData: {
        etom_index_baseline: 0,
        negative_remarks_count: 0,
        solved_remarks_count: 0,
        comparison: {
            week: {
                etom_average: 0,
                site_average: 0,
                company_average: 0,
            },
            month: {
                etom_average: 0,
                site_average: 0,
                company_average: 0,
            },
            year: {
                etom_average: 0,
                site_average: 0,
                company_average: 0,
            },
        },
        trend: [],
        site: '',
    },
    subcategoriesReportData: {
        categories: [],
        per_assignee_responses: [],
        workers_and_remarks: [],
    },
    activeLanguage: DJ_CONST.LANGUAGE_CODE,
};

export default withView(gettext('Subcategories report'), true)(connect(mapStateToProps, null)(SubcategoriesReport));
