import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectors as loadingSelectors} from 'ducks/loading';


const mapStateToProps = state => ({
    loading: loadingSelectors.isLoading(state),
});

const Loader = ({loading, children}) => (loading ?
    <div className="loading">
        <div className="loading-img" />
    </div> :
    children);

Loader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Loader);
