import {select, put} from 'redux-saga/effects';
import {setOfflineVersion} from 'ducks/offlineVersion';
import {RESET_STATE} from "@redux-offline/redux-offline/lib/constants";


export default function* checkOfflineVersion() {
    const getOfflineVersion = state => state.offlineVersion;
    const offlineVersion = yield select(getOfflineVersion);
    if (DJ_CONST.REDUX_OFFLINE_STORE_VERSION > offlineVersion) {
        yield put({type: RESET_STATE});
        yield put(setOfflineVersion(DJ_CONST.REDUX_OFFLINE_STORE_VERSION));
    }
}
