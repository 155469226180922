import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Icon} from 'react-fa';

import ListItem from "components/ListItem";


class RemovableObject extends Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string.isRequired,
        onRemove: PropTypes.func,
        subtitle: PropTypes.string,
    };

    static defaultProps = {
        display: null,
        className: '',
        subtitle: '',
        onRemove: null,
    };

    state = {
        confirming: false,
    };

    onRemove = () => {
        // If onRemove is not provided as props, then disable the removing process alltoghether;
        if (!this.props.onRemove) {
            return;
        }

        if (this.state.confirming) {
            this.props.onRemove();
        }

        this.setState({confirming: true});
    };

    onCancel = () => this.setState({confirming: false});

    render() {
        const {title, subtitle, className} = this.props;
        const {confirming} = this.state;

        return (
            <div>
                <div
                    className={classNames(className, 'removable', {'removable--removing': confirming})}
                    onClick={this.onRemove}
                >
                    <ListItem>
                        <div className="removable__content">
                            <div className="removable__content__text">
                                <span className="removable__content__title">{title}</span>
                                {subtitle ? <span className="removable__content__subtitle">
                                    {subtitle}
                                </span> : null}
                            </div>
                            {confirming ? <Icon name="minus-square" /> : null}
                        </div>
                    </ListItem>
                </div>
                {confirming ? <div className="backdrop-click" onClick={this.onCancel} /> : null}
            </div>
        );
    }
}

export default RemovableObject;
