import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';

import users, {STATE_KEY as USERS_KEY} from 'ducks/user';
import sites, {STATE_KEY as SITES_KEY} from 'ducks/buildingSites';
import referred from 'ducks/referred';
import offlineVersion from 'ducks/offlineVersion';
import errors from 'ducks/errors';
import loading from 'ducks/loading';
import nfsReducer, {STATE_KEY as NFS_STATE_KEY} from 'ducks/nfs';
import serverClient, {STATE_KEY as SERVER_STATE_KEY} from 'ducks/serverClient';
import {TRIGGER_LOGOUT} from 'sagas/user/logoutSaga';
import {TRIGGER_LOGIN} from 'sagas/user/loginSaga';

const reducers = {
    errors,
    loading,
    [USERS_KEY]: users,
    [SITES_KEY]: sites,
    offlineVersion,
    referred,

    router: routerReducer,

    [NFS_STATE_KEY]: nfsReducer,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
    if (action.type === TRIGGER_LOGOUT || action.type === TRIGGER_LOGIN) {
        return appReducer({loading: state.loading}, action);
    } else {
        return appReducer(state, action);
    }
};

export default rootReducer;

export const serverRootReducer = SERVER_MODE ? combineReducers({
    ...reducers,
    [SERVER_STATE_KEY]: serverClient,
}) : null;
