import {combineReducers} from 'redux';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import update from 'immutability-helper';
import {toast} from 'react-toastify';

import {lazyParam} from 'configuration/configureStore';
import {gettext} from 'utils/i18n';
import {compareString} from 'utils/sorting';

export const STATE_KEY = 'buildingSites';

export const ADD_SITE = `${STATE_KEY}/ADD_SITE`;
export const ADD_SITE_OPTIMISTIC = `${STATE_KEY}/ADD_SITE_OPTIMISTIC`;
export const ADD_SITE_ROLLBACK = `${STATE_KEY}/ADD_SITE_ROLLBACK`;
export const ADD_CHECKUP_OPTIMISTIC = `${STATE_KEY}/ADD_CHECKUP_OPTIMISTIC`;
export const ADD_CHECKUP = `${STATE_KEY}/ADD_CHECKUP`;
export const ADD_CHECKUP_ROLLBACK = `${STATE_KEY}/ADD_CHECKUP_ROLLBACK`;
export const UPDATE_CHECKUP_OPTIMISTIC = `${STATE_KEY}/UPDATE_CHECKUP_OPTIMISTIC`;
export const UPDATE_CHECKUP = `${STATE_KEY}/UPDATE_CHECKUP`;
export const UPDATE_CHECKUP_ROLLBACK = `${STATE_KEY}/UPDATE_CHECKUP_ROLLBACK`;
export const DELETE_CHECKUP_OPTIMISTIC = `${STATE_KEY}/DELETE_CHECKUP_OPTIMISTIC`;
export const DELETE_CHECKUP = `${STATE_KEY}/DELETE_CHECKUP`;
export const DELETE_CHECKUP_ROLLBACK = `${STATE_KEY}/DELETE_CHECKUP_ROLLBACK`;
export const FINALIZE_CHECKUP_OPTIMISTIC = `${STATE_KEY}/FINALIZE_CHECKUP_OPTIMISTIC`;
export const FINALIZE_CHECKUP = `${STATE_KEY}/FINALIZE_CHECKUP`;
export const FINALIZE_CHECKUP_ROLLBACK = `${STATE_KEY}/FINALIZE_CHECKUP_ROLLBACK`;
export const ADD_REMARK_OPTIMISTIC = `${STATE_KEY}/ADD_REMARK_OPTIMISTIC`;
export const ADD_REMARK = `${STATE_KEY}/ADD_REMARK`;
export const UPDATE_REMARK_OPTIMISTIC = `${STATE_KEY}/UPDATE_REMARK_OPTIMISTIC`;
export const UPDATE_REMARK = `${STATE_KEY}/UPDATE_REMARK`;
export const UPDATE_REMARK_ROLLBACK = `${STATE_KEY}/UPDATE_REMARK_ROLLBACK`;
export const ADD_REMARK_ROLLBACK = `${STATE_KEY}/ADD_REMARK_ROLLBACK`;
export const ADD_RESPONSE_OPTIMISTIC = `${STATE_KEY}/ADD_RESPONSE_OPTIMISTIC`;
export const ADD_RESPONSE = `${STATE_KEY}/ADD_RESPONSE`;
export const ADD_RESPONSE_ROLLBACK = `${STATE_KEY}/ADD_RESPONSE_ROLLBACK`;
export const PATCH_RESPONSE_OPTIMISTIC = `${STATE_KEY}/PATCH_RESPONSE_OPTIMISTIC`;
export const PATCH_RESPONSE = `${STATE_KEY}/PATCH_RESPONSE`;
export const PATCH_RESPONSE_ROLLBACK = `${STATE_KEY}/PATCH_RESPONSE_ROLLBACK`;
export const ADD_REMARKS = `${STATE_KEY}/ADD_REMARKS`;
export const SORT_DATA = `${STATE_KEY}/SORT_DATA`;
export const GET_SITES = `${STATE_KEY}/GET_SITES`;
export const GET_CHECKUPS = `${STATE_KEY}/GET_CHECKUPS`;
export const GET_REPORT = `${STATE_KEY}/GET_REPORT`;
export const GET_SUBCATEGORIES_REPORT = `${STATE_KEY}/GET_SUBCATEGORIES_REPORT`;
export const REQUEST_REMARK_CATEGORIES = `${STATE_KEY}/REQUEST_REMARK_CATEGORIES`;
export const ADD_REMARK_CATEGORIES = `${STATE_KEY}/ADD_REMARK_CATEGORIES`;
export const CLEAR_REMARK_CATEGORIES = `${STATE_KEY}/CLEAR_REMARK_CATEGORIES`;

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

/**
 * @typedef {{name: string, phone: string, email: string}} ResponsiblePerson
 */

/**
 * Initial definition if `objects` state.
 * @type {{
 *  sortBy: {field: string, dir: string},
 *  sorted: Array.<number>,
 *  data: Object.<number, {
 *    id: number,
 *    name: string,
 *    address: string,
 *    checkUps: Object.<number, {
 *      id: number,
 *      date: string,
 *      members: Array.<{name: string, email: string}>,
 *      remarks: Object.<string, {
 *        id: number,
 *        comment: string,
 *        responsiblePerson: ResponsiblePerson,
 *        images: Array.<{id: number, data: string}>,
 *      }>,
 *    }>,
 *  }>,
 * }}
 */
const initialState = {
    sortBy: {field: 'name', dir: SORT_ASC},
    sorted: [],
    data: {},
    remarks: {},
    report: {},
    subcategoriesReport: {},
    remarkCategories: {},
};

export const INCIDENT_TYPES = [
    {label: gettext('Occupational safety'), slug: 'safety'},
    {label: gettext('Working environment'), slug: 'environment'},
];


export function getRemarkByIdAndCheckup(remarksStore, checkupId, id) {
    let result = null;
    for (const category in remarksStore[checkupId]) { // eslint-disable-line no-restricted-syntax
        if (Object.prototype.hasOwnProperty.call(remarksStore[checkupId], category)) {
            for (const status in remarksStore[checkupId][category]) { // eslint-disable-line no-restricted-syntax
                if (Object.prototype.hasOwnProperty.call(remarksStore[checkupId][category], status)) {
                    remarksStore[checkupId][category][status].forEach((r) => { // eslint-disable-line no-loop-func
                        if (r.id === id) {
                            result = r;
                        }
                    });

                    if (result) { // Stop execution
                        return result;
                    }
                }
            }
        }
    }
    return result;
}

export function getRemarkById(remarksStore, id) {
    let result = null;
    for (const checkupId in remarksStore) { // eslint-disable-line no-restricted-syntax
        if (Object.prototype.hasOwnProperty.call(remarksStore, checkupId)) {
            for (const category in remarksStore[checkupId]) { // eslint-disable-line no-restricted-syntax
                if (Object.prototype.hasOwnProperty.call(remarksStore[checkupId], category)) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const status in remarksStore[checkupId][category]) {
                        if (Object.prototype.hasOwnProperty.call(remarksStore[checkupId][category], status)) {
                            // eslint-disable-next-line no-loop-func
                            remarksStore[checkupId][category][status].forEach((r) => {
                                if (r.id === id) {
                                    result = r;
                                }
                            });

                            if (result) { // Stop execution
                                return result;
                            }
                        }
                    }
                }
            }
        }
    }
    return result;
}

export const ETOM_INDEX_BASELINE_DEFAULT = DJ_CONST.ETOM_INDEX_BASELINE_DEFAULT;

function sortedBy(state = initialState.sortBy, action) {
    switch (action.type) {
        case SORT_DATA:
            return {field: action.field, dir: action.dir};

        default:
            return state;
    }
}


const selectField = (instance, column) => instance[column];
const getSortingFunc = (field, dir) => {
    if (field === 'name') {
        return compareString(dir === SORT_ASC);
    }

    // no-op
    return () => 0;
};


function sorted(state = initialState.sorted, action) {
    switch (action.type) {
        case SORT_DATA: {
            const {field, dir} = action;
            const sortingFunc = getSortingFunc(field, dir);
            return Object.values(action.data)
                .sort((a, b) => sortingFunc(selectField(a, action.field), selectField(b, action.field)))
                .map(obj => obj.id);
        }

        default:
            return state;
    }
}

function data(state = initialState.data, action) {
    switch (action.type) {
        case ADD_SITE_OPTIMISTIC:
        case ADD_SITE: {
            const site = action.siteData;
            let oldSite = {};

            if (state[site.id]) {
                // The site is already in the state and we can trust the device's storage
                oldSite = state[site.id];
            }

            return {
                ...state,
                [site.id]: {
                    ...oldSite,
                    ...site,
                },
            };
        }

        case ADD_SITE_ROLLBACK: {
            toast.error(gettext('An error happened with adding a site.'));
            return update(state, {$unset: [action.siteData.id]});
        }

        case ADD_CHECKUP:
        case ADD_CHECKUP_OPTIMISTIC: {
            const checkup = cloneDeep(action.checkupData); // clone
            /* eslint-disable no-restricted-syntax */
            // Strip Remark object contents from this reducer and leave only IDs as pointers
            for (const [categoryId, categoryObj] of Object.entries(checkup.remarks)) {
                for (const [statusId, statusList] of Object.entries(categoryObj)) {
                    checkup.remarks[categoryId][statusId] = statusList.map(remarkObj => remarkObj.id);
                }
            }
            /* eslint-enable no-restricted-syntax */
            const site = state[checkup.site] || {id: checkup.site, checkUps: {}};

            return {
                ...state,
                [site.id]: {
                    ...site,
                    checkUps: {
                        ...site.checkUps,
                        [checkup.id]: checkup,
                    },
                },
            };
        }

        case UPDATE_CHECKUP_OPTIMISTIC: {
            return update(
                state,
                {
                    [action.checkupData.site]: {
                        checkUps: {
                            [action.checkupData.id]: {
                                observers: {
                                    $set: action.checkupData.observers,
                                },
                                date: {
                                    $set: new Date().toJSON().slice(0, 10),
                                },
                            },
                        },
                    },
                },
            );
        }

        case DELETE_CHECKUP_OPTIMISTIC:
        case ADD_CHECKUP_ROLLBACK: {
            if (action.type === ADD_CHECKUP_ROLLBACK) {
                toast.error(gettext('An error happened with adding a checkup.'));
            }

            if (!state[action.checkupData.site]) {
                return state;
            }

            if (!state[action.checkupData.site].checkUps[action.checkupData.id]) {
                return state;
            }


            return update(
                state,
                {
                    [action.checkupData.site]: {
                        checkUps: {
                            $unset: [action.checkupData.id],
                        },
                    },
                },
            );
        }

        case FINALIZE_CHECKUP: {
            const checkup = action.checkupData;

            return update(
                state,
                {
                    [checkup.site]: {
                        checkUps: {
                            [checkup.id]: {
                                status: {
                                    $set: DJ_CONST.CHECKUP_STATUSES.STATUS_FINALIZED,
                                },
                            },
                        },
                    },
                },
            );
        }

        case FINALIZE_CHECKUP_OPTIMISTIC: {
            // we have .ID / .OBSERVERS / .SITE
            const checkup = action.checkupData;

            return update(
                state,
                {
                    [checkup.site]: {
                        checkUps: {
                            [checkup.id]: {
                                status: {
                                    $set: DJ_CONST.CHECKUP_STATUSES.STATUS_FINALIZED,
                                },
                                observers: {
                                    $push: checkup.observers,
                                },
                            },
                        },
                    },
                },
            );
        }

        case FINALIZE_CHECKUP_ROLLBACK: {
            toast.error(gettext('An error happened with a checkup finalization.'));

            // we have .ID / .OBSERVERS / .SITE
            const checkup = action.checkupData;

            // Bail out if:
            // - site is not loaded
            // - checkup does not exist in state
            // Not really sure why those things are missing, but oh well
            if (!get(state, [checkup.site]) || !get(state, [checkup.site, 'checkUps', checkup.id])) {
                return state;
            }

            return update(
                state,
                {
                    [checkup.site]: {
                        checkUps: {
                            [checkup.id]: {
                                status: {
                                    $set: DJ_CONST.CHECKUP_STATUSES.STATUS_IN_PROGRESS,
                                },
                            },
                        },
                    },
                },
            );
        }

        case ADD_REMARK_OPTIMISTIC:
        case ADD_REMARK: {
            const remark = action.remarkData;
            const siteId = remark.siteId;

            const statuses = get(state, [siteId, 'checkUps', remark.checkup, 'remarks', remark.category], {});
            const existingRemarks = get(statuses, [remark.status], []).slice();
            if (existingRemarks.indexOf(remark.id) === -1) {
                existingRemarks.push(remark.id);
            }

            // If remark category or status changed we might need to also clean up other categories/statuses
            let otherRemarkCategories = get(state, [siteId, 'checkUps', remark.checkup, 'remarks'], {});
            Object.keys(otherRemarkCategories).forEach((categoryKey) => {
                const cat = otherRemarkCategories[categoryKey];

                Object.keys(cat).forEach((statusKey) => {
                    if (cat[statusKey].indexOf(remark.id) !== -1) {
                        otherRemarkCategories = update(otherRemarkCategories, {
                            [categoryKey]: {
                                [statusKey]: {
                                    $splice: [
                                        [remark.id, 1],
                                    ],
                                },
                            },
                        });
                    }
                });
            });

            return {
                ...state,
                [siteId]: {
                    ...get(state, [siteId], {}),
                    checkUps: {
                        ...get(state, [siteId, 'checkUps'], {}),
                        [remark.checkup]: {
                            ...get(state, [siteId, 'checkUps', remark.checkup], {}),
                            remarks: {
                                ...otherRemarkCategories,
                                [remark.category]: {
                                    ...get(state, [siteId, 'checkUps', remark.checkup, 'remarks', remark.category], {}),
                                    [remark.status]: existingRemarks,
                                },
                            },
                        },
                    },
                },
            };
        }

        case ADD_REMARK_ROLLBACK: {
            if (action.payload && action.payload.isInvalidResponseCode && action.payload.statusCode === 413) {
                toast.error(gettext('An error happened with adding a remark. Please retry with smaller files.'));
            } else {
                toast.error(gettext('An error happened with adding a remark.'));
            }

            const remark = action.remarkData;

            // Bail out if:
            // - no remark data
            // - site is not loaded
            // - checkup does not exist in state
            // Not really sure why those things are missing, but oh well
            if (!remark || !get(state, [remark.siteId]) || !get(state, [remark.siteId, 'checkUps', remark.checkup])) {
                return state;
            }


            const statuses = get(state, [remark.siteId, 'checkUps', remark.checkup, 'remarks', remark.category], null);

            // If the remark category does not exist yet then return an empty one
            if (statuses === null) {
                return update(
                    state,
                    {
                        [remark.siteId]: {
                            checkUps: {
                                [remark.checkup]: {
                                    remarks: {
                                        [remark.category]: {
                                            $set: {
                                                [remark.status]: [],
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                );
            }

            const existingRemarks = get(statuses || {}, [remark.status], []).filter(r => remark.id !== r.id);

            return update(
                state,
                {
                    [remark.siteId]: {
                        checkUps: {
                            [remark.checkup]: {
                                remarks: {
                                    [remark.category]: {
                                        [remark.status]: {
                                            $set: existingRemarks,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            );
        }

        case GET_SITES: {
            const sites = {};
            action.sites.forEach(site => (sites[site.id] = site)); // eslint-disable-line no-return-assign
            return {
                ...sites,
            };
        }

        case GET_CHECKUPS: {
            const checkUpsById = {};
            action.checkUps.forEach(
                checkup => (checkUpsById[checkup.id] = checkup), // eslint-disable-line no-return-assign
            );

            return {
                ...state,
                [action.siteId]: {
                    ...state[action.siteId],
                    checkUps: checkUpsById, // TODO clearing existing, matters?
                },
            };
        }

        default:
            return state;
    }
}

function remarks(state = initialState.remarks, action) {
    switch (action.type) {
        case ADD_CHECKUP: {
            const checkup = action.checkupData;

            if (state[checkup.id] && !action.force) {
                // The checkup is already in the state and we can trust the device's storage
                return state;
            }

            return {
                ...state,
                [checkup.id]: {
                    ...state[checkup.id],
                    ...checkup.remarks,
                },
            };
        }

        case UPDATE_REMARK_OPTIMISTIC:
        case UPDATE_REMARK:
        case ADD_REMARK_OPTIMISTIC:
        case ADD_REMARK: {
            // If the action corresponds to an offline-commit it also contains `payload` field (the server response)
            // In that case we ignore local data, and use the remote response instead
            //  This also releases memory used for image uploading (data uris)
            let remark = action.remarkData;
            if ((action.type === ADD_REMARK || action.type === UPDATE_REMARK) && action.payload) {
                remark = action.payload;
            }

            let remarksForCheckup = get(state, [remark.checkup, remark.category, remark.status], []);
            // Remove temporary remark with same id
            remarksForCheckup = remarksForCheckup.filter(r => r.id !== remark.id);

            // making backup for update rollback
            if (action.type === UPDATE_REMARK_OPTIMISTIC) {
                // TODO: avoid sending backup optimistic to server
                remark._backup_optimistic = cloneDeep(getRemarkById(state, remark.id));
            }

            remarksForCheckup.push(remark);

            return {
                ...state,
                [remark.checkup]: {
                    ...get(state, [remark.checkup], {}),
                    [remark.category]: {
                        ...get(state, [remark.checkup, remark.category], {}),
                        [remark.status]: remarksForCheckup,
                    },
                },
            };
        }

        case ADD_REMARK_ROLLBACK: {
            const remark = action.remarkData;

            let remarksForCheckup = get(state, [remark.checkup, remark.category, remark.status], []);
            // Remove remark with same id
            remarksForCheckup = remarksForCheckup.filter(r => r.id !== remark.id);

            return {
                ...state,
                [remark.checkup]: {
                    ...get(state, [remark.checkup], {}),
                    [remark.category]: {
                        ...get(state, [remark.checkup, remark.category], {}),
                        [remark.status]: remarksForCheckup,
                    },
                },
            };
        }

        case UPDATE_REMARK_ROLLBACK: {
            toast.error(gettext('Updating a remark failed'));

            const existingRmk = getRemarkById(state, action.remarkData.id);

            // No remark exists, bail out!
            if (!existingRmk || !existingRmk._backup_optimistic) {
                return state;
            }

            const rmk = existingRmk._backup_optimistic;

            let remarksForCheckup = get(state, [rmk.checkup, rmk.category, rmk.status], []);
            // Remove existing remark with same id
            remarksForCheckup = remarksForCheckup.filter(r => r.id !== rmk.id);
            remarksForCheckup.push(rmk);

            return update(
                state,
                {
                    [rmk.checkup]: {
                        [rmk.category]: {
                            [rmk.status]: {
                                $set: remarksForCheckup,
                            },
                        },
                    },
                },
            );
        }

        case ADD_REMARKS: {
            const parsedRemarks = {};

            action.remarksList.forEach((remark) => {
                if (parsedRemarks[remark.checkup]) {
                    if (parsedRemarks[remark.checkup][remark.category]) {
                        if (parsedRemarks[remark.checkup][remark.category][remark.status]) {
                            parsedRemarks[remark.checkup][remark.category][remark.status].push(remark);
                        } else {
                            parsedRemarks[remark.checkup][remark.category][remark.status] = [remark];
                        }
                    } else {
                        parsedRemarks[remark.checkup][remark.category] = {[remark.status]: [remark]};
                    }
                } else {
                    parsedRemarks[remark.checkup] = {[remark.category]: {[remark.status]: [remark]}};
                }
            });

            return {...parsedRemarks};
        }

        case ADD_RESPONSE_OPTIMISTIC:
        case ADD_RESPONSE: {
            const rmk = cloneDeep(getRemarkById(state, action.responseData.remark));

            if (action.type === ADD_RESPONSE && action.payload) {
                // Release local base64 images from memory
                rmk.images = action.payload.images;
            }

            let remarksForCheckup = get(state, [rmk.checkup, rmk.category, rmk.status], []);
            // Remove existing remark with same id
            remarksForCheckup = remarksForCheckup.filter(r => r.id !== rmk.id);
            // Update the response on the right remark
            rmk._last_response_backup_optimistic = rmk.last_response;
            rmk.last_response = action.responseData;
            remarksForCheckup.push(rmk);

            return update(
                state,
                {
                    [rmk.checkup]: {
                        [rmk.category]: {
                            [rmk.status]: {
                                $set: remarksForCheckup,
                            },
                        },
                    },
                },
            );
        }

        case ADD_RESPONSE_ROLLBACK: {
            toast.error(gettext('An error happened with adding a remark response'));

            const existingRmk = getRemarkById(state, action.responseData.remark);

            // No remark exists, bail out!
            if (!existingRmk) {
                return state;
            }

            const rmk = cloneDeep(existingRmk);

            let remarksForCheckup = get(state, [rmk.checkup, rmk.category, rmk.status], []);
            // Remove existing remark with same id
            remarksForCheckup = remarksForCheckup.filter(r => r.id !== rmk.id);
            // Update the response on the right remark
            rmk.last_response = rmk._last_response_backup_optimistic || null;

            if (rmk.last_response) {
                // add rollback flag so the form can react to failures properly
                rmk.last_response.rollback = true;
            }

            remarksForCheckup.push(rmk);

            return update(
                state,
                {
                    [rmk.checkup]: {
                        [rmk.category]: {
                            [rmk.status]: {
                                $set: remarksForCheckup,
                            },
                        },
                    },
                },
            );
        }

        case PATCH_RESPONSE_OPTIMISTIC: {
            const rmk = cloneDeep(getRemarkById(state, action.responseData.remark));

            rmk.is_resolved = action.responseData.state === DJ_CONST.RESPONSE_STATES.APPROVED;

            let remarksForCheckup = get(state, [rmk.checkup, rmk.category, rmk.status], []);
            // Remove existing remark with same id
            remarksForCheckup = remarksForCheckup.filter(r => r.id !== rmk.id);
            // Update the response on the right remark
            rmk.last_response.state = action.responseData.state;
            rmk.last_response.reviewer_comment = action.responseData.reviewer_comment;
            remarksForCheckup.push(rmk);

            return update(
                state,
                {
                    [rmk.checkup]: {
                        [rmk.category]: {
                            [rmk.status]: {
                                $set: remarksForCheckup,
                            },
                        },
                    },
                },
            );
        }

        case PATCH_RESPONSE_ROLLBACK: {
            toast.error(gettext('An error happened with updating remark response'));

            const rmk = cloneDeep(getRemarkById(state, action.responseData.remark));

            rmk.is_resolved = false;

            let remarksForCheckup = get(state, [rmk.checkup, rmk.category, rmk.status], []);
            // Remove existing remark with same id
            remarksForCheckup = remarksForCheckup.filter(r => r.id !== rmk.id);
            // Update the response on the right remark
            rmk.last_response.state = DJ_CONST.RESPONSE_STATES.PENDING;
            rmk.last_response.reviewer_comment = null;
            remarksForCheckup.push(rmk);

            return update(
                state,
                {
                    [rmk.checkup]: {
                        [rmk.category]: {
                            [rmk.status]: {
                                $set: remarksForCheckup,
                            },
                        },
                    },
                },
            );
        }

        default:
            return state;
    }
}

function report(state = initialState.report, action) {
    // TODO: deal with reports per checkup.
    // right now if report per checkup is asked, it overrides current report
    switch (action.type) {
        case GET_REPORT: {
            const newState = state;
            return Object.assign(newState, action.reportData, {site: action.siteId});
        }

        default:
            return state;
    }
}


function subcategoriesReport(state = initialState.subcategoriesReport, action) {
    switch (action.type) {
        case GET_SUBCATEGORIES_REPORT: {
            return {
                ...state,
                [action.siteId]: {
                    ...get(state, [action.siteId], {}),
                    [action.startDate === '3m' ? '3m' : 'start']: action.reportData,
                },
            };
        }

        default:
            return state;
    }
}


function remarkCategories(state = initialState.remarkCategories, action) {
    switch (action.type) {
        case ADD_REMARK_CATEGORIES: {
            const result = {};
            action.remarkCategories.forEach(
                category => (result[category.slug] = category), // eslint-disable-line no-return-assign
            );
            return result;
        }

        default:
            return state;
    }
}

// Site actions
export const requestAddSite = siteData => ({type: ADD_SITE_OPTIMISTIC,
    siteData,
    meta: {
        offline: {
            effect: {resourceName: 'apiBuildingSitesCreate', method: 'post', data: siteData, validStatusCodes: [200]},
            commit: {type: ADD_SITE, siteData},
            rollback: {type: ADD_SITE_ROLLBACK, siteData},
        },
    },
});
export const addSite = siteData => ({type: ADD_SITE, siteData});
export const getSites = sites => ({type: GET_SITES, sites});

// Checkup actions
export const requestAddCheckUp = checkupData => ({type: ADD_CHECKUP_OPTIMISTIC,
    checkupData,
    meta: {
        offline: {
            effect: {resourceName: 'apiCheckupCreate', method: 'post', data: checkupData},
            commit: {type: ADD_CHECKUP, checkupData},
            rollback: {type: ADD_CHECKUP_ROLLBACK, checkupData},
        },
    },
});
export const addCheckup = (checkupData, force = false) => ({type: ADD_CHECKUP, checkupData, force});
export const requestUpdateCheckUp = checkupData => ({type: UPDATE_CHECKUP_OPTIMISTIC,
    checkupData,
    meta: {
        offline: {
            effect: {resourceName: 'apiCheckupUpdate',
                method: 'patch',
                data: checkupData,
                kwargs: {pk: checkupData.id}},
            commit: {type: UPDATE_CHECKUP, checkupData},
            rollback: {type: UPDATE_CHECKUP_ROLLBACK, checkupData},
        },
    },
});
export const requestDeleteCheckup = checkupData => ({type: DELETE_CHECKUP_OPTIMISTIC,
    checkupData,
    meta: {
        offline: {
            effect: {resourceName: 'apiCheckupDelete',
                method: 'del',
                data: checkupData,
                kwargs: {pk: checkupData.id}},
            commit: {type: DELETE_CHECKUP, checkupData},
            rollback: {type: DELETE_CHECKUP_ROLLBACK, checkupData},
        },
    },
});
export const requestFinalizeCheckup = checkupData => ({type: FINALIZE_CHECKUP_OPTIMISTIC,
    checkupData,
    meta: {
        offline: {
            effect: {resourceName: 'apiCheckupFinalize',
                method: 'patch',
                data: checkupData,
                kwargs: {pk: checkupData.id}},
            commit: {type: FINALIZE_CHECKUP, checkupData},
            rollback: {type: FINALIZE_CHECKUP_ROLLBACK, checkupData},
        },
    },
});
export const getCheckups = (checkUps, siteId) => ({type: GET_CHECKUPS, checkUps, siteId});

// Remark actions
export const requestAddRemark = (remarkData) => {
    const nfsImages = (remarkData.images || []).filter(item => item.type === 'nfs').map(item => item.image);

    return {
        type: ADD_REMARK_OPTIMISTIC,
        remarkData,
        meta: {
            offline: {
                effect: {
                    resourceName: 'apiRemarkCreate',
                    method: 'post',
                    lazyParams: lazyParam('handleNativeFs', remarkData, 'images'),
                },

                // isCommit is used by nfsCommitWatcher to detect if it needs to delete files in nfsImages
                //  we need to do this with a watcher since redux-offline does not allow multiple actions
                //  for the commit.
                commit: {type: ADD_REMARK, remarkData, isCommit: true, nfsImages},
                rollback: {type: ADD_REMARK_ROLLBACK, remarkData},
            },
        },
    };
};

export const addRemark = remarkData => ({type: ADD_REMARK, remarkData});
export const addRemarks = remarksList => ({type: ADD_REMARKS, remarksList});


export const requestUpdateRemark = (remarkData) => {
    const nfsImages = (remarkData.images || []).filter(item => item.type === 'nfs').map(item => item.image);

    return {
        type: UPDATE_REMARK_OPTIMISTIC,
        remarkData,
        meta: {
            offline: {
                effect: {
                    resourceName: 'apiRemarkUpdate',
                    method: 'patch',
                    lazyParams: lazyParam('handleNativeFs', remarkData, 'images'),
                    kwargs: {pk: remarkData.id},
                },

                // isCommit is used by nfsCommitWatcher to detect if it needs to delete files in nfsImages
                //  we need to do this with a watcher since redux-offline does not allow multiple actions
                //  for the commit.
                commit: {type: UPDATE_REMARK, remarkData, isCommit: true, nfsImages},
                rollback: {type: UPDATE_REMARK_ROLLBACK, remarkData},
            },
        },
    };
};

export const UpdateRemark = remarkData => ({type: UPDATE_REMARK, remarkData});

// Response actions
export const requestAddResponse = (responseData, remarkId) => {
    const nfsImages = (responseData.images || []).filter(item => item.type === 'nfs').map(item => item.image);

    return {
        type: ADD_RESPONSE_OPTIMISTIC,
        responseData,
        remarkId,
        meta: {
            offline: {
                effect: {
                    resourceName: 'apiResponseCreate',
                    method: 'post',
                    lazyParams: lazyParam('handleNativeFs', responseData, 'images'),
                    kwargs: {pk: remarkId},
                },

                // isCommit is used by nfsCommitWatcher to detect if it needs to delete files in nfsImages
                //  we need to do this with a watcher since redux-offline does not allow multiple actions
                //  for the commit.
                commit: {type: ADD_RESPONSE, responseData, isCommit: true, nfsImages},
                rollback: {type: ADD_RESPONSE_ROLLBACK, responseData},
            },
        },
    };
};

export const requestPatchResponse = responseData => ({type: PATCH_RESPONSE_OPTIMISTIC,
    responseData,
    meta: {
        offline: {
            effect: {
                resourceName: 'apiResponseUpdate', method: 'patch', data: responseData, kwargs: {pk: responseData.id},
            },
            commit: {type: PATCH_RESPONSE, responseData},
            rollback: {type: PATCH_RESPONSE_ROLLBACK, responseData},
        },
    },
});

// Report actions
export const getReport = (reportData, siteId, startDate) => ({type: GET_REPORT, reportData, startDate});

// SubcategoriesReport actions
export const getSubcategoriesReport = (reportData, siteId, startDate) => (
    {type: GET_SUBCATEGORIES_REPORT, reportData, siteId, startDate}
);

// Utility actions
export const sortBy = (field, dir, sortFunc) => (dispatch, getState) => dispatch({
    type: SORT_DATA, field, dir, sortFunc, data: getState()[STATE_KEY].data,
});
export const requestRemarkCategories = () => ({type: REQUEST_REMARK_CATEGORIES});
export const addRemarkCategories = categoryData => ({type: ADD_REMARK_CATEGORIES, remarkCategories: categoryData});

export default combineReducers({
    sortedBy,
    sorted,
    remarks,
    remarkCategories,
    data,
    report,
    subcategoriesReport,
});
