import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ListItem from './ListItem';

const List = ({header, className, children, icons}) => (
    <div className={classNames("view__list", className, {"view__list--with-icons": icons})}>
        {header ? (
            <div className="view__list__header">{header}</div>
        ) : null}
        {children}
    </div>
);

List.propTypes = {
    header: PropTypes.node,
    className: PropTypes.string,
    icons: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

List.defaultProps = {
    header: null,
    className: null,
    icons: false,
    children: null,
};

export {List as default, ListItem};
