/**
 * This Duck is for handling logged in user actions and user data.
 */
import {combineReducers} from 'redux';

import {getCookie} from 'utils/cookie';
import {toast} from 'react-toastify';
import {gettext} from 'utils/i18n';


export const STATE_KEY = 'users';

const SET_USER = `${STATE_KEY}/SET_USER`;
const UPDATE_REMARKS_CC_EMAIL_STATUS = `${STATE_KEY}/UPDATE_REMARKS_CC_EMAIL_STATUS`;
const UPDATE_REMARKS_CC_EMAIL_STATUS_OPTIMISTIC = `${STATE_KEY}/UPDATE_REMARKS_CC_EMAIL_STATUS_OPTIMISTIC`;
const UPDATE_REMARKS_CC_EMAIL_STATUS_ROLLBACK = `${STATE_KEY}/UPDATE_REMARKS_CC_EMAIL_STATUS_ROLLBACK`;
export const SET_ACTIVE_LANGUAGE = `${STATE_KEY}/SET_ACTIVE_LANGUAGE`;


function userReducer(state = null, action) {
    switch (action.type) {
        case SET_USER:
            return action.user;

        case UPDATE_REMARKS_CC_EMAIL_STATUS_OPTIMISTIC:
        case UPDATE_REMARKS_CC_EMAIL_STATUS: {
            return {
                ...state,
                remarks_cc_email: action.remarks_cc_email,
            };
        }

        case UPDATE_REMARKS_CC_EMAIL_STATUS_ROLLBACK: {
            toast.error(gettext('An error happened with "Send copy of remarks email to me" setting.'));
            return {
                ...state,
                remarks_cc_email: !action.remarks_cc_email,
            };
        }

        default:
            return state;
    }
}

function authenticatedReducer(state = false, action) {
    switch (action.type) {
        case SET_USER:
            return !!action.user && !!Object.keys(action.user).length;

        default:
            return state;
    }
}

const initialLanguage = () => getCookie(DJ_CONST.LANGUAGE_COOKIE_NAME) || DJ_CONST.LANGUAGE_CODE;

function activeLanguageReducer(state = initialLanguage(), action) {
    switch (action.type) {
        case SET_ACTIVE_LANGUAGE:
            return action.language;

        default:
            return state;
    }
}

// update cc email status
// eslint-disable-next-line camelcase
export const requestUpdateRemarksCcEmailStatus = remarks_cc_email => ({type: UPDATE_REMARKS_CC_EMAIL_STATUS_OPTIMISTIC,
    remarks_cc_email,
    meta: {
        offline: {
            effect: {resourceName: 'apiUpdateRemarksCcEmailStatus', method: 'patch', data: {remarks_cc_email}},
            commit: {type: UPDATE_REMARKS_CC_EMAIL_STATUS, remarks_cc_email},
            rollback: {type: UPDATE_REMARKS_CC_EMAIL_STATUS_ROLLBACK, remarks_cc_email},
        },
    },
});


export default combineReducers({
    user: userReducer,
    isAuthenticated: authenticatedReducer,
    activeLanguage: activeLanguageReducer,
});

export const setUser = user => ({type: SET_USER, user});
export const setActiveLanguage = language => ({type: SET_ACTIVE_LANGUAGE, language});

export const selectors = {
    isAuthenticated: state => state[STATE_KEY].isAuthenticated,
    user: state => state[STATE_KEY].user,
    activeLanguage: state => state[STATE_KEY].activeLanguage || initialLanguage(),
};

